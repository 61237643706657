import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { IonChip } from '@ionic/react'

const Badge = styled(IonChip)<{ $clickable: boolean }>`
  --background: var(--ion-color-primary);
  --color: var(--greyscale-10);
  height: 25px;
  width: 100px;
  display: flex;
  justify-content: center;
  cursor: ${props => (props.$clickable ? 'pointer' : 'default')};
`

const SubscriptionBadge = ({ clickable = false, label }: { clickable?: boolean; label: string }) => {
  return <Badge $clickable={clickable}>{_.capitalize(label)}</Badge>
}

export default SubscriptionBadge
