import React, { useEffect } from 'react'
import { pick, get } from 'lodash'
import styled from 'styled-components'
import { generatePath, useLocation, useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { IonPage, IonList } from '@ionic/react'
import useStores from '@supplyhound/hooks/useStores'
import Content from '@supplyhound/components/Content'
import JobSiteItem from '@supplyhound/components/JobSiteItem'
import Spacer from '@supplyhound/components/common/Spacer'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import { JOB_SITE_LIST_DETAIL_ROUTE } from '@supplyhound/utils/config'
import { Header, HeaderModes } from '@supplyhound/layout'

import { FileWithUrl, JobSite } from '@supplyhound/types'

const StyledList = styled(IonList)`
  border: 1px solid #414141;
  border-radius: var(--ion-border-radius);
  background-color: var(--ion-color-light);
  padding: 0 0;
  overflow: hidden;

  ion-item:last-child {
    --border-width: 0;
  }
`
const HistoryJobSiteListSelectPage: React.FC = () => {
  const history = useHistory()
  const location = useLocation<{
    item: {
      description: string
      quantity: number
      image: FileWithUrl
      source_task_item_id: number
    }
  }>()
  const { jobSitesStore, historyStore } = useStores()
  const jobSites = jobSitesStore.jobSiteArray
  const { loadWhile } = useLoadingState()

  useEffect(() => {
    loadWhile(jobSitesStore.dispatchFetchJobSites)
  }, [])

  return (
    <IonPage>
      <Header mode={HeaderModes.Leaf} />
      <Content>
        <h1>
          <b>Which jobsite would you like to add this item to</b>
        </h1>
        <Spacer height={30} />
        <StyledList lines="full">
          {jobSites.map(jobSite => (
            <JobSiteItem
              key={jobSite.id}
              label={jobSite.nickname || jobSite.formatted_address}
              onClick={async () => {
                await loadWhile(async () =>
                  historyStore.dispatchOrderListItem(
                    jobSite.orderer_list.id,
                    pick(location.state.item, ['description', 'quantity', 'image']),
                    get(location.state.item, 'source_task_item_id')
                  )
                )
                history.push(
                  // @ts-ignore see thread https://github.com/supplyhound/order-app/pull/137/files#r789259791
                  generatePath(JOB_SITE_LIST_DETAIL_ROUTE, { id: jobSite.id })
                )
              }}
            />
          ))}
          <JobSiteItem
            addJobSiteItem
            onJobSiteCreate={async (jobSite: JobSite) => {
              await loadWhile(() =>
                historyStore.dispatchOrderListItem(
                  jobSite.orderer_list.id,
                  pick(location.state.item, ['description', 'quantity', 'image']),
                  get(location.state.item, 'source_task_item_id')
                )
              )
              // @ts-ignore see thread https://github.com/supplyhound/order-app/pull/137/files#r789259791
              history.push(generatePath(JOB_SITE_LIST_DETAIL_ROUTE, { id: jobSite.id }))
            }}
          />
        </StyledList>
      </Content>
    </IonPage>
  )
}

export default observer(HistoryJobSiteListSelectPage)
