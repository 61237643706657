import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { HOME_ROUTE } from '@supplyhound/utils/config'
import useStores from '@supplyhound/hooks/useStores'

const OrdererAuthenticated: React.FC<{ children: JSX.Element | JSX.Element[]; redirectPathName?: string }> = ({
  children,
  redirectPathName = HOME_ROUTE,
}) => {
  const { userProfileStore, sessionStore } = useStores()
  const location = useLocation()

  sessionStore.setIsSessionExpired(userProfileStore.authStore.isTokenExpired)
  if (userProfileStore.authStore.isAuthenticated) {
    return <>{children}</>
  }

  return <Redirect to={{ pathname: redirectPathName, state: { referrer: location } }} />
}

export default observer(OrdererAuthenticated)
