import React, { useState } from 'react'
import useStores from '@supplyhound/hooks/useStores'
import { ID, BasicUser } from '@supplyhound/types'
import { dispatchUserUpdate } from '@supplyhound/api'
import AccountProfileForm from 'pages/Account/AccountProfileForm'
import NotificationBanner from '@supplyhound/components/NotificationBanner'
import { observer } from 'mobx-react-lite'

const AccountProfileTab = () => {
  const {
    userProfileStore: { authStore },
  } = useStores()

  const [bannerMessage, setBannerMessage] = useState(false)

  const handleProfileSubmit = async (id: ID, userPayload: BasicUser) =>
    dispatchUserUpdate(id, userPayload).then(response => {
      authStore.updateBasicUserInfo(response.data.user)
      setBannerMessage(true)
    })

  return (
    <>
      <AccountProfileForm onSubmit={handleProfileSubmit} />
      {bannerMessage && <NotificationBanner message={'Profile Saved'} onDidDismiss={() => setBannerMessage(false)} />}
    </>
  )
}

export default observer(AccountProfileTab)
