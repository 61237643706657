import React from 'react'
import styled from 'styled-components'
import IconPopover from '@supplyhound/components/IconPopover'
import { helpCircle, helpCircleOutline } from 'ionicons/icons'

const InfoText = styled.div`
  font-size: 0.8rem;
  font-style: italic;
  font-weight: 600;
`

const StyledIconPopover = styled(IconPopover)`
  cursor: pointer;
`

const DeliveryByInfo: React.FC = () => {
  return (
    <StyledIconPopover
      icon={helpCircleOutline}
      clickedIcon={helpCircle}
      content={<InfoText>Helper text placeholder</InfoText>}
      id="deliveryHelp"
    />
  )
}

export default DeliveryByInfo
