import React, { useEffect } from 'react'
import { Field, FormikBag, FormikProps, withFormik } from 'formik'
import {
  Location,
  PickupType,
  ID,
  ForwardPropsToChildren,
  SelectableOrdererListItem,
  UnidentifiedPickupTask,
} from '@supplyhound/types'
import styled from 'styled-components'
import { getLabeledPickupConfigs, PickupTypeConfigs } from '@supplyhound/utils/datetimes'
import { compose } from 'lodash/fp'
import { observer } from 'mobx-react-lite'
import DropdownField from '../fields/DropdownField'
import TextInputField from '../fields/TextInputField'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import { PICKUP_DETAILS_VALIDATION_SCHEMA } from '@supplyhound/utils/validation'
import { omit } from 'lodash'
import MaterialListField from '@supplyhound/forms/DetailsForm/MaterialListField'
import useStores from '@supplyhound/hooks/useStores'

export type PickupDetailsFormValues = Pick<
  UnidentifiedPickupTask,
  'pickup_type' | 'pickup_address' | 'pickup_datetime' | 'pickup_note'
> & {
  location?: Partial<Location>
  taskItems: SelectableOrdererListItem[]
  jobSiteId: ID
}

type PickupDetailsFormProps = {
  task: PickupDetailsFormValues
  selectableItems: SelectableOrdererListItem[]
  onSubmit: (values: PickupDetailsFormValues) => void
  onChange: (values: PickupDetailsFormValues) => void
  taskItems: SelectableOrdererListItem[]
}

type FormikValueProps = FormikProps<PickupDetailsFormValues>
type Props = ForwardPropsToChildren<PickupDetailsFormValues, FormikValueProps>

const Row = styled.div`
  padding-top: calc(2 * var(--space-unit));
`

const PickupTypeField = styled(Field)`
  .expandable-list {
    z-index: calc(var(--dropdown-z-index) + 1);
  }
`

const PickupDetailsForm: React.FC<Props & FormikValueProps> = ({
  values,
  submitForm,
  setValues,
  setFieldValue,
  errors,
  jobSiteId,
}) => {
  const { userProfileStore } = useStores()
  const marketTimeZone = userProfileStore.marketTimezone

  const radioItems = getLabeledPickupConfigs(marketTimeZone).filter(({ disabled }) => !disabled)

  useEffect(() => {
    if (!values.location) return

    setFieldValue('pickup_address', values.location.full_address)
  }, [values.location])

  return (
    <div>
      <Row>
        <PickupTypeField
          name="pickup_type"
          label="Pickup by"
          placeholder="Select pickup time"
          component={DropdownField}
          choices={radioItems}
          onSelect={(value: PickupType) => {
            const datetimeConfig = PickupTypeConfigs[value]
            const pickupDatetime = datetimeConfig.computePickupDatetime(marketTimeZone)
            setValues(
              {
                ...values,
                pickup_datetime: pickupDatetime,
                pickup_type: value,
              },
              false
            )
          }}
        />
      </Row>
      <Row>
        <Field name="pickup_note" label="Note" placeholder="Add note here (optional)" component={TextInputField} />
      </Row>
      <Row>
        <MaterialListField<PickupDetailsFormValues>
          jobSiteId={jobSiteId}
          errors={errors}
          setFieldValue={setFieldValue}
          taskItems={values.taskItems}
        />
      </Row>
      <Row>
        <SubmitButton size="large" expand="block" onClick={submitForm}>
          Next
        </SubmitButton>
      </Row>
    </div>
  )
}

export default compose(
  withFormik<PickupDetailsFormProps, PickupDetailsFormValues>({
    displayName: 'PickupDetailsForm',
    enableReinitialize: true,
    validationSchema: PICKUP_DETAILS_VALIDATION_SCHEMA,
    validateOnMount: true,

    mapPropsToValues: ({ task, taskItems }) => ({
      ...task,
      location: { full_address: task.pickup_address },
      taskItems,
    }),

    handleSubmit(
      values: PickupDetailsFormValues,
      { props: { onSubmit } }: FormikBag<PickupDetailsFormProps, PickupDetailsFormValues>
    ) {
      onSubmit(omit(values, 'location'))
    },
  }),
  observer
)(PickupDetailsForm)
