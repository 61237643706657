import React, { useMemo } from 'react'
import styled from 'styled-components'
import { IonPage } from '@ionic/react'
import Icon from '@supplyhound/components/common/Icon'
import { callOutline } from 'ionicons/icons'
import Content from '@supplyhound/components/Content'
import Spacer from '@supplyhound/components/common/Spacer'
import Item from '@supplyhound/components/common/Item'
import { Header, HeaderModes } from '@supplyhound/layout'

const HelpItem = styled(Item)`
  border: 1px solid var(--ion-border-color);
  border-radius: var(--ion-border-radius);
  --padding: 0;
  font-size: 16px;
  font-weight: 500;
  --background: var(--greyscale-1);
  cursor: pointer;

  ::part(native) {
    height: 70px;
    box-shadow: none;
  }
`

const HelpPage = () => {
  const helpItems = useMemo(() => {
    return [
      {
        icon: callOutline,
        label: 'Call SupplyHound',
        onClick: () => window.open(`tel:${process.env.REACT_APP_SUPPLYHOUND_PHONE_NUMBER}`, '_self'),
      },
    ]
  }, [])

  return (
    <IonPage>
      <Header mode={HeaderModes.Root} label="Help" />
      <Content>
        <h4>Support</h4>
        <Spacer height={25} />
        {helpItems.map(({ icon, label, onClick }, index) => {
          return (
            <HelpItem key={index} lines="none" onClick={onClick} detail>
              <Icon icon={icon} />
              <Spacer width={22} />
              {label}
            </HelpItem>
          )
        })}
      </Content>
    </IonPage>
  )
}

export default HelpPage
