import React from 'react'

import flowRight from 'lodash/flowRight'
import styled from 'styled-components'
import { FormikProps, withFormik, Field, FieldProps } from 'formik'

import { validatePromoCode } from '@supplyhound/api'
import InputMask from 'react-input-mask'
import { Promotion } from '@supplyhound/types'
import { observer } from 'mobx-react-lite'
import ErrorLabel from '@supplyhound/components/common/ErrorLabel'
import { IonSpinner } from '@ionic/react'

export interface PromoCodeValues {
  promo_code: string
  promotion: Promotion | undefined
}

export interface PromoCodeFormProps {
  promo_code: string
  onSubmit: (values: PromoCodeValues) => void
  subtotal: number
  ordered_directly: boolean
  supplier_id?: number
}

const Container = styled.div`
  display: flex;
  position: relative;
`
const PromoField = styled(InputMask)`
  background: none;
  flex-grow: 1;
  border: none;
  &:focus {
    outline: none;
  }
`
const PromoButton = styled.button`
  border-radius: 15px;
  width: 100px;
  background-color: var(--ion-color-tertiary);
  color: var(--perma-dark-color);
  font-weight: 500;
  font-size: 0.8rem;
  padding: 5px 0px;
  cursor: pointer;
`

const PromoInput: React.FC<FieldProps> = ({ field: { value, name }, form }) => {
  return (
    <PromoField
      mask={''}
      placeholder="Enter code"
      value={value}
      onChange={e => {
        const value = e.target.value
        form.setFieldValue(name, value)
      }}
    />
  )
}

const PromoCodeForm: React.FC<PromoCodeFormProps & FormikProps<PromoCodeValues>> = ({
  submitForm,
  errors,
  touched,
  isSubmitting,
}) => {
  return (
    <>
      <Container>
        <Field name="promo_code" component={PromoInput} type="text" label="" placeholder="Enter code" />
        {isSubmitting ? (
          <IonSpinner />
        ) : (
          <PromoButton type="submit" onClick={submitForm}>
            Apply
          </PromoButton>
        )}
      </Container>
      {touched.promo_code && errors.promo_code && <ErrorLabel>{errors.promo_code}</ErrorLabel>}
    </>
  )
}

export default flowRight(
  withFormik<PromoCodeFormProps, PromoCodeValues>({
    displayName: 'PromoCodeForm',
    enableReinitialize: true,
    mapPropsToValues({ promo_code }: PromoCodeFormProps): PromoCodeValues {
      return { promo_code } as PromoCodeValues
    },
    handleSubmit(values, { props: { onSubmit, subtotal, ordered_directly, supplier_id }, setFieldError }) {
      const { promo_code } = values
      return validatePromoCode(promo_code, subtotal, ordered_directly, supplier_id)
        .then(({ promotion }) => {
          onSubmit({ promo_code, promotion })
        })
        .catch(error => {
          let msg: string
          onSubmit({ promo_code: '', promotion: undefined })
          if ([400, 404].includes(error.response.status)) {
            msg = 'This promo code is invalid. Please try again or contact SupplyHound support.'
          } else if (error.response.status === 500) {
            msg = 'There was an issue processing the promo code. Please contact SupplyHound support.'
          } else {
            throw error
          }
          setFieldError('promo_code', msg)
        })
    },
    validateOnBlur: false,
    validateOnChange: false,
  }),
  observer
)(PromoCodeForm)
