import { DatetimeChangeEventDetail, IonButton, IonContent, IonDatetime, IonModal } from '@ionic/react'
import React, { useRef } from 'react'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import { FieldProps } from 'formik'

type Props = {
  close: () => void
  isOpen: boolean
  presentation?: 'date-time' | 'date' | 'time'
  dateMin?: DateTime
}

const StyledModal = styled(IonModal)`
  --height: 350px;
  --width: 350px;

  &.modal-card {
    align-items: center;
  }
`

const StyledIonDateTime = styled(IonDatetime)`
  --ion-color-base: var(--greyscale-10);
`

const DatetimeModalField: React.FC<FieldProps & Props> = ({
  field: { name, value },
  form,
  isOpen,
  close,
  presentation = 'date-time',
  dateMin,
}) => {
  const ref = useRef<HTMLIonDatetimeElement>(null)

  const handleConfirm = () => ref.current?.confirm()

  // Add 2 hours because that's fastest window we offer for delivery
  const min = dateMin || DateTime.now().plus({ hours: 2 })

  const handleChange = (e: CustomEvent<DatetimeChangeEventDetail>) => {
    if (!e.detail.value) return

    let datetime = DateTime.fromISO(e.detail.value)

    if (datetime < min) {
      datetime = min
    }

    // if we are using date presentation, set delivery time to end of day
    if (presentation === 'date') {
      datetime = datetime.startOf('day')
    }

    form.setFieldValue(name, datetime.toISO())
    close()
  }

  const nextMonth = DateTime.now().month + 1 > 12 ? 1 : DateTime.now().month + 1

  return (
    <StyledModal onDidDismiss={close} isOpen={isOpen}>
      <IonContent>
        <StyledIonDateTime
          value={value || dateMin}
          ref={ref}
          onIonChange={handleChange}
          color="ion-text-color"
          className="absolutely-centered"
          minuteValues="0,15,30,45"
          monthValues={[DateTime.now().month, nextMonth]}
          presentation={presentation}
          min={min.toISO()}
        >
          <IonButton slot="buttons" onClick={handleConfirm}>
            Confirm
          </IonButton>
        </StyledIonDateTime>
      </IonContent>
    </StyledModal>
  )
}

export default DatetimeModalField
