import React from 'react'
import { IonButton } from '@ionic/react'
import Icon from '@supplyhound/components/common/Icon'
import styled from 'styled-components'
import { addOutline } from 'ionicons/icons'
import { ButtonType } from '@supplyhound/types'

const StyledIonButton = styled(IonButton)`
  box-sizing: border-box;

  --background: linear-gradient(180deg, var(ion-color-primary) 0%, #f0c32a 100%);

  --border-radius: 21px;
  --background-activated: none;
  --background-focused: none;
  --background-hover: none;
  --background-color: ;

  ::part(native) {
    text-transform: none;
    --padding-start: 15px;
    --padding-end: 15px;
    --padding-top: 15px;
    --padding-bottom: 15px;
  }
`
const AddButton: ButtonType = ({ children, ...props }) => (
  <StyledIonButton {...props}>
    <Icon icon={addOutline} /> {children}
  </StyledIonButton>
)

export default AddButton
