import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IonGrid, IonRow, IonCol, IonButton, useIonModal } from '@ionic/react'
import { personOutline } from 'ionicons/icons'
import { observer } from 'mobx-react-lite'
import { useLoadingState, useStores as useCommonStores } from '@supplyhound/hooks'
import useStores from 'hooks/useStore'
import { Employee } from 'types'

import ActionModal from '@supplyhound/components/common/Modals/ActionModal'

const Grid = styled(IonGrid)<{ $isAdmin?: boolean }>`
  border: 1px solid var(--ion-border-color);
  border-radius: var(--ion-border-radius);
  background-color: var(--greyscale-1);

  ${props => (props.$isAdmin ? 'overflow-x: scroll;' : '')}
`

const Row = styled(IonRow)<{ $isAdmin?: boolean }>`
  border-bottom: 1px solid var(--ion-border-color);
  ${props => (props.$isAdmin ? 'width: 568px;' : '')}

  &:last-child {
    border-bottom: none;
  }
`

const HeaderRow = styled(Row)`
  font-weight: 800;
`

const Col = styled(IonCol)`
  border-right: 1px solid var(--ion-border-color);
  word-wrap: break-all;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &:last-child {
    border-right: none;
  }
`

const NameCol = styled(Col)`
  flex: 0 0 100px;
`

const EmailCol = styled(Col)`
  flex: 1 0 200px;
  word-break: break-all;
`

const RoleCol = styled(Col)`
  flex: 0 0 100px;
`

const ActionCol = styled(Col)`
  flex: 0 0 100px;
`

const ActionButton = ({ employee, onSubmit }: { employee: Employee; onSubmit: () => void }) => {
  const { companyStore } = useStores()
  const { loadWhile } = useLoadingState()

  const [presentDeleteModal, dismissDeleteModal] = useIonModal(ActionModal, {
    icon: personOutline,
    title: `Remove Member`,
    subtext: `Are you sure you want to remove ${employee.user.name} (${employee.user.email})?`,
    submitText: 'Remove member',
    onDismiss: () => dismissDeleteModal(),
    onSubmit: () => {
      loadWhile(async () => {
        await companyStore.dispatchRemoveEmployee({ id: employee.user.id })
      }).then(() => {
        onSubmit()
        dismissDeleteModal()
      })
    },
  })

  return (
    <IonButton
      size="small"
      onClick={() => {
        presentDeleteModal()
      }}
    >
      Delete
    </IonButton>
  )
}

const EmployeeDirectory = () => {
  const { userProfileStore } = useCommonStores()
  const { companyStore } = useStores()
  const [employees, setEmployees] = useState<Employee[]>([])
  const { loadWhile } = useLoadingState()

  useEffect(() => {
    loadWhile(async () => {
      await companyStore.dispatchFetchCompany()
      setEmployees(companyStore.employeesJSON)
    })
  }, [])

  if (!userProfileStore.profile) {
    return null
  }

  const isCompanyAdministrator = userProfileStore.profile.subscription!.administrator

  return (
    <Grid $isAdmin={isCompanyAdministrator}>
      <HeaderRow $isAdmin={isCompanyAdministrator}>
        <NameCol>Name</NameCol>
        <EmailCol>Email</EmailCol>
        <RoleCol>Role</RoleCol>
        {isCompanyAdministrator && <ActionCol>Action</ActionCol>}
      </HeaderRow>
      {employees.map((employee: Employee) => {
        const { id, user, role } = employee
        return (
          <Row key={id} $isAdmin={isCompanyAdministrator}>
            <NameCol>{user.name}</NameCol>
            <EmailCol>{user.email}</EmailCol>
            <RoleCol>{role}</RoleCol>
            {isCompanyAdministrator && (
              <ActionCol>
                {role !== 'admin' && (
                  <ActionButton
                    employee={employee}
                    onSubmit={() => {
                      setEmployees([...employees.filter(employee => employee.id !== id)])
                    }}
                  />
                )}
              </ActionCol>
            )}
          </Row>
        )
      })}
    </Grid>
  )
}

export default observer(EmployeeDirectory)
