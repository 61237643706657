import React from 'react'
import styled from 'styled-components'
import { Field, FormikBag, FormikProps, withFormik } from 'formik'
import { ID, UserProfile } from '@supplyhound/types'
import TextInputField from '@supplyhound/forms/fields/TextInputField'
import * as Yup from 'yup'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import { helpCircle, helpCircleOutline } from 'ionicons/icons'
import IconPopover from '@supplyhound/components/IconPopover'

const StyledField = styled(Field)`
  margin-bottom: var(--space-unit);
  margin-top: 5px;
`

const StyledHelpPopover = styled(IconPopover)`
  padding-top: 5px;
  padding-left: 7px;
`

export interface EmailProfile {
  id: ID
  accounting_emails: string
}

interface ProfileFormProps {
  user: UserProfile
  onSubmit: (arg0: ID, arg1: Pick<UserProfile, 'accounting_emails'>) => Promise<void>
}

const AccountingEmailForm: React.FC<ProfileFormProps & FormikProps<EmailProfile>> = ({ submitForm }) => {
  return (
    <div>
      <h2>Billing email(s)</h2>
      <StyledField
        name="accounting_emails"
        placeholder="Billing email(s)"
        component={TextInputField}
        type="email"
        extraContent={
          <StyledHelpPopover
            id="accounting_emails_id"
            icon={helpCircleOutline}
            clickedIcon={helpCircle}
            content="Your SupplyHound receipts will be sent to each email entered. Add multiple emails by separating them with a comma."
          />
        }
      />

      <SubmitButton color="primary" onClick={submitForm}>
        Save email(s)
      </SubmitButton>
    </div>
  )
}

export default withFormik<ProfileFormProps, EmailProfile>({
  displayName: 'AccountingEmailForm',
  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    accounting_emails: Yup.array()
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value
        }
        return originalValue ? originalValue.split(/[\s,]+/) : []
      })
      .of(Yup.string().email(({ value }) => `${value} is not a valid email`))
      .required('Enter a valid email address in the format name@example.com'),
  }),

  handleSubmit(values: EmailProfile, { props: { onSubmit } }: FormikBag<ProfileFormProps, EmailProfile>) {
    // We convert the emails array to string to populate the field and needs to be converted back before submitting
    const accounting_emails = values.accounting_emails.split(',')

    onSubmit(values.id, { accounting_emails: accounting_emails })
  },

  mapPropsToValues: ({ user }) => {
    return {
      id: user.id,
      accounting_emails: user.accounting_emails.join(),
    }
  },
})(AccountingEmailForm)
