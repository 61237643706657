import React, { useState, useCallback } from 'react'

import styled from 'styled-components'
import Card from '@supplyhound/components/common/Card'

import useStores from '@supplyhound/hooks/useStores'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import useLoadingState from '@supplyhound/hooks/useLoadingState'

import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { createSetupIntent as createSetupIntentApi } from '@supplyhound/api'
import { helpCircleOutline, helpCircle } from 'ionicons/icons'
import { IonLabel } from '@ionic/react'
import Spacer from '@supplyhound/components/common/Spacer'
import IconPopover from '@supplyhound/components/IconPopover'

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding: 10px;
`
const RowContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  font-size: 0.8rem;
  font-weight: 400;
`

const SeurityCardRow = styled.div`
  display: flex;
  width: 100%;
`

const StyledSubmitButton = styled(SubmitButton)`
  width: 100%;
`
const BorderBottom = styled(Container)`
  border-bottom: 1px solid var(--ion-border-color);
`
const BorderRight = styled(Container)`
  border-right: 1px solid var(--ion-border-color);
  padding-left: 22px;
`
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 22px;
`

const HelperText = styled.span`
  text-align: right;
  font-weight: 400;
  font-size: 14px;
`

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
      boxShadow: 'none',
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

interface CreditCardFormProps {
  onSubmit: () => void | null
}

const CreditCardForm: React.FC<CreditCardFormProps> = ({ onSubmit }) => {
  const { userProfileStore, paymentMethodStore } = useStores()
  const profile = userProfileStore.profile
  const { loadWhile } = useLoadingState()

  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState('')

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault()
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      if (!profile || !stripe || !elements) {
        return
      }

      try {
        const result = await loadWhile(async () => {
          // Create SetupIntent
          const { data } = await createSetupIntentApi()
          const setupIntentClientSecret = data.client_secret

          // Add card to the SetupIntent
          const response = await stripe.confirmCardSetup(setupIntentClientSecret, {
            payment_method: {
              card: elements.getElement(CardNumberElement)!,
              billing_details: {
                name: profile.name,
                email: profile.email,
                phone: profile.phone,
              },
            },
          })
          const error = response.error
          if (error) {
            switch (error.code) {
              case 'incomplete_number':
                throw new Error('Enter the 16 digit card number')
              case 'incomplete_expiry':
                throw new Error('Enter the card expiration date (ie 09/24)')
              case 'incomplete_cvc':
                throw new Error('Enter the 3 digit CVC code on the back of your card')
              default:
                throw new Error(error.message)
            }
          }
          await paymentMethodStore.dispatchFetchPaymentMethods()

          return response
        })
        const newPaymentId = result.setupIntent?.payment_method
        newPaymentId && paymentMethodStore.updateSelectedPaymentMethod(newPaymentId)

        // Clear Elements on submit
        elements.getElement(CardNumberElement)?.clear()
        elements.getElement(CardExpiryElement)?.clear()
        elements.getElement(CardCvcElement)?.clear()
        setError('')
        onSubmit && onSubmit()
      } catch (e) {
        setError(e.message)
      }
    },
    [profile, stripe, elements, setError]
  )

  return (
    <>
      <Container>
        <div>Card information</div>
        <Card>
          <BorderBottom>
            <div>
              <CardNumberElement
                options={{
                  classes: { base: 'StripeInputNoBoxShadow' },
                  showIcon: true,
                  placeholder: 'xxxx-xxxx-xxxx-xxxx',
                  ...CARD_ELEMENT_OPTIONS,
                }}
              />
            </div>
          </BorderBottom>
          <RowContainer>
            <BorderRight>
              Expiration{' '}
              <CardExpiryElement
                options={{
                  classes: { base: 'StripeInputNoBoxShadow' },
                  placeholder: 'xx/xx',
                  ...CARD_ELEMENT_OPTIONS,
                }}
              />
            </BorderRight>
            <SeurityCardRow>
              <Container>
                CVV
                <RowContainer>
                  <CardCvcElement
                    options={{
                      classes: { base: 'StripeInputNoBoxShadow' },
                      placeholder: 'xxx',
                      ...CARD_ELEMENT_OPTIONS,
                    }}
                  />
                </RowContainer>
              </Container>
              <IconContainer>
                <IconPopover
                  id="creditCardHelper"
                  icon={helpCircleOutline}
                  clickedIcon={helpCircle}
                  content={<HelperText>This is the 3 digit code on the back of your card.</HelperText>}
                />
              </IconContainer>
            </SeurityCardRow>
          </RowContainer>
        </Card>
        {error && (
          <Container>
            <IonLabel color="danger" position="stacked">
              {error}
            </IonLabel>
          </Container>
        )}
        <Spacer height={20} />
        <StyledSubmitButton size="large" onClick={handleSubmit}>
          Add Card
        </StyledSubmitButton>
      </Container>
    </>
  )
}

export default CreditCardForm
