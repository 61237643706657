import React from 'react'
import SubscriptionSignup from './Signup'
import SubscriptionManage from './Manage'

const SubscriptionModal: React.FC<{
  dismiss: () => void
  type: 'manage' | 'create'
  history?: any
}> = ({ dismiss, type, history }) => {
  return type === 'create' ? (
    <SubscriptionSignup dismiss={dismiss} history={history} />
  ) : (
    <SubscriptionManage dismiss={dismiss} />
  )
}

export default SubscriptionModal
