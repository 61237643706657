import { DateTime } from 'luxon'
import { keyBy, pick, omit, without } from 'lodash'

import {
  DeliveryType,
  DeliveryTypeConfig,
  LabeledValue,
  PickupType,
  PickupTypeConfig,
  SupplierDeliveryDate,
  SupplierDeliveryType,
  SupplierDeliveryTypeConfig,
} from '@supplyhound/types'

import { DELIVERY_OPTION_EXTRA_FEE } from '@supplyhound/utils/config'

export type DeliveryConfigLabel = LabeledValue<DeliveryType>
export type PickupConfigLabel = LabeledValue<PickupType>
export type SupplierDeliveryDateConfigLabel = LabeledValue<SupplierDeliveryDate>
export type SupplierDeliveryTypeConfigLabel = LabeledValue<SupplierDeliveryType>

/**
 * Returns end of the current quarter
 *
 * Ex:
 *    // 1 September 2014 12:23:45 to 1 September 2014 12:30:00
 *    var result = getEndOfQuarter(new Date(2014, 8, 1, 12, 23, 45))
 *
 *    // 1 September 2014 12:53:45 to 1 September 2014 13:00:00:
 *    var result = getEndOfQuarter(new Date(2014, 8, 1, 12, 53, 45))
 *
 * @param date The date object to manipulate
 * @returns DateTime object of the end of the current quarter
 */
const getEndOfQuarter = (date: DateTime): DateTime => {
  const quarter = 15 * 60 * 1000
  const endOfQuarterInMillis = Math.ceil(date.valueOf() / quarter) * quarter

  return DateTime.fromMillis(endOfQuarterInMillis)
}

/**
 * @see https://docs.google.com/drawings/d/1Y1FDH22ZnVORXQaPREqPNbXG80tNl5gznYzBl49NTTs/
 */
export const DeliveryTypeConfigs = keyBy<DeliveryTypeConfig>(
  [
    {
      value: DeliveryType.TwoHours,
      label: `2-Hours (+$${DELIVERY_OPTION_EXTRA_FEE[DeliveryType.TwoHours]})`,
      isEnabled(tz) {
        const now = DateTime.now().startOf('second').setZone(tz)
        const today2pm = now.set({ hour: 14, minute: 0, second: 0 })
        // Enabled until 2 pm
        return now <= today2pm
      },
      computeDatetimes(tz) {
        const now = DateTime.now().startOf('second').setZone(tz)
        const today530am = now.set({ hour: 5, minute: 30, second: 0 })
        const today6am = now.set({ hour: 6, minute: 0, second: 0 })

        // If now is before 5:30 am, set the pickup datetime to 6 am, otherwise, to now
        const pickupDatetime = now <= today530am ? today6am : getEndOfQuarter(now).setZone(tz)
        // Set the delivery datetime to 2 hours past the pickup datetiime
        const deliveryDatetime = pickupDatetime.plus({ hours: 2 })

        return [pickupDatetime.toISO(), deliveryDatetime.toISO()]
      },
    },
    {
      value: DeliveryType.FourHours,
      label: `4-Hours (+$${DELIVERY_OPTION_EXTRA_FEE[DeliveryType.FourHours]})`,
      isEnabled(tz) {
        const now = DateTime.now().startOf('second').setZone(tz)
        const today530am = now.set({ hour: 5, minute: 30, second: 0 })
        const today1pm = now.set({ hour: 13, minute: 0, second: 0 })

        // Enabled during 5:30 am - 1 pm
        return now >= today530am && now <= today1pm
      },
      computeDatetimes(tz) {
        const now = DateTime.now().startOf('second').setZone(tz)

        // Set the pickup datetime to now
        const pickupDatetime = getEndOfQuarter(now).setZone(tz)
        // Set the delivery datetime to 4 hours past the pickup datetime
        const deliveryDatetime = pickupDatetime.plus({ hours: 4 })

        return [pickupDatetime.toISO(), deliveryDatetime.toISO()]
      },
    },
    {
      value: DeliveryType.SameDay,
      label: 'Same Day - by 3:30',
      isEnabled(tz) {
        const now = DateTime.now().startOf('second').setZone(tz)
        const today11am = now.set({ hour: 11, minute: 0, second: 0 })

        // Enabled until 11 am
        return now <= today11am
      },
      computeDatetimes(tz) {
        const now = DateTime.now().startOf('second').setZone(tz)
        const today7am = now.set({ hour: 7, minute: 0, second: 0 })
        const today4pm = now.set({ hour: 16, minute: 0, second: 0 })

        // Sets pickup datetime to the greater one of current local time and 7 am
        const pickupDatetime = DateTime.max(getEndOfQuarter(now), today7am).setZone(tz)
        // Sets delivery datetime to 4 pm
        const deliveryDatetime = today4pm

        return [pickupDatetime.toISO(), deliveryDatetime.toISO()]
      },
    },
    {
      value: DeliveryType.Tomorrow9am,
      label: `Tomorrow by 9 am (+$${DELIVERY_OPTION_EXTRA_FEE[DeliveryType.Tomorrow9am]})`,
      computeDatetimes(tz) {
        const tomorrow = DateTime.now().plus({ days: 1 }).startOf('second').setZone(tz)
        const tomorrow6am = tomorrow.set({ hour: 6, minute: 0, second: 0 })
        const tomorrow9am = tomorrow.set({ hour: 9, minute: 0, second: 0 })

        // Sets pickup time to 6am the next day
        const pickupDatetime = tomorrow6am
        // Sets delivery time to 9am the next day
        const deliveryDatetime = tomorrow9am

        return [pickupDatetime.toISO(), deliveryDatetime.toISO()]
      },
    },
    {
      value: DeliveryType.Tomorrow2pm,
      label: 'Tomorrow by 2 pm',
      computeDatetimes(tz) {
        const tomorrow = DateTime.now().plus({ days: 1 }).startOf('second').setZone(tz)
        const tomorrow6am = tomorrow.set({ hour: 6, minute: 0, second: 0 })
        const tomorrow2pm = tomorrow.set({ hour: 14, minute: 0, second: 0 })

        // Sets pickup time to 6am the next day
        const pickupDatetime = tomorrow6am
        // Sets delivery time to 2pm the next day
        const deliveryDatetime = tomorrow2pm

        return [pickupDatetime.toISO(), deliveryDatetime.toISO()]
      },
    },
    {
      value: DeliveryType.Custom,
      label: 'Custom',
      computeDatetimes(tz: string, deliveryDatetimeString: string) {
        const deliveryDatetime = DateTime.fromISO(deliveryDatetimeString, { zone: tz, setZone: true })
          .startOf('second')
          .setZone(tz, { keepLocalTime: true })

        // If it's today, default to 30 minutes from now,
        // otherwise default to 7:00 am on the same day as the delivery time
        const pickupDatetime = deliveryDatetime.hasSame(DateTime.now().setZone(tz), 'day')
          ? DateTime.now().plus({ minutes: 30 }).startOf('second').setZone(tz)
          : deliveryDatetime.set({ hour: 7, minute: 0, second: 0 })
        return [pickupDatetime.toISO(), deliveryDatetime.toISO()]
      },
    },

    // Curri dleivery options
    {
      value: DeliveryType.Rush,
      label: `Rush (+$${DELIVERY_OPTION_EXTRA_FEE[DeliveryType.Rush]})`,
      isEnabled(tz) {
        const now = DateTime.now().startOf('second').setZone(tz)
        const today3pm = now.set({ hour: 15, minute: 0, second: 0 })

        // Enabled until 11 am
        return now <= today3pm
      },
      computeDatetimes(tz) {
        const now = DateTime.now().startOf('second').setZone(tz)
        const today530am = now.set({ hour: 5, minute: 30, second: 0 })
        const today6am = now.set({ hour: 6, minute: 0, second: 0 })

        // If now is before 5:30 am, set the pickup datetime to 6 am, otherwise, to now
        const pickupDatetime = now <= today530am ? today6am : getEndOfQuarter(now).setZone(tz)
        // Set the delivery datetime to 2 hours past the pickup datetiime
        const deliveryDatetime = pickupDatetime.plus({ hours: 2 })

        return [pickupDatetime.toISO(), deliveryDatetime.toISO()]
      },
    },
    {
      value: DeliveryType.SameDay,
      label: 'Same Day',
      isEnabled(tz) {
        const now = DateTime.now().startOf('second').setZone(tz)
        const today12am = now.set({ hour: 12, minute: 0, second: 0 })

        // Enabled until 11 am
        return now <= today12am
      },
      computeDatetimes(tz) {
        const now = DateTime.now().startOf('second').setZone(tz)
        const today7am = now.set({ hour: 7, minute: 0, second: 0 })
        const today4pm = now.set({ hour: 16, minute: 0, second: 0 })

        // Sets pickup datetime to the greater one of current local time and 7 am
        const pickupDatetime = DateTime.max(getEndOfQuarter(now), today7am).setZone(tz)
        // Sets delivery datetime to 4 pm
        const deliveryDatetime = today4pm

        return [pickupDatetime.toISO(), deliveryDatetime.toISO()]
      },
    },
    {
      value: DeliveryType.Tomorrow9amCurri,
      label: `Tomorrow by 9 am (+$${DELIVERY_OPTION_EXTRA_FEE[DeliveryType.Tomorrow9amCurri]})`,
      computeDatetimes(tz) {
        const tomorrow = DateTime.now().plus({ days: 1 }).startOf('second').setZone(tz)
        const tomorrow7am = tomorrow.set({ hour: 7, minute: 0, second: 0 })
        const tomorrow9am = tomorrow.set({ hour: 9, minute: 0, second: 0 })

        // Sets pickup time to 6am the next day
        const pickupDatetime = tomorrow7am
        // Sets delivery time to 9am the next day
        const deliveryDatetime = tomorrow9am

        return [pickupDatetime.toISO(), deliveryDatetime.toISO()]
      },
    },
    {
      value: DeliveryType.Tomorrow3pmCurri,
      label: `Tomorrow by 3 pm`,
      computeDatetimes(tz) {
        const tomorrow = DateTime.now().plus({ days: 1 }).startOf('second').setZone(tz)
        const tomorrow9am = tomorrow.set({ hour: 9, minute: 0, second: 0 })
        const tomorrow3pm = tomorrow.set({ hour: 15, minute: 0, second: 0 })

        // Sets pickup time to 6am the next day
        const pickupDatetime = tomorrow9am
        // Sets delivery time to 9am the next day
        const deliveryDatetime = tomorrow3pm

        return [pickupDatetime.toISO(), deliveryDatetime.toISO()]
      },
    },
    {
      value: DeliveryType.Monday9amCurri,
      label: `Monday by 9 am (+$${DELIVERY_OPTION_EXTRA_FEE[DeliveryType.Monday9amCurri]})`,
      computeDatetimes(tz) {
        const today = DateTime.now().setZone(tz)
        let daysToMonday = 0
        switch (today.weekday) {
          case 5:
            daysToMonday = 3
            break
          case 6:
            daysToMonday = 2
            break
          default:
            daysToMonday = 0
            break
        }
        const monday = DateTime.now().plus({ days: daysToMonday }).startOf('second').setZone(tz)
        const monday7am = monday.set({ hour: 7, minute: 0, second: 0 })
        const monday9am = monday.set({ hour: 9, minute: 0, second: 0 })

        // Sets pickup time to 6am the next day
        const pickupDatetime = monday7am
        // Sets delivery time to 9am the next day
        const deliveryDatetime = monday9am

        return [pickupDatetime.toISO(), deliveryDatetime.toISO()]
      },
    },
    {
      value: DeliveryType.Monday3pmCurri,
      label: `Monday by 3 pm`,
      computeDatetimes(tz) {
        const today = DateTime.now().setZone(tz)
        let daysToMonday = 0
        switch (today.weekday) {
          case 5:
            daysToMonday = 3
            break
          case 6:
            daysToMonday = 2
            break
          default:
            daysToMonday = 0
            break
        }
        const monday = DateTime.now().plus({ days: daysToMonday }).startOf('second').setZone(tz)
        const monday9am = monday.set({ hour: 9, minute: 0, second: 0 })
        const monday3pm = monday.set({ hour: 15, minute: 0, second: 0 })

        // Sets pickup time to 6am the next day
        const pickupDatetime = monday9am
        // Sets delivery time to 9am the next day
        const deliveryDatetime = monday3pm

        return [pickupDatetime.toISO(), deliveryDatetime.toISO()]
      },
    },
    {
      value: DeliveryType.Scheduled,
      label: `Schedule (+$${DELIVERY_OPTION_EXTRA_FEE[DeliveryType.Scheduled]})`,
      computeDatetimes(tz: string, deliveryDatetimeString: string) {
        const deliveryDatetime = DateTime.fromISO(deliveryDatetimeString, { zone: tz, setZone: true })
          .startOf('second')
          .setZone(tz, { keepLocalTime: true })

        // If it's today, default to 30 minutes from now,
        // otherwise default to 7:00 am on the same day as the delivery time
        const pickupDatetime = deliveryDatetime.hasSame(DateTime.now().setZone(tz), 'day')
          ? DateTime.now().plus({ minutes: 30 }).startOf('second').setZone(tz)
          : deliveryDatetime.set({ hour: 7, minute: 0, second: 0 })
        return [pickupDatetime.toISO(), deliveryDatetime.toISO()]
      },
    },
  ],
  'value'
)

export const PickupTypeConfigs = keyBy<PickupTypeConfig>(
  [
    {
      value: PickupType.TwoHours,
      label: '2 hours',
      isEnabled(tz: string) {
        const now = DateTime.now().startOf('second').setZone(tz)
        const today530am = now.set({ hour: 5, minute: 30, second: 0 })
        const today2pm = now.set({ hour: 14, minute: 0, second: 0 })

        return now >= today530am && now <= today2pm
      },
      computePickupDatetime(tz: string) {
        const now = DateTime.now().startOf('second').setZone(tz)

        return getEndOfQuarter(now).plus({ hours: 2 }).setZone(tz).toISO()
      },
    },
    {
      value: PickupType.FourHours,
      label: '4 hours',
      isEnabled(tz: string) {
        const now = DateTime.now().startOf('second').setZone(tz)
        const today530am = now.set({ hour: 5, minute: 30, second: 0 })
        const today1pm = now.set({ hour: 13, minute: 0, second: 0 })

        return now >= today530am && now <= today1pm
      },
      computePickupDatetime(tz: string) {
        const now = DateTime.now().startOf('second').setZone(tz)

        return getEndOfQuarter(now).plus({ hours: 4 }).setZone(tz).toISO()
      },
    },
    {
      value: PickupType.TodayAfter2pm,
      label: 'Today after 2pm',
      isEnabled(tz: string) {
        const now = DateTime.now().startOf('second').setZone(tz)
        const today12pm = now.set({ hour: 12, minute: 0, second: 0 })

        return now <= today12pm
      },
      computePickupDatetime(tz: string) {
        const now = DateTime.now().startOf('second').setZone(tz)

        return now.set({ hour: 14, minute: 0, second: 0 }).toISO()
      },
    },
    {
      value: PickupType.TomorrowBy7am,
      label: 'Tomorrow by 7am',
      computePickupDatetime(tz: string) {
        const tomorrow = DateTime.now().plus({ days: 1 }).startOf('second').setZone(tz)

        return tomorrow.set({ hour: 7, minute: 0, second: 0 }).toISO()
      },
    },
    {
      value: PickupType.TomorrowAfter2pm,
      label: 'Tomorrow after 2pm',
      computePickupDatetime(tz: string) {
        const tomorrow = DateTime.now().plus({ days: 1 }).startOf('second').setZone(tz)

        return tomorrow.set({ hour: 14, minute: 0, second: 0 }).toISO()
      },
    },
  ],
  'value'
)

export const SupplierDeliveryTypeConfigs = keyBy<SupplierDeliveryTypeConfig>(
  [
    {
      value: SupplierDeliveryType.Anytime,
      label: 'Anytime',
    },
    {
      value: SupplierDeliveryType.Morning,
      label: '9am - 12pm',
    },
    {
      value: SupplierDeliveryType.Afternoon,
      label: 'Afternoon',
    },
  ],
  'value'
)

export const deliveryTypeIsUsingCustomDeliveryDate = (value: DeliveryType) => {
  return value === DeliveryType.Custom || value === DeliveryType.Scheduled
}

const getLabeledConfigs = (
  config: typeof DeliveryTypeConfigs | typeof PickupTypeConfigs | typeof SupplierDeliveryTypeConfigs,
  tz?: string
) =>
  Object.values(config).map(({ label, value, isEnabled }) => {
    const disabled = isEnabled ? !isEnabled(tz) : false

    return {
      label,
      value,
      disabled,
    }
  })

const curriDeliveryTypes = [
  DeliveryType.Rush,
  DeliveryType.SameDay,
  DeliveryType.Tomorrow9amCurri,
  DeliveryType.Tomorrow3pmCurri,
  DeliveryType.Scheduled,
]
const curriDeliveryTypesConfig = pick(DeliveryTypeConfigs, curriDeliveryTypes)
const curriDeliveryFriSatTypes = [
  DeliveryType.Rush,
  DeliveryType.SameDay,
  DeliveryType.Monday9amCurri,
  DeliveryType.Monday3pmCurri,
  DeliveryType.Scheduled,
]
const curriDeliveryTypesFriSatConfig = pick(DeliveryTypeConfigs, curriDeliveryFriSatTypes)

export const getLabeledDeliveryConfigs = (hasCurriIntegtration: boolean, tz?: string): DeliveryConfigLabel[] => {
  if (hasCurriIntegtration) {
    const today = DateTime.now().setZone(tz)
    // If Friday (5) or Saturday (6) return configuration with Monday delivery options
    return [5, 6].includes(today.weekday)
      ? getLabeledConfigs(curriDeliveryTypesFriSatConfig, tz)
      : getLabeledConfigs(curriDeliveryTypesConfig, tz)
  }
  return getLabeledConfigs(omit(DeliveryTypeConfigs, without(curriDeliveryTypes, DeliveryType.SameDay)), tz)
}
export const getLabeledSupplierDeliveryTypeConfigs = (tz?: string): SupplierDeliveryTypeConfigLabel[] => {
  return getLabeledConfigs(SupplierDeliveryTypeConfigs, tz)
}
export const getLabeledPickupConfigs = (tz?: string): PickupConfigLabel[] => getLabeledConfigs(PickupTypeConfigs, tz)

export const supplierDeliveryDateOptions = () => {
  const options: { [key: string]: string }[] = []

  for (let i = 1; i <= 5; i++) {
    let obj: { [key: string]: string } = {}
    const datetime = DateTime.now().plus({ days: i }).startOf('day')
    const month = datetime.month.toString()
    const day = datetime.day.toString()
    obj['value'] = datetime.startOf('day').toISO()
    obj['label'] = `${month}/${day}`
    options.push(obj)
  }
  options.push({ label: 'Custom', value: 'custom' })
  return options
}

export const dateDisplayFormatter = (datetime: string) => {
  const date = new Date(datetime)
  return date.toLocaleDateString().split('/').slice(0, 2).join('/')
}
