import { axiosInstance } from '@supplyhound/api'
import { ID } from '@supplyhound/types'
import { Company, Invite } from 'types'

export const fetchCompany = (): Promise<Company> =>
  axiosInstance.get('/v1/company').then(({ data: { company } }) => company)

export const inviteEmployee = ({ email }: { email: string }): Promise<Invite> =>
  axiosInstance
    .post('/v1/company/invite', {
      email,
    })
    .then(({ data: { invitation } }) => invitation)

export const removeEmployee = ({ id }: { id: ID }): Promise<void> =>
  axiosInstance.post('/v1/company/remove_employee', {
    user_id: id,
  })

export const fetchInvites = (): Promise<Invite[]> =>
  axiosInstance.get('/v1/company/invites').then(({ data: { invitations } }) => invitations)

export const revokeEmployeeInvite = ({ id }: { id: ID }): Promise<void> =>
  axiosInstance.delete(`/v1/company/invites/${id}`)

export const resendEmployeeInvite = ({ id }: { id: ID }): Promise<void> =>
  axiosInstance.post(`/v1/company/invite/${id}/resend`)
