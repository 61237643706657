import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { IonPage } from '@ionic/react'
import { observer } from 'mobx-react-lite'

import Content from '@supplyhound/components/Content'

import CreditCardForm from '@supplyhound/forms/CreditCardForm'
import { Header, HeaderModes } from '@supplyhound/layout'

type LocationState = {
  referrer: string
  cardId?: string
}

const AddPaymentPage: React.FC = () => {
  const history = useHistory()
  const { state } = useLocation<LocationState>()

  return (
    <IonPage>
      <Header mode={HeaderModes.Leaf} label="Add Card" />
      <Content>
        <CreditCardForm onSubmit={() => history.push(state.referrer)} />
      </Content>
    </IonPage>
  )
}

export default observer(AddPaymentPage)
