import React, { useEffect, useRef, useState } from 'react'
import { IonPage } from '@ionic/react'
import styled from 'styled-components'

import { observer } from 'mobx-react-lite'
import useStores from '@supplyhound/hooks/useStores'
import { fetchContactByPlaceId } from '@supplyhound/api'

import Content from '@supplyhound/components/Content'
import Card from '@supplyhound/components/common/Card'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import {
  ContactWithCommunicationPreference,
  DeliveryTaskBillingDetails,
  JobSiteDetailRouteParams,
  TaskTypes,
  UnidentifiedDeliveryTask,
} from '@supplyhound/types'

import PaymentMethodComponent from '@supplyhound/components/PaymentMethod'
import PromoCodeForm, { PromoCodeFormProps, PromoCodeValues } from '@supplyhound/forms/PromoCodeForm'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import { JOB_SITE_ORDER_RECEIPT_ROUTE, PAYMENT_SELECT_ROUTE } from '@supplyhound/utils/config'
import { Header, HeaderModes } from '@supplyhound/layout'

import MapPin from '@supplyhound/components/MapPin'
import OrderDetails from '@supplyhound/components/OrderDetails'
import { GoogleMap } from '@react-google-maps/api'
import DirectionsMap from '@supplyhound/components/DirectionsMap'
import { GoogleMapStyles } from '@supplyhound/styles'
import Icon from '@supplyhound/components/common/Icon'
import Spacer from '@supplyhound/components/common/Spacer'
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons'
import { formatCurrency } from '@supplyhound/utils/formatters'

import { DeliveryTypeConfigs } from '@supplyhound/utils/datetimes'

const SectionContent = styled.div`
  padding: 13px 20px;
`

const StyledCard = styled(Card)`
  background-color: var(--greyscale-1);
`

const ButtonContainer = styled.div`
  padding: 5px 15px 0px 15px;
  margin-bottom: 25px;
`

const PromoDiscountCard = styled(Card)`
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  border-color: var(--ion-color-success);
  margin-bottom: 20px;
`

const StyledCheckmark = styled(Icon)`
  margin-right: 10px;
  color: var(--ion-color-success);
`

const StyledClose = styled(Icon)`
  margin-left: auto;
  color: var(--ion-color-medium);
  cursor: pointer;
`

const ContactPreferenceText = styled.span`
  font-size: 0.8rem;
  font-weight: 500;
`

type PromoCodeSectionProps = {
  task: UnidentifiedDeliveryTask
  onPromoCodeFormSubmit: PromoCodeFormProps['onSubmit']
  onPromoCodeDelete: () => void
  billingDetails: DeliveryTaskBillingDetails
}

const PromoCodeSection: React.FC<PromoCodeSectionProps> = ({
  billingDetails,
  task,
  onPromoCodeFormSubmit,
  onPromoCodeDelete,
}) => (
  <div>
    <h4>Promo code</h4>
    {billingDetails.discount ? (
      <PromoDiscountCard>
        <StyledCheckmark icon={checkmarkCircleOutline} width={40} />
        <p>{formatCurrency(billingDetails.discount)} discount applied</p>
        <StyledClose
          icon={closeCircleOutline}
          onClick={() => {
            onPromoCodeDelete()
          }}
        />
      </PromoDiscountCard>
    ) : (
      <Card>
        <SectionContent>
          <PromoCodeForm
            promo_code={task.promo_code || ''}
            subtotal={billingDetails.subtotalWithFees}
            ordered_directly={task.ordered_directly}
            supplier_id={task.supplier_id}
            onSubmit={onPromoCodeFormSubmit}
          />
        </SectionContent>
      </Card>
    )}
  </div>
)

const JobSiteTaskDetailPage: React.FC = () => {
  const { loadWhile } = useLoadingState()
  const history = useHistory()
  const {
    jobSitesStore,
    jobSitesStore: { listStore },
    paymentMethodStore,
    userProfileStore,
    userProfileStore: { subscriptionStore },
  } = useStores()

  const hasCurriIntegrationFlag = userProfileStore.profile?.feature_flags.curri_integration
  const isUserSetupForBillToAccount = userProfileStore.profile?.feature_flags.bill_to_account
  const isSaasFFOn = !!userProfileStore.profile?.feature_flags.saas

  const { id } = useParams<JobSiteDetailRouteParams>()
  const jobSiteId = parseInt(id)
  const jobSite =
    jobSiteId === jobSitesStore.deliveryOnlyJobSiteId
      ? jobSitesStore.deliveryOnlyJobSite
      : jobSitesStore.jobSites[jobSiteId]
  const task = jobSitesStore.getJobSiteTask(jobSiteId)
  const isDelivery = task?.type === TaskTypes.Delivery

  const subscription = subscriptionStore.getSubscriptionJson
  const userHasSubscription = !!subscription
  const saasIsDisabledAndDirectOrderTask = !isSaasFFOn && isDelivery && task.ordered_directly
  const hasPowerOrLegacySubscription =
    userHasSubscription && (subscription.subscription_type === 'power' || subscription.subscription_type === 'legacy')
  const hasProSubscriptionCredits =
    userHasSubscription && subscription.subscription_type === 'pro' && subscription.free_booking_fee_count > 0
  const waiveOrderManagementFee =
    hasPowerOrLegacySubscription || hasProSubscriptionCredits || saasIsDisabledAndDirectOrderTask
  const waiveOrderManagementFeePickup =
    !isSaasFFOn || (isSaasFFOn && (hasPowerOrLegacySubscription || hasProSubscriptionCredits))
  const localBilling = isDelivery
    ? jobSitesStore.getJobSiteTaskBillingDetails(jobSiteId, waiveOrderManagementFee, isSaasFFOn)
    : jobSitesStore.getJobSiteTaskBillingDetails(jobSiteId, waiveOrderManagementFeePickup, isSaasFFOn)

  const [billingDetails, setBillingDetails] = useState<DeliveryTaskBillingDetails | undefined>()

  const directionsCallback = (distance: number) => {
    jobSitesStore.updateJobSiteTask(jobSiteId, { job_distance: distance })
  }
  const marketTimeZone = userProfileStore.marketTimezone
  const updateTimes = () => {
    if (task && isDelivery) {
      const deliveryType = task.delivery_type

      if (!DeliveryTypeConfigs[deliveryType]) return

      const [updatedPickup, updatedDelivery] = DeliveryTypeConfigs[deliveryType].computeDatetimes(
        marketTimeZone,
        task.delivery_datetime
      )
      jobSitesStore.updateJobSiteTask(jobSiteId, {
        pickup_datetime: updatedPickup,
        delivery_datetime: updatedDelivery,
      })
    }
  }
  const [contactWithPreference, setContactWithPreference] = useState<ContactWithCommunicationPreference | undefined>()
  const { selectedPaymentMethodId } = paymentMethodStore
  const paymentRef = useRef<null | HTMLDivElement>(null)
  const [doesPaymentExist, setDoesPaymentExist] = useState(true)
  const handleSubmitClick = () => {
    updateTimes()
    if (selectedPaymentMethodId || isUserSetupForBillToAccount) {
      const paymentMethodId = isUserSetupForBillToAccount ? '' : selectedPaymentMethodId!
      loadWhile(() => {
        return isDelivery
          ? jobSitesStore.dispatchCreateJobSiteDeliveryTask(
              jobSiteId,
              paymentMethodId,
              waiveOrderManagementFee,
              isSaasFFOn,
              billingDetails!.quote_id,
              billingDetails!.quote_code
            )
          : jobSitesStore.dispatchCreateJobSitePickupTask(
              jobSiteId,
              paymentMethodId,
              waiveOrderManagementFeePickup,
              isSaasFFOn
            )
      }).then(() => {
        userProfileStore.dispatchLoadProfile()
        if (isDelivery && hasCurriIntegrationFlag) {
          jobSitesStore.updateLastBillingDetails(billingDetails!)
        }
        history.push(JOB_SITE_ORDER_RECEIPT_ROUTE)
      })
    } else {
      if (paymentRef.current) {
        paymentRef.current.scrollIntoView(true)
      }
      setDoesPaymentExist(false)
    }
  }

  const handlePromoCodeFormSubmit = ({ promo_code, promotion }: PromoCodeValues) => {
    jobSitesStore.updateJobSiteTask(jobSiteId, {
      promotion,
      promo_code,
    })
    if (billingDetails && promotion) {
      setBillingDetails({ ...billingDetails, discount: promotion.discount })
    }
  }

  const handlePromoCodeDelete = () => {
    jobSitesStore.updateJobSiteTask(jobSiteId, {
      promotion: undefined,
      promo_code: '',
    })
    if (billingDetails) {
      setBillingDetails({ ...billingDetails, discount: 0 })
    }
  }

  useEffect(() => {
    loadWhile(jobSitesStore.dispatchFetchJobSites)

    updateTimes()

    const timer: ReturnType<typeof setTimeout> = setInterval(() => {
      updateTimes()
    }, 10000)
    return () => {
      clearInterval(timer)
    }
  }, [task])

  useEffect(() => {
    if (jobSite && isDelivery && !task?.ordered_directly) {
      listStore.dispatchFetchList(jobSite.orderer_list.id)
    }
  }, [jobSite, isDelivery, task])

  useEffect(() => {
    jobSitesStore.updateJobSiteTask(jobSiteId, {
      ordered_with_subscription: userHasSubscription,
    })
  }, [subscription])

  useEffect(() => {
    if (!task || !isDelivery || !!billingDetails || !(task as UnidentifiedDeliveryTask)?.job_distance) return

    if (hasCurriIntegrationFlag) {
      if (!!task.delivery_type) {
        const extraFee = jobSitesStore.taskStore.taskExtraFee(task)
        loadWhile(async () => {
          return jobSitesStore
            .dispatchGetDeliveryQuotes({ ...task, extra_fee: extraFee, promo_discount: 0 })
            .then(data => {
              const quote = data
              setBillingDetails(quote)
            })
        })
      }
    } else {
      setBillingDetails(localBilling)
    }
  }, [task, billingDetails, isDelivery, (task as UnidentifiedDeliveryTask)?.job_distance])

  // Rerun quote if discount applied
  useEffect(() => {
    if (!task) {
      return
    }

    if (hasCurriIntegrationFlag && task.type === TaskTypes.Delivery) {
      loadWhile(async () => {
        const extraFee = jobSitesStore.taskStore.taskExtraFee(task)
        const quote = await jobSitesStore.dispatchGetDeliveryQuotes({
          ...task,
          extra_fee: extraFee,
          promo_discount: billingDetails?.discount ?? 0,
        })
        setBillingDetails(quote)
      })
    } else {
      setBillingDetails(localBilling)
    }
  }, [billingDetails?.discount])

  useEffect(() => {
    if (!task || isDelivery || !!billingDetails) {
      return
    }

    if (hasCurriIntegrationFlag && task.type === TaskTypes.Pickup) {
      loadWhile(async () => {
        const quote = await jobSitesStore.dispatchGetPickupQuotes(task)
        setBillingDetails(quote)
      })
    } else {
      setBillingDetails(localBilling)
    }
  }, [task, billingDetails, isDelivery])

  useEffect(() => {
    loadWhile(async () => {
      if (!task || !task.supplier || !task.supplier.place_id) {
        return
      }

      const contactWithCommunicationPreference = await fetchContactByPlaceId({
        placeId: task.supplier.place_id,
      })
      setContactWithPreference(contactWithCommunicationPreference)
    })
  }, [task?.supplier?.place_id])

  return (
    <IonPage>
      <Header label="Review" mode={HeaderModes.Leaf} />
      <ButtonContainer>
        <SubmitButton expand="block" size="large" onClick={handleSubmitClick}>
          Place Order
        </SubmitButton>
      </ButtonContainer>
      <Content>
        {isDelivery && billingDetails && (
          <PromoCodeSection
            task={task as UnidentifiedDeliveryTask}
            billingDetails={billingDetails}
            onPromoCodeFormSubmit={handlePromoCodeFormSubmit}
            onPromoCodeDelete={handlePromoCodeDelete}
          />
        )}

        <OrderDetails task={task} billingDetails={billingDetails} />

        {!isUserSetupForBillToAccount && (
          <>
            <Spacer height={25} />
            <h4>Default Payment</h4>
            <div ref={paymentRef}>
              <PaymentMethodComponent
                onClick={() => history.push(generatePath(PAYMENT_SELECT_ROUTE, { id: jobSiteId }))}
                showCreditCardRequired={!doesPaymentExist}
              />
            </div>
          </>
        )}

        <Spacer height={25} />
        {task && isDelivery ? (
          <GoogleMap options={{ gestureHandling: 'none' }} mapContainerStyle={GoogleMapStyles}>
            <DirectionsMap
              origin={task.pickup_address}
              destination={(task as UnidentifiedDeliveryTask).delivery_address}
              travelMode={google.maps.TravelMode.DRIVING}
              callback={directionsCallback}
            />
          </GoogleMap>
        ) : (
          <MapPin address={task?.pickup_address} />
        )}
        {contactWithPreference ? (
          <>
            <Spacer height={25} />
            <h4>Supplier Contact</h4>
            <StyledCard>
              <SectionContent>
                <ContactPreferenceText>
                  Note - {`${contactWithPreference.first_name} ${contactWithPreference.last_name}`} will be notified per
                  your settings. You can update your supplier communication preferences in the Contacts menu.
                </ContactPreferenceText>
              </SectionContent>
            </StyledCard>
          </>
        ) : null}
      </Content>
    </IonPage>
  )
}

export default observer(JobSiteTaskDetailPage)
