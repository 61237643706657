import React from 'react'
import styled from 'styled-components'
import { FormikProps, Field, withFormik } from 'formik'
import { IonLabel } from '@ionic/react'
import Icon from '@supplyhound/components/common/Icon'
import { cameraOutline } from 'ionicons/icons'
import { useDropzone } from 'react-dropzone'
import { Photo } from '@capacitor/camera'
import { Capacitor } from '@capacitor/core'
import usePhoto from '@supplyhound/hooks/usePhoto'
import clsx from 'clsx'
import { FileWithUrl, ID, OptionallyIdentifiedTeamMemberListItem, toEnumType } from '@supplyhound/types'

import InlineModal from '@supplyhound/components/common/Modals/InlineModal'
import BaseButton from '@supplyhound/components/buttons/BaseButton'
import TextAreaField from '@supplyhound/forms/fields/TextAreaField'
import TextInputField from './fields/TextInputField'

import ImagePreview from '@supplyhound/components/ImagePreview'
import { GenericItemValidationSchema } from '@supplyhound/utils/validationSchemas'
import { catchAndSetErrors } from '@supplyhound/utils/forms'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import { HeaderModes } from '@supplyhound/layout'
import { DEFAULT_ORDERER_LIST_ITEM } from '@supplyhound/utils/config'

import ShortCuts from './shortcuts'

export const FormModes = {
  Add: 'Add',
  Edit: 'Edit',
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Row = styled.div`
  padding-top: calc(2.5 * var(--space-unit));
`

const FooterRow = styled(Row)`
  flex: auto 1 0;
  padding: calc(2 * var(--space-unit)) 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const MoreAddOption = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
  top: 185px;
  padding: 10px 20px 10px 20px;
  justify-content: space-between;
  background-color: var(--ion-color-tertiary);
  z-index: 1000;
  font-weight: 500;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

const ImageLink = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--perma-dark-color);
`

const StyledTextAreaField = styled(TextAreaField)`
  .native-textarea {
    min-height: 140px;
    margin-bottom: 40px;
  }
`

const ImageLabel = styled(IonLabel)`
  padding-left: calc(0.5 * var(--space-unit));
`

const AddImageLabel = styled(ImageLabel)`
  color: var(--ion-color-anchor);
`

const StyledImagePreview = styled(ImagePreview)`
  position: absolute;
  top: 50px;
  left: 10px;
  z-index: 2;
`

const DescriptionWrapper = styled.div`
  position: relative;

  &.image-attached ${StyledTextAreaField} {
    padding-left: 100px;
  }
`

const RemoveItemButton = styled(BaseButton)`
  color: var(--ion-color-danger);
  height: 16px;
`

const RemoveButtonRow = styled(Row)`
  display: flex;
  justify-content: center;
`

type FormMode = toEnumType<typeof FormModes>

export type FormProps = {
  item?: OptionallyIdentifiedTeamMemberListItem
  onSubmit: (item: OptionallyIdentifiedTeamMemberListItem) => Promise<void>
  triggerResetForm?: boolean
  setShowFullItemForm?: (showFullItemForm: boolean) => any
  showFullItemForm?: boolean
  isOpen: boolean
  onCancel: () => void
  headerSubtitle?: string
  mode?: FormMode
  onDeleteItem?: (id: ID) => void
}

const TeamMemberListItemModalForm: React.FC<FormProps & FormikProps<OptionallyIdentifiedTeamMemberListItem>> = ({
  isOpen,
  values,
  onCancel,
  setFieldValue,
  submitForm,
  headerSubtitle,
  mode,
  resetForm,
  onDeleteItem,
}) => {
  const addCTAText = 'Add item to list'
  const titleText = mode === FormModes.Edit ? 'Edit item' : addCTAText
  const submitText = mode === FormModes.Edit ? 'Finish editing' : addCTAText

  const {
    getRootProps: getRootDropzoneProps,
    getInputProps: getInputDropzoneProps,
    open: openDropzone,
  } = useDropzone({
    accept: 'image/*',
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles: FileWithUrl[]) => {
      const image = acceptedFiles[0]

      image.url = URL.createObjectURL(image)
      setFieldValue('image', image)
    },
  })

  const { takePicture } = usePhoto({
    takePictureCallback: async (photo: Photo) => {
      if (photo.webPath) {
        const fetchedImage = await fetch(photo.webPath)
        const imageBlob = await fetchedImage.blob()
        const file = new File([imageBlob], `uploaded-image-${Date.now()}`) as FileWithUrl
        file.url = photo.webPath
        setFieldValue('image', file)
      }
    },
  })
  const uploadPicture = Capacitor.getPlatform() === 'web' ? openDropzone : takePicture

  const handleDismiss = () => {
    resetForm()
    onCancel()
  }

  const modalMode = mode === FormModes.Edit ? HeaderModes.Leaf : HeaderModes.Cancel

  const placeholderText = `Describe anything by\n    -  Typing a description like "2x4x10 DF Dry"\n    -  Adding a picture of your list or the item`

  return (
    <InlineModal
      isOpen={isOpen}
      onDidDismiss={handleDismiss}
      back={handleDismiss}
      label={titleText}
      subLabel={headerSubtitle}
      mode={modalMode}
    >
      <ContentWrapper>
        <Row>
          <DescriptionWrapper
            className={clsx({
              'image-attached': !!values.image?.url,
            })}
          >
            <Field
              name="description"
              component={StyledTextAreaField}
              label="ITEM DESCRIPTION"
              placeholder={!!values.image?.url ? 'Add description (optional)' : placeholderText}
              extraContent={
                <MoreAddOption className="extraContent">
                  <ImageLink onClick={uploadPicture}>
                    <Icon width={25} icon={cameraOutline} />
                    <AddImageLabel>Add Image</AddImageLabel>
                    {/* @ts-ignore https://github.com/react-dropzone/react-dropzone/issues/1052 */}
                    <div {...getRootDropzoneProps()}>
                      <input {...getInputDropzoneProps()} />
                    </div>
                  </ImageLink>
                </MoreAddOption>
              }
            />
            {values.image && (
              <StyledImagePreview
                height={75}
                width={75}
                thumbnailSrc={values.image.thumbnail_url || values.image.url}
                src={values.image.url}
              />
            )}
          </DescriptionWrapper>
        </Row>

        {(values.description || values.image) && (
          <Row>
            <Field
              name="quantity"
              component={TextInputField}
              type="number"
              label="QUANTITY"
              placeholder="Add quantity"
            />
          </Row>
        )}

        {mode === FormModes.Edit && (
          <RemoveButtonRow>
            <RemoveItemButton
              fill="clear"
              size="small"
              onClick={() => {
                if (onDeleteItem && values.id) {
                  onDeleteItem(values.id)
                }
              }}
            >
              Delete Item
            </RemoveItemButton>
          </RemoveButtonRow>
        )}

        {!values.description && <ShortCuts setFieldValue={setFieldValue} />}

        <FooterRow>
          <SubmitButton expand="block" size="large" onClick={submitForm}>
            {submitText}
          </SubmitButton>
        </FooterRow>
      </ContentWrapper>
    </InlineModal>
  )
}

export default withFormik<FormProps, OptionallyIdentifiedTeamMemberListItem>({
  displayName: 'TeamMemberListItemForm',
  enableReinitialize: true,
  validationSchema: GenericItemValidationSchema,
  validateOnMount: true,

  mapPropsToValues({ item }) {
    return item ?? DEFAULT_ORDERER_LIST_ITEM
  },

  handleSubmit(values: OptionallyIdentifiedTeamMemberListItem, { props: { onSubmit }, resetForm, setErrors }) {
    catchAndSetErrors(() => onSubmit(values).then(() => resetForm()), setErrors)
  },
})(TeamMemberListItemModalForm)
