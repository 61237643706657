import React from 'react'
import { generatePath, useLocation } from 'react-router-dom'
import { IonPage } from '@ionic/react'
import { observer } from 'mobx-react-lite'

import styled from 'styled-components'

import Card from '@supplyhound/components/common/Card'
import CreditCardDisplay from '@supplyhound/components/CreditCardDisplay'
import Content from '@supplyhound/components/Content'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'

import { HOME_ROUTE, JOB_SITE_TASK_DETAIL_ROUTE, PAYMENT_ADD_ROUTE } from '@supplyhound/utils/config'
import useStores from '@supplyhound/hooks/useStores'
import { useHistory, useParams } from 'react-router-dom'
import { Header, HeaderModes } from '@supplyhound/layout'

import { JobSiteDetailRouteParams } from '@supplyhound/types'
import Spacer from '@supplyhound/components/common/Spacer'

const StyledSubmitButton = styled(SubmitButton)`
  width: 100%;
`

const PaymentSetupPage: React.FC = () => {
  const history = useHistory()

  const { paymentMethodStore, userProfileStore } = useStores()
  const { paymentMethods } = paymentMethodStore
  const { id: jobSiteId } = useParams<JobSiteDetailRouteParams>()
  const location = useLocation()

  if (!jobSiteId) {
    history.push(HOME_ROUTE)
    return null
  }
  const hasActiveSubscription = !!userProfileStore.subscriptionStore.getSubscriptionJson
  const isCompanyAdministrator = !!userProfileStore.profile?.subscription?.administrator
  const hasSubscriptionAndAdmin = hasActiveSubscription && isCompanyAdministrator

  return (
    <IonPage>
      <Header mode={HeaderModes.Leaf} label="Select card" />
      <Content>
        {paymentMethods.map(payment => (
          <Card key={payment.id}>
            <CreditCardDisplay
              card={payment.card}
              pillText="Select"
              onClick={() => {
                paymentMethodStore.updateSelectedPaymentMethod(payment.id)
                history.push(
                  generatePath(JOB_SITE_TASK_DETAIL_ROUTE, {
                    id: jobSiteId,
                  })
                )
              }}
            />
          </Card>
        ))}
        <Spacer height={20} />
        {(hasSubscriptionAndAdmin || !hasActiveSubscription) && (
          <StyledSubmitButton
            size="large"
            onClick={() => {
              history.push(PAYMENT_ADD_ROUTE, { referrer: location, jobSiteId: jobSiteId })
            }}
          >
            Add new card
          </StyledSubmitButton>
        )}
      </Content>
    </IonPage>
  )
}

export default observer(PaymentSetupPage)
