import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import useStores from '@supplyhound/hooks/useStores'
import { TEAM_MEMBER_LOGIN_ROUTE } from '@supplyhound/utils/config'

const TeamMemberAuthenticated: React.FC = ({ children }) => {
  const location = useLocation()
  const { teamMemberProfileStore, sessionStore } = useStores()

  sessionStore.setIsSessionExpired(teamMemberProfileStore.authStore.isTokenExpired)
  if (teamMemberProfileStore.authStore.isAuthenticated) {
    return <>{children}</>
  }

  return (
    <Redirect
      to={{
        pathname: TEAM_MEMBER_LOGIN_ROUTE,
        search: location.search,
        state: { referrer: location },
      }}
    />
  )
}

export default observer(TeamMemberAuthenticated)
