import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import useStores from '@supplyhound/hooks/useStores'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import Pill from '@supplyhound/components/common/Pill'

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  overflow: scroll;
`

type JobSiteFilterProps = { onChange: (jobSiteId: number | undefined) => void }

const JobSiteFilter: React.FC<JobSiteFilterProps> = ({ onChange }) => {
  const { jobSitesStore } = useStores()

  // update jobsites
  useEffect(() => {
    jobSitesStore.dispatchFetchJobSites()
  }, [])

  const [selectedJobSite, setSelectedJobSite] = useState<number | undefined>(undefined)
  const jobSites = toJS(jobSitesStore.allJobSiteArray)

  return (
    <FilterContainer>
      <Pill
        label="All"
        isSelected={!selectedJobSite}
        onClick={() => {
          onChange(undefined)
          setSelectedJobSite(undefined)
        }}
      />

      {jobSites.map(jobSite => {
        const isSelected = selectedJobSite === jobSite.id
        return (
          <Pill
            key={jobSite.id}
            label={jobSite.name || jobSite.location.street}
            isSelected={isSelected}
            onClick={() => {
              onChange(jobSite.id)
              setSelectedJobSite(jobSite.id)
            }}
          />
        )
      })}
    </FilterContainer>
  )
}

export default observer(JobSiteFilter)
