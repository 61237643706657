import { HistoryTask, TaskType } from '@supplyhound/types'

export const formatTaskType = (type: TaskType): string => {
  switch (type) {
    case 'DeliveryTask':
      return 'Delivery'
    case 'PickupTask':
      return 'Will Call'
    default:
      return ''
  }
}

export const sortTasksByStatus = (tasks: HistoryTask[]) => {
  const activeTasks: HistoryTask[] = []
  const completedTasks: HistoryTask[] = []

  tasks.forEach(task => {
    if (task.task_complete) {
      completedTasks.push(task)
    } else {
      activeTasks.push(task)
    }
  })

  return [activeTasks, completedTasks]
}
