import React from 'react'
import { IonPage } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import Content from '@supplyhound/components/Content'
import SupplierSearchForm, { SupplierSearchFormValues } from '@supplyhound/forms/SupplierSearchForm'
import { Header, HeaderModes } from '@supplyhound/layout'

import { useStores, useLoadingState, useGoogleGeocoder } from '@supplyhound/hooks'
import { generatePath, useHistory } from 'react-router-dom'
import { DELIVERY_ONLY_DETAIL_ROUTE } from '@supplyhound/utils/config'
import { TaskTypes, DeliveryType } from '@supplyhound/types'

const DeliveryOnlySupplierPage: React.FC = () => {
  const { loadWhile } = useLoadingState()
  const history = useHistory()
  const { suppliersStore, jobSitesStore } = useStores()
  const { getByPlaceId } = useGoogleGeocoder()

  const handleSearchFormSubmit = ({ supplier }: SupplierSearchFormValues) => {
    loadWhile(async () => {
      if (!supplier.supplier_id && supplier.place_id) {
        supplier.supplier_id = await suppliersStore.dispatchFetchSupplierIdByPlaceId(supplier.place_id)
      }
      if (supplier.place_id) {
        const geocodeResult = await getByPlaceId(supplier.place_id)
        if (geocodeResult) {
          supplier.address = geocodeResult.formatted_address
        }
      }
      // We need to work with the task store directly because the job site isn't known at this point
      jobSitesStore.taskStore.upsert(jobSitesStore.deliveryOnlyJobSiteId, {
        supplier,
        type: TaskTypes.Delivery,
        delivery_type: DeliveryType.Tomorrow9am,
        ordered_directly: true,
        pickup_address: supplier.address,
      })
    }).then(() => {
      history.push(generatePath(DELIVERY_ONLY_DETAIL_ROUTE))
    })
  }

  return (
    <IonPage>
      <Header mode={HeaderModes.Leaf} label="Delivery only" />
      <Content>
        <SupplierSearchForm onSubmit={handleSearchFormSubmit} label="Pickup from" />
      </Content>
    </IonPage>
  )
}

export default observer(DeliveryOnlySupplierPage)
