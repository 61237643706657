// Import the functions you need from the SDKs you need
import firebase from 'firebase'

export const firebaseConfig = {
  apiKey: 'AIzaSyC-vvXb0ETTrj_Ub6woZHTOxYtLLp7TZ-0',
  authDomain: 'supplyhound-342918.firebaseapp.com',
  projectId: 'supplyhound-342918',
  storageBucket: 'supplyhound-342918.appspot.com',
  messagingSenderId: '1088082929527',
  appId: '1:1088082929527:web:0fb0c54da66cefc5676372',
  measurementId: 'G-D0E7LCQ14R',
}

export const initializeFirebase = () => {
  firebase.initializeApp(firebaseConfig)
}
