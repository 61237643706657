import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import SunkenBorder from '@supplyhound/components/common/SunkenBorder'
import CheckableItemList from '@supplyhound/components/CheckableItemList'
import {
  SelectableTeamMemberListItem,
  OptionallyIdentifiedTeamMemberListItem,
  MaterialListHeader,
  TeamMemberInfo,
  ID,
  AbstractItem,
} from '@supplyhound/types'
import styled from 'styled-components'
import JobSiteDropdown from './JobSiteDropdown'
import { generatePath, useHistory } from 'react-router-dom'
import { TEAM_MEMBER_LIST_ROUTE } from '@supplyhound/utils/config'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import useStores from '@supplyhound/hooks/useStores'
import useModallyEditableItems from '@supplyhound/hooks/useModallyEditableItems'
import TeamMemberListItemModalForm, { FormModes } from '@supplyhound/forms/TeamMemberListItemModalForm'
import { HeightState } from '@supplyhound/hooks/useDynamicHeight'
import TeamMemberHeader from '@supplyhound/components/TeamMemberHeader'

const StyledJobSiteDropdown = styled(JobSiteDropdown)`
  width: 100%;
`

interface MaterialsListProps {
  listId: ID
  items: SelectableTeamMemberListItem[]
  header?: string
  onCheckboxClick?: (item: AbstractItem & { id: ID; selected: boolean }, index: number) => any
  onCheckboxControlClick?: (isCheckboxOn: boolean) => any
  displayOnly?: boolean
  setSelectableItems?: (arg0: SelectableTeamMemberListItem[]) => void
  teamMember?: TeamMemberInfo
  onItemSubmit?: (item: OptionallyIdentifiedTeamMemberListItem) => void
}

const TeamMemberMaterialsList: React.FC<MaterialsListProps> = ({
  listId,
  items,
  header = MaterialListHeader.JobSiteDropdown,
  onCheckboxClick,
  onCheckboxControlClick,
  displayOnly = false,
  setSelectableItems,
  teamMember,
  onItemSubmit,
  ...props
}) => {
  const { loadWhile } = useLoadingState()
  const history = useHistory()
  const [showItems, setShowItems] = useState(true)

  const {
    teamMemberProfileStore: { listStore },
  } = useStores()
  const list = listStore.lists[listId]
  const jobSite = list?.jobSite

  const handleJobSiteSelect = (jobSiteId: ID) => {
    const id = listStore.findListByJobSiteId(jobSiteId)?.id
    if (id !== listId) {
      loadWhile(async () => history.push(generatePath(TEAM_MEMBER_LIST_ROUTE, { id })))
    }
  }

  const { handleEditItem, handleEditItemSubmit, handleEditItemCancel, itemInEdit, showModal } =
    useModallyEditableItems<OptionallyIdentifiedTeamMemberListItem>({
      items,
      onSubmit: item =>
        loadWhile(async () => {
          if (onItemSubmit) {
            return onItemSubmit(item)
          }
          return list.dispatchUpdateItem(item)
        }),
    })

  const [editedItem, setEditedItem] = useState<SelectableTeamMemberListItem>()

  const handleEditItemDelete = (teamMemberListItemId: ID) => {
    loadWhile(async () => {
      await list.dispatchDeleteItem(teamMemberListItemId)
    }).then(() => {
      handleEditItemCancel()
    })
  }

  useEffect(() => {
    // The 'teamMember' prop is only passed when using this component in the orderer app.
    // This dispatchFetchLists is only for when this is used in the team member app.
    if (!teamMember) {
      loadWhile(listStore.dispatchFetchLists)
    }
  }, [])

  return (
    <>
      <SunkenBorder {...props}>
        {header === MaterialListHeader.JobSiteDropdown && (
          <StyledJobSiteDropdown
            selectedJobSite={jobSite}
            jobSites={listStore.jobSitesArray}
            onJobSiteSelect={handleJobSiteSelect}
            disableAddJobSite
            onDropdownStateChange={(state: HeightState) => {
              if (state === 'Expanding' || state === 'Expanded') {
                setShowItems(false)
              } else {
                setShowItems(true)
              }
            }}
          />
        )}
        {header === MaterialListHeader.TeamMember && <TeamMemberHeader teamMember={teamMember} />}
        {showItems && (
          <CheckableItemList<SelectableTeamMemberListItem>
            items={items}
            onItemClick={handleEditItem}
            displayOnly={displayOnly}
            editedItem={editedItem}
            onCheckboxClick={onCheckboxClick}
            onCheckboxControlClick={onCheckboxControlClick}
          />
        )}
      </SunkenBorder>
      <TeamMemberListItemModalForm
        isOpen={showModal}
        item={itemInEdit}
        onSubmit={updatedItem => {
          return handleEditItemSubmit(updatedItem).then(() => {
            setEditedItem(updatedItem as SelectableTeamMemberListItem)
            if (setSelectableItems) {
              setSelectableItems(
                items.map(item => (item.id === updatedItem.id ? (updatedItem as SelectableTeamMemberListItem) : item))
              )
            }
          })
        }}
        onCancel={handleEditItemCancel}
        mode={FormModes.Edit}
        headerSubtitle={jobSite?.name}
        onDeleteItem={handleEditItemDelete}
      />
    </>
  )
}

export default observer(TeamMemberMaterialsList)
