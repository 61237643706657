import React from 'react'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { HOME_ROUTE } from '@supplyhound/utils/config'
import useStores from '@supplyhound/hooks/useStores'

const OrdererUnauthenticatedOnly: React.FC = ({ children }) => {
  const { userProfileStore } = useStores()

  if (userProfileStore.authStore.isAuthenticated) {
    return <Redirect to={HOME_ROUTE} />
  }

  return <>{children}</>
}

export default observer(OrdererUnauthenticatedOnly)
