import React, { useEffect, useState } from 'react'
import { IonPage, useIonModal } from '@ionic/react'
import { Header, HeaderModes } from '@supplyhound/layout'

import Content from '@supplyhound/components/Content'
import { observer } from 'mobx-react-lite'
import { MANAGE_JOB_SITES_DETAIL_ROUTE, MANAGE_JOB_SITES_SELECT_ROUTE } from '@supplyhound/utils/config'
import JobSiteList from '@supplyhound/components/JobSiteList'
import Pill from '@supplyhound/components/common/Pill'
import styled from 'styled-components'
import { JobSiteStatus, JobSiteStatuses } from '@supplyhound/types'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import useStores from '@supplyhound/hooks/useStores'
import Card from '@supplyhound/components/common/Card'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import { AddEditJobSiteModal } from '@supplyhound/modals'
import { useHistory } from 'react-router-dom'
import Icon from '@supplyhound/components/common/Icon'
import { locationOutline } from 'ionicons/icons'
import Spacer from '@supplyhound/components/common/Spacer'

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  overflow: scroll;
  margin-bottom: calc(2 * var(--space-unit));
`

const StyledEmptyCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  height: 370px;
  padding: 20px;
`

const StyledTextContainer = styled.div`
  flex: 50%;
  text-align: center;
`

const ManageJobSitesPage: React.FC = () => {
  const history = useHistory()
  const [filterState, setFilterState] = useState<JobSiteStatus>(JobSiteStatuses.current)

  const { loadWhile } = useLoadingState()
  useEffect(() => {
    loadWhile(jobSitesStore.dispatchFetchJobSites)
  }, [])

  const { jobSitesStore } = useStores()
  const allJobSites = jobSitesStore.allJobSiteArray
  const activeJobSites = jobSitesStore.jobSiteArray
  const archivedCount = allJobSites.length - activeJobSites.length
  const isSetCurrent = filterState === JobSiteStatuses.current
  const isSetArchived = filterState === JobSiteStatuses.archived

  const [present, dismiss] = useIonModal(AddEditJobSiteModal, {
    dismiss: () => dismiss(),
    onSubmit: () => history.push(MANAGE_JOB_SITES_SELECT_ROUTE),
  })

  return (
    <IonPage>
      <Header mode={HeaderModes.Root} label="Manage Jobsites" />
      <Content>
        <FilterContainer>
          <Pill
            label="Current"
            isSelected={filterState === JobSiteStatuses.current}
            onClick={() => {
              setFilterState(JobSiteStatuses.current)
            }}
          />
          <Pill
            label="Archived"
            isSelected={filterState === JobSiteStatuses.archived}
            onClick={() => {
              setFilterState(JobSiteStatuses.archived)
            }}
          />
        </FilterContainer>
        {(isSetCurrent && activeJobSites.length) || (isSetArchived && archivedCount) ? (
          <JobSiteList
            jobSiteClickRoute={MANAGE_JOB_SITES_DETAIL_ROUTE}
            jobSiteAfterCreateRoute={MANAGE_JOB_SITES_SELECT_ROUTE}
            statusFilter={filterState}
            showAddRow={isSetCurrent}
          />
        ) : (
          <>
            <StyledEmptyCard>
              <StyledTextContainer>
                <Spacer height={60} />
                <Icon icon={locationOutline} width={50} />
                <p>
                  All your {filterState === JobSiteStatuses.current ? 'current' : 'archived'} jobsites will appear here.
                </p>
              </StyledTextContainer>
              <SubmitButton onClick={() => present()}>Add jobsite</SubmitButton>
            </StyledEmptyCard>
          </>
        )}
      </Content>
    </IonPage>
  )
}

export default observer(ManageJobSitesPage)
