import React from 'react'
import { IonContent, IonPage } from '@ionic/react'

import { Redirect } from 'react-router-dom'

import { Header, HeaderModes } from '@supplyhound/layout'

import { observer } from 'mobx-react-lite'
import TabsLayout from '@supplyhound/components/TabsLayout'
import { useStores } from '@supplyhound/hooks'

import EmployeeDirectory from './EmployeeDirectory'
import EmployeeInvite from './EmployeeInvite'
import { HOME_ROUTE } from '@supplyhound/utils/config'

const tabs = [
  {
    id: 'invite',
    label: 'Invite',
    View: EmployeeInvite,
  },
  {
    id: 'employee',
    label: 'Employees',
    View: EmployeeDirectory,
  },
]

const CompanyPage = () => {
  const { userProfileStore } = useStores()

  if (!userProfileStore.profile) {
    return null
  }

  if (!userProfileStore.profile.subscription) {
    return <Redirect to={HOME_ROUTE} />
  }

  const tabsToRender = userProfileStore.profile.subscription!.administrator
    ? tabs
    : tabs.filter(tab => tab.id === 'employee')

  return (
    <IonPage>
      <Header mode={HeaderModes.Root} label="Company" />
      <IonContent>
        <TabsLayout tabs={tabsToRender} />
      </IonContent>
    </IonPage>
  )
}

export default observer(CompanyPage)
