import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import useStores from '@supplyhound/hooks/useStores'
import TeamMemberMaterialsList from '@supplyhound/components/TeamMemberMaterialsList'

const SubmittedItemsTab: React.FC<{
  setPageSubHeading: (arg0: string) => void
}> = ({ setPageSubHeading }) => {
  const {
    teamMemberProfileStore: { listStore },
  } = useStores()

  const { id } = useParams<{ id: string }>()
  const listId = parseInt(id)
  const list = listStore.lists[listId]

  useEffect(() => {
    setPageSubHeading('Review submitted items')
  }, [])

  return (
    <TeamMemberMaterialsList
      listId={list?.id}
      items={list?.submitedItems.map(item => ({ ...item, selected: false }))}
      displayOnly
    />
  )
}

export default observer(SubmittedItemsTab)
