import React from 'react'
import { IonPage } from '@ionic/react'
import { Header, HeaderModes } from '@supplyhound/layout'

import Content from '@supplyhound/components/Content'
import TabsLayout from '@supplyhound/components/TabsLayout'
import { observer } from 'mobx-react-lite'
import AccountProfileTab from 'pages/Account/AccountProfileTab'
import AccountPaymentTab from 'pages/Account/AccountPaymentTab'

const AccountPage: React.FC = () => {
  const tabs = [
    {
      id: 'payment',
      label: 'Payment',
      View: AccountPaymentTab,
    },
    {
      id: 'profile',
      label: 'Profile',
      View: AccountProfileTab,
    },
  ]

  return (
    <IonPage>
      <Header mode={HeaderModes.Root} label="Account" />
      <Content>
        <TabsLayout tabs={tabs} />
      </Content>
    </IonPage>
  )
}

export default observer(AccountPage)
