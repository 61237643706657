import { DeliveryBy, VehicleType } from '@supplyhound/types'
import * as Yup from 'yup'

export const DELIVERY_DETAILS_VALIDATION_SCHEMA = Yup.object()
  .shape({
    job_site_id: Yup.number().required('Please select a job site').min(0, 'Please select a job site'),
    location: Yup.object().shape({
      full_address: Yup.string().required('Enter or select an option for where to deliver materials '),
    }),
    vehicle_type: Yup.mixed()
      .required()
      .oneOf([VehicleType.Car, VehicleType.Truck, DeliveryBy.Supplier, VehicleType.RackTruck, VehicleType.SprinterVan]),
    delivery_type: Yup.string().required('Delivery by time required'),
    pickup_datetime: Yup.date().required('Required').min(new Date(), 'Should be later than now'),
    delivery_address: Yup.string().required('Required'),
    delivery_datetime: Yup.date()
      .required('Delivery by date required')
      .min(new Date(), 'Select a future time for delivery'),
    delivery_note: Yup.string(),
    taskItems: Yup.array()
      .of(
        Yup.object().shape({
          selected: Yup.boolean(),
        })
      )
      .compact(item => {
        if (!item) {
          return true
        }
        return !item.selected
      })
      .min(1, 'Select at least one item to order'),
  })
  .required()

export const DELIVERY_ONLY_DETAILS_VALIDATION_SCHEMA = Yup.object()
  .shape({
    job_site_id: Yup.number().required('Please select a job site').min(0, 'Please select a job site'),
    location: Yup.object().shape({
      full_address: Yup.string().required('Enter or select an option for where to deliver materials '),
    }),
    vehicle_type: Yup.mixed()
      .required()
      .oneOf([VehicleType.Car, VehicleType.Truck, DeliveryBy.Supplier, VehicleType.RackTruck, VehicleType.SprinterVan]),
    order_no: Yup.string().required('Required'),
    delivery_type: Yup.string().required('Delivery type required'),
    pickup_datetime: Yup.date().required('Required').min(new Date(), 'Should be later than now'),
    delivery_address: Yup.string().required('Required'),
    delivery_datetime: Yup.date()
      .required('Delivery by time required')
      .min(new Date(), 'Select a future time for delivery'),
    delivery_note: Yup.string(),
  })
  .required()

export const PICKUP_DETAILS_VALIDATION_SCHEMA = Yup.object()
  .shape({
    pickup_datetime: Yup.date().required('Select a time for pickup').min(new Date(), 'Should be later than now'),
    pickup_type: Yup.string().required('Select a time for pickup'),
    pickup_address: Yup.string().required('Required'),
    pickup_note: Yup.string(),
    taskItems: Yup.array()
      .of(
        Yup.object().shape({
          selected: Yup.boolean(),
        })
      )
      .compact(item => {
        if (!item) {
          return true
        }
        return !item.selected
      })
      .min(1, 'Select at least one item to order'),
  })
  .required()
