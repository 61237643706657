import { axiosInstance } from './axios'

export const fetchItemHistory = (jobsiteId: number | undefined) =>
  axiosInstance.get(jobsiteId ? `/v1/history/jobsite/${jobsiteId}/items` : `/v1/history/items`).then(({ data }) => data)

export const fetchOrderHistory = (jobsiteId?: number) =>
  axiosInstance.get(jobsiteId ? `/v1/history/jobsite/${jobsiteId}/orders` : `/v1/history/orders`).then(({ data }) => {
    const tasks = data.tasks.map((task: any) => {
      const billing = task.billing_details
      return {
        ...task,
        supplier: {
          name: task.pickup_address.split(', ')[0],
          address: task.pickup_address,
        },
        billingDetails: {
          subtotal: Number(billing.subtotal),
          extraFee: Number(billing.extra_fee),
          handlingFee: Number(billing.handling_fee),
          discount: Number(billing.discount),
          total: Number(billing.total),
          subtotalWithFees: Number(billing.sub_total_with_fees),
        },
      }
    })
    return { tasks }
  })
