import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { IonPage, useIonModal } from '@ionic/react'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { parse } from 'query-string'
import Spacer from '@supplyhound/components/common/Spacer'
import {
  JOB_SITE_LIST_SELECT_ROUTE,
  DELIVERY_ONLY_ADD_SUPPLIER_ROUTE,
  TEAM_MEMBERS_ROUTE,
  HISTORY_ADD_ROUTE,
} from '@supplyhound/utils/config'
import Content from '@supplyhound/components/Content'
import { Header, HeaderModes } from '@supplyhound/layout'

import { LoginModal, LoginFlowStateType } from '@supplyhound/modals'
import useStores from '@supplyhound/hooks/useStores'
import { menuController } from '@ionic/core/components'
import { LogoLarge, LogoLargeDarkMode } from '@supplyhound/images'
import Icon from '@supplyhound/components/common/Icon'
import { readerOutline, receiptOutline } from 'ionicons/icons'
import OrdererListItemModalForm, { FormModes } from '@supplyhound/forms/OrdererListItemModalForm'
import Caption from '@supplyhound/components/common/text/Caption'
import { modalController } from '@ionic/core'

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--greyscale-6);
  align-items: center;
`

const Centered = styled.div`
  display: flex;
  justify-content: center;
`

const LogoImage = styled.img`
  width: 300px;
  margin-top: var(--space-unit);
  content: url(${LogoLarge});

  ion-app.dark & {
    content: url(${LogoLargeDarkMode});
  }
`

const BaseSection = styled.div`
  border-radius: 8px;
  padding: 13px;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 10px;
  cursor: pointer;
`
const HalfSection = styled(BaseSection)`
  border: none;
  background-color: var(--ion-color-primary);
  align-items: center;
  max-width: 200px;
  color: var(--perma-dark-color);
  text-align: center;
`

const FullSection = styled(BaseSection)`
  border: 1px solid var(--ion-border-color);
  background-color: var(--greyscale-1);
  align-items: start;
  margin: 10px;
  max-width: 415px;
`

const StyledCaption = styled(Caption)`
  color: var(--greyscale-7);
  ion-app.dark & {
    color: var(--greyscale-5);
  }
`

const Divider = styled.div`
  width: 25%;
  border-top: 1px solid var(--greyscale-4);
  margin: 40px 0px;
`
const Bottom = styled(Centered)`
  @media (min-height: 740px) {
    position: absolute;
    bottom: 20px;
  }
  right: 20px;
  left: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const HomePage: React.FC = () => {
  const history = useHistory()
  const { search } = useLocation()
  const { userProfileStore } = useStores()
  const { showModal, email, token: changePasswordToken } = parse(search)

  const [showFullItemForm, setShowFullItemForm] = useState(false)

  const [loginFlowState, setLoginFlowState] = useState<LoginFlowStateType>(
    showModal === 'true' ? 'enter_password' : 'login'
  )

  const [present, dismiss] = useIonModal(LoginModal, {
    dismiss: () => dismiss(),
    loginFlowState,
    setLoginFlowState,
    email,
    token: changePasswordToken,
  })

  useEffect(() => {
    menuController.enable(userProfileStore.authStore.isAuthenticated, 'menu')
    if (!userProfileStore.authStore.isAuthenticated) {
      present({
        backdropDismiss: false,
        cssClass: ['login-modal'],
      })
    }
  }, [userProfileStore.authStore.isAuthenticated, showModal, loginFlowState])

  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const subscriptionType = _.capitalize(userProfileStore.profile?.subscription?.subscription_type || '')
  return (
    <IonPage>
      <Header
        mode={showFullItemForm ? HeaderModes.Leaf : HeaderModes.Root}
        onBackButtonClick={() => setShowFullItemForm(false)}
        label="Home"
      />
      <Content>
        <LogoContainer>
          <LogoImage alt="logo" />
          {subscriptionType && subscriptionType !== 'Legacy'
            ? `${subscriptionType} Member`
            : 'One tool for all your materials'}
        </LogoContainer>
        <Spacer height={30} />
        <Centered>
          <HalfSection onClick={() => setModalOpen(true)}>
            <Icon icon={readerOutline} width={30} />
            <h3>Build list</h3>
            <StyledCaption>Add an item</StyledCaption>
          </HalfSection>
          <HalfSection onClick={() => history.push(JOB_SITE_LIST_SELECT_ROUTE)}>
            <Icon icon={receiptOutline} width={30} />
            <h3>Place order</h3>
            <StyledCaption>From any supplier</StyledCaption>
          </HalfSection>
        </Centered>
        <Centered>
          <FullSection onClick={() => history.push(TEAM_MEMBERS_ROUTE)}>
            <h3>Manage your team</h3>
            <StyledCaption>Get material requests from team members and communicate in real time.</StyledCaption>
          </FullSection>
        </Centered>
        <Bottom>
          <Divider />
          <FullSection onClick={() => history.push(DELIVERY_ONLY_ADD_SUPPLIER_ROUTE)}>
            <h3>Move materials.</h3>
            <StyledCaption>No order? Use delivery only.</StyledCaption>
          </FullSection>
        </Bottom>
        <OrdererListItemModalForm
          item={undefined}
          isOpen={modalOpen}
          onSubmit={async item => {
            setModalOpen(false)
            history.push({
              pathname: JOB_SITE_LIST_SELECT_ROUTE,
              state: { item },
            })
          }}
          onAddFromHistoryClick={async () => {
            await modalController.dismiss()
            history.push(generatePath(HISTORY_ADD_ROUTE))
          }}
          onCancel={() => {
            setModalOpen(false)
          }}
          mode={FormModes.Add}
        />
      </Content>
    </IonPage>
  )
}

export default observer(HomePage)
