import React, { ComponentProps, useState } from 'react'
import { IonToggle } from '@ionic/react'
import styled from 'styled-components'
import { FieldProps } from 'formik'

interface Props extends ComponentProps<typeof IonToggle> {
  onToggle: (arg0: boolean) => void
}

const StyledIonToggle = styled(IonToggle)`
  border: 1px solid var(--ion-color-dark);
  --background: var(--ion-color-warning);
  --background-checked: var(--ion-color-success);
  --handle-background: var(--ion-color-dark);
  --handle-background-checked: var(--ion-color-dark);
`

const ToggleButton: React.FC<FieldProps & Props> = ({ field: { name }, form, checked, onToggle, ...props }) => {
  const [isChecked, setIsChecked] = useState(checked)
  return (
    <StyledIonToggle
      name={name}
      checked={isChecked}
      onIonChange={e => {
        form.setFieldValue(name, e.detail.checked)
        setIsChecked(!isChecked)
        onToggle(!isChecked)
      }}
      {...props}
    />
  )
}

export default ToggleButton
