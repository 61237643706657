import React, { useEffect, useState } from 'react'
import { IonPage, useIonModal } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import { Header, HeaderModes } from '@supplyhound/layout'

import Content from '@supplyhound/components/Content'
import TeamMemberAddEditForm from '@supplyhound/forms/TeamMemberAddEditForm'
import { TeamMember, Modes, OptionallyIdentifiedTeamMember, PageMode } from '@supplyhound/types'
import useStores from '@supplyhound/hooks/useStores'
import useSerializeApiError from '@supplyhound/hooks/useSerializeApiError'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import { TEAM_MEMBERS_ROUTE, TEAM_MEMBER_ADD_ROUTE } from '@supplyhound/utils/config'
import { useHistory, useLocation } from 'react-router-dom'
import ActionModal from '@supplyhound/components/common/Modals/ActionModal'
import { trashOutline } from 'ionicons/icons'

const TeamMemberAddEditPage: React.FC = () => {
  const history = useHistory()
  const location = useLocation()

  const {
    teamStore,
    userProfileStore: { profile },
  } = useStores()

  const { loadWhile } = useLoadingState()
  const [, , serializeApiError] = useSerializeApiError()

  const { members } = teamStore
  const id = location.pathname.split('/').pop()

  const team_id = profile?.team.id ?? -1

  const mode: PageMode = location.pathname === TEAM_MEMBER_ADD_ROUTE ? Modes.Add : Modes.Edit

  const member: TeamMember | undefined = members.find(member => member.id.toString() === id)

  const initialToggle = mode === Modes.Add ? true : member?.should_receive_daily_reminder

  const [reminderOn, setReminderOn] = useState(initialToggle)

  useEffect(() => {
    if (!profile) return
    loadWhile(() => {
      teamStore.setId(profile.team.id)

      return serializeApiError(() => teamStore.dispatchLoadMembers())
    })
  }, [profile])

  const handleSubmit = async (teamMember: TeamMember | OptionallyIdentifiedTeamMember) => {
    if (teamMember.id) {
      await teamStore.dispatchUpdateMember(teamMember as TeamMember)
    } else {
      await teamStore.dispatchCreateMember(teamMember as OptionallyIdentifiedTeamMember)
    }
    history.push(TEAM_MEMBERS_ROUTE)
  }

  const handleRemove = async (teamMember?: TeamMember) => {
    if (teamMember?.id) {
      await teamStore.dispatchDeleteMember(teamMember)
    }
    history.push(TEAM_MEMBERS_ROUTE)
  }

  const [presentRemoveModal, dismissRemoveModal] = useIonModal(ActionModal, {
    icon: trashOutline,
    title: `Delete ${member?.first_name}?`,
    subtext: 'All outstanding requests from this team member will also be deleted.',
    submitText: 'Delete team member',
    onDismiss: () => dismissRemoveModal(),
    onSubmit: () => {
      handleRemove(member)
      dismissRemoveModal()
    },
  })

  return (
    <IonPage>
      <Header label={`${mode} team member`} mode={HeaderModes.Leaf} />
      <Content>
        <TeamMemberAddEditForm
          onSubmit={handleSubmit}
          mode={mode}
          reminderOn={reminderOn!}
          setReminderOn={() => setReminderOn(!reminderOn)}
          teamMember={member}
          handleRemoveClick={presentRemoveModal}
          team_id={team_id}
        />
      </Content>
    </IonPage>
  )
}

export default observer(TeamMemberAddEditPage)
