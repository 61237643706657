import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { IonIcon } from '@ionic/react'
import { checkmarkSharp } from 'ionicons/icons'
import { formatCurrency } from '@supplyhound/utils/formatters'
import Card from '@supplyhound/components/common/Card'
import Spacer from '@supplyhound/components/common/Spacer'
import { BillingPlanType, SubscriptionType, BulletPointsType } from '../types'
import { BILLING_CADENCE } from '../config'
import BaseButton from '@supplyhound/components/buttons/BaseButton'

const StyledCard = styled(Card)<{ $currentPlan?: boolean }>`
  width: 325px;
  padding: 20px;
  border-radius: var(--ion-border-radius);
  display: flex;
  flex-direction: column;
  opacity: ${props => (props.$currentPlan ? '0.5' : '1')};
`

const Header = styled.div`
  text-align: center;
`

const HeadingPrice = styled.h3`
  &&& {
    font-size: 32px;
  }
`

const Caption = styled.span`
  color: var(--greyscale-6);
  font-size: 16px;
`

const BulletPointList = styled.ul`
  list-style: none;
  padding: 0;
`

const BulletPoint = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  &.bold {
    font-weight: 800;
  }
`

const Icon = styled(IonIcon)`
  color: var(--greyscale-4);
  font-size: 24px;
`

const StyledHR = styled.hr`
  width: 40px;
  background-color: var(--ion-border-color);
`

const ButtonContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: end;
`

const SubscriptionCard = ({
  billingPlanType,
  planName,
  price,
  bulletPoints,
  onChoose,
  currentPlan = false,
  chooseButtonText,
  chooseButtonColor,
}: {
  billingPlanType: BillingPlanType
  planName: SubscriptionType
  price: {
    monthly: number
    annually: number
  }
  bulletPoints: BulletPointsType[]
  onChoose: (planName: SubscriptionType) => void
  currentPlan?: boolean
  chooseButtonText?: string
  chooseButtonColor?: string
}) => {
  let buttonText = currentPlan ? 'Current Plan' : `Choose ${_.capitalize(planName)}`

  if (chooseButtonText) {
    buttonText = chooseButtonText
  }
  return (
    <StyledCard className="subscription-card" $currentPlan={currentPlan}>
      <Header>
        <h2>{_.capitalize(planName)}</h2>

        <StyledHR />
        <Spacer height={15} />
        <HeadingPrice>{formatCurrency(price[billingPlanType], { noDecimal: true })}</HeadingPrice>
        <Caption>per month, billed {BILLING_CADENCE[billingPlanType]}</Caption>
      </Header>
      <BulletPointList>
        {bulletPoints.map((bulletPoint, idx) => {
          return (
            <BulletPoint key={idx} className={bulletPoint.bold ? 'bold' : ''}>
              <Icon src={checkmarkSharp} />
              <Spacer width={5} />
              {bulletPoint.description}
            </BulletPoint>
          )
        })}
      </BulletPointList>
      <Spacer height={20} />
      <ButtonContainer>
        <BaseButton onClick={() => onChoose(planName)} color={chooseButtonColor} disabled={currentPlan}>
          {buttonText}
        </BaseButton>
      </ButtonContainer>
    </StyledCard>
  )
}

export default SubscriptionCard
