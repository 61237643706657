import { useState } from 'react'
import { ApiErrorSerializer, serializeApiError as defaultSerializer } from '@supplyhound/api'

type SerializeError = (fn: () => Promise<any>) => Promise<any>
type SetError = (error: string) => void
type UseSerializeApiError = (serializer?: ApiErrorSerializer) => [string, SetError, SerializeError]

export const useSerializeApiError: UseSerializeApiError = (serializer = defaultSerializer) => {
  const [error, setError] = useState<string>('')

  const serializeError: SerializeError = fn => {
    return fn().catch(e => {
      // If it doesn't have a response property then
      // it's not an api error; don't try to serialize it.
      if (e.response) {
        setError(serializer(e))
      }

      // Always throw it.
      throw e
    })
  }

  return [error, setError, serializeError]
}

export default useSerializeApiError
