import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { IonPage } from '@ionic/react'

import { Header } from '@supplyhound/layout'
import styled from 'styled-components'
import Content from '@supplyhound/components/Content'
import ToggleableTabs, { ToggleableTab } from '@supplyhound/components/ToggleableTabs'
import Spacer from '@supplyhound/components/common/Spacer'
import RequestedItemsTab from 'pages/TeamMemberListPage/RequestedItemsTab'
import SubmittedItemsTab from 'pages/TeamMemberListPage/SubmittedItemsTab'
import NotificationBanner from '@supplyhound/components/NotificationBanner'

const StyledContent = styled(Content)`
  ::part(scroll) {
    padding-bottom: 100px;
  }
`

export const Tab = {
  RequestedItems: 'Requested Items',
  SubmittedItems: 'Submitted',
}

const TeamMemberListPage: React.FC = () => {
  const [activeTabName, setActiveTabName] = useState(Tab.RequestedItems)
  const tabs: ToggleableTab[] = [
    { name: Tab.RequestedItems, label: Tab.RequestedItems },
    { name: Tab.SubmittedItems, label: Tab.SubmittedItems },
  ]
  const [displayBanner, setDisplayBanner] = useState(false)
  const [pageSubHeading, setPageSubHeading] = useState('')

  return (
    <IonPage>
      <Header label="Materials list" subLabel={pageSubHeading} />
      {displayBanner && (
        <NotificationBanner
          onDidDismiss={() => setDisplayBanner(false)}
          message="Your items have been successfully submitted."
        />
      )}
      <StyledContent>
        <ToggleableTabs
          activeTabName={activeTabName}
          tabs={tabs}
          onToggle={name => {
            setActiveTabName(name)
          }}
        />
        <Spacer height={15} />
        {activeTabName === Tab.RequestedItems && (
          <RequestedItemsTab
            setPageSubHeading={setPageSubHeading}
            onSubmitItemsHandler={() => {
              setActiveTabName(Tab.SubmittedItems)
              setDisplayBanner(true)
            }}
          />
        )}
        {activeTabName === Tab.SubmittedItems && <SubmittedItemsTab setPageSubHeading={setPageSubHeading} />}
      </StyledContent>
    </IonPage>
  )
}

export default observer(TeamMemberListPage)
