import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { IonPage } from '@ionic/react'
import { Header, HeaderModes } from '@supplyhound/layout'

import Content from '@supplyhound/components/Content'
import TeamMemberLoginForm, { TeamMemberLoginFormValues } from '@supplyhound/forms/TeamMemberLoginForm'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import useStores from '@supplyhound/hooks/useStores'
import useQuery from '@supplyhound/hooks/useQuery'
import { TeamMemberAuthQuery } from '@supplyhound/types'

const TeamMemberLoginPage: React.FC = () => {
  const { loadWhile } = useLoadingState()
  const { teamMemberProfileStore } = useStores()
  const {
    query: { phone },
  } = useQuery<TeamMemberAuthQuery>()

  const handleSubmitTeamMemberLogin = ({ phone }: TeamMemberLoginFormValues) => {
    return loadWhile(() => teamMemberProfileStore.dispatchLoginTeamMember(phone)).catch(e => {
      throw e
    })
  }

  // If we land with a phone query try to login.
  useEffect(() => {
    if (phone) {
      handleSubmitTeamMemberLogin({ phone })
    }
  }, [])

  return (
    <IonPage>
      <Header label="Team tool" mode={HeaderModes.Root} />
      <Content>
        <TeamMemberLoginForm phone={phone} onSubmit={handleSubmitTeamMemberLogin} />
      </Content>
    </IonPage>
  )
}

export default observer(TeamMemberLoginPage)
