import React from 'react'
import { useHistory } from 'react-router-dom'
import { IonPage } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import useStores from '@supplyhound/hooks/useStores'
import SignUpForm from '@supplyhound/forms/SignUpForm'
import Content from '@supplyhound/components/Content'
import { Header, HeaderModes } from '@supplyhound/layout'

import { signUp } from '@supplyhound/api'
import { User } from '@supplyhound/types'
import { HOME_ROUTE } from '@supplyhound/utils/config'
import useQuery from '@supplyhound/hooks/useQuery'

const SignUpPage: React.FC = () => {
  const history = useHistory()
  const { userProfileStore } = useStores()

  const { query } = useQuery<{ email?: string; invite_token?: string; company_name?: string }>()
  const params = { email: query.email, invite_token: query.invite_token, company_name: query.company_name }

  const handleSubmit = async (user: User) => {
    const payload = { ...user }
    if (params.invite_token) {
      payload.invite_token = params.invite_token
    }
    const { data } = await signUp(payload)

    // Set token and load profile
    await userProfileStore.authStore.setToken(data.jwt)
    await userProfileStore.dispatchLoadProfile()

    history.push({ pathname: HOME_ROUTE })
  }

  return (
    <IonPage>
      <Header label="Create Account" mode={HeaderModes.Leaf} />
      <Content>
        <SignUpForm
          marketOptions={userProfileStore.marketOptions}
          onSubmit={handleSubmit}
          email={params.email}
          company_name={params.company_name}
        />
      </Content>
    </IonPage>
  )
}

export default observer(SignUpPage)
