import React from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import IconPopover from '@supplyhound/components/IconPopover'
import { helpCircle, helpCircleOutline } from 'ionicons/icons'
import mixpanel from 'mixpanel-browser'
import useStores from '@supplyhound/hooks/useStores'

const VehicleTypeInfoText = styled.div`
  font-size: 0.8rem;
  font-style: italic;
  font-weight: 600;
`

const VehicleHelpText = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledIconPopover = styled(IconPopover)`
  cursor: pointer;
`

const VehicleTypeInfo: React.FC = () => {
  const { userProfileStore } = useStores()
  const userId = userProfileStore.profile?.id
  return (
    <StyledIconPopover
      icon={helpCircleOutline}
      clickedIcon={helpCircle}
      content={
        <VehicleTypeInfoText>
          <VehicleHelpText>
            <span>
              <u>Truck</u>: Max payload 1800 lbs.
            </span>
            <span>
              <u>Car</u>: Items less than 50 lbs and max 4 ft long.
            </span>
          </VehicleHelpText>
        </VehicleTypeInfoText>
      }
      id="vehicleHelp"
      trackIconClicked={() => {
        if (userId) {
          mixpanel.identify(userId.toString())
          mixpanel.track('Vehicle Type Info Clicked', {
            $distinct_id: userProfileStore.profile?.id,
          })
        }
      }}
    />
  )
}

export default observer(VehicleTypeInfo)
