import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useIonModal } from '@ionic/react'
import OvalButton from '@supplyhound/components/buttons/OvalButton'
import SubscriptionModal from 'components/modals/SubscriptionModal'
import Card from '@supplyhound/components/common/Card'

const SubscriptionCard = styled(Card)`
  padding: calc(3 * var(--space-unit)) calc(2 * var(--space-unit));
`

const SubscriptionGettingStarted = styled(OvalButton)`
  height: 35px;
`

const SubscriptionGetStarted = () => {
  const history = useHistory()
  const [presentSubscriptionModal, dismissSubscriptionModal] = useIonModal(SubscriptionModal, {
    dismiss: () => dismissSubscriptionModal(),
    type: 'create',
    history,
  })

  return (
    <SubscriptionCard>
      <p>Supercharge your workflows with company accounts</p>
      <ul>
        <li>
          <h4>Save money</h4>
        </li>
        <li>
          <h4>More projects</h4>
        </li>
        <li>
          <h4>Bigger teams</h4>
        </li>
      </ul>
      <SubscriptionGettingStarted
        color="tertiary"
        onClick={() => presentSubscriptionModal({ cssClass: 'subscription' })}
      >
        Get started
      </SubscriptionGettingStarted>
    </SubscriptionCard>
  )
}

export default SubscriptionGetStarted
