import React from 'react'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import useStores from '@supplyhound/hooks/useStores'
import { TEAM_MEMBER_HOME_ROUTE } from '@supplyhound/utils/config'

const TeamMemberUnauthenticatedOnly: React.FC = ({ children }) => {
  const { teamMemberProfileStore } = useStores()

  if (teamMemberProfileStore.authStore.isAuthenticated) {
    return <Redirect to={TEAM_MEMBER_HOME_ROUTE} />
  }

  return <>{children}</>
}

export default observer(TeamMemberUnauthenticatedOnly)
