import React, { useEffect } from 'react'
import { IonPage, IonRow } from '@ionic/react'
import Icon from '@supplyhound/components/common/Icon'
import { generatePath, useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import _ from 'lodash'
import { Header, HeaderModes } from '@supplyhound/layout'

import Content from '@supplyhound/components/Content'
import BaseButton from '@supplyhound/components/buttons/BaseButton'
import { Team } from '@supplyhound/icons'
import { chevronForwardOutline, timeOutline } from 'ionicons/icons'
import Card from '@supplyhound/components/common/Card'
import TeamMemberRow from '@supplyhound/components/common/TeamMemberRow'
import HorizontalRule from '@supplyhound/components/common/HorizontalRule'
import useStores from '@supplyhound/hooks/useStores'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import useSerializeApiError from '@supplyhound/hooks/useSerializeApiError'
import { TEAM_MEMBER_ADD_ROUTE, TEAM_MEMBER_EDIT_ROUTE, TEAM_MEMBER_SCHEDULE_ROUTE } from '@supplyhound/utils/config'
import { TeamMember } from '@supplyhound/types'
import { getReminderDaysLabel, formatTime } from '@supplyhound/utils/teamReminder'

const StyledButton = styled(BaseButton)`
  margin-bottom: 30px;
`

const TeamIcon = styled(Icon)`
  font-size: 3.125rem;
`

const Text = styled.p`
  font-weight: 400;
  font-size: 1.125rem;
  text-align: center;
  line-height: 1.625rem;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50%;
`

const ScheduleCard = styled(Card)<{ paused: boolean }>`
  margin-top: calc(2 * var(--space-unit));
  margin-bottom: calc(3 * var(--space-unit));
  border-color: ${props => (props.paused ? 'var(--ion-color-warning)' : 'var(--ion-border-color)')};
`

const Row = styled(IonRow)`
  height: 73px;
  align-items: center;
  background-color: var(--ion-color-secondary);
  border-radius: var(--ion-border-radius);
  cursor: pointer;
  background-color: var(--greyscale-1);
`

const ChevronIcon = styled(Icon)`
  font-size: 1.25rem;
  margin-left: auto;
  margin-right: calc(3 * var(--space-unit));
`

const ClockIcon = styled(Icon)`
  font-size: 2.2rem;
  margin-left: calc(2 * var(--space-unit));
  margin-right: var(--space-unit);
`

const TeamMembersPage: React.FC = () => {
  const history = useHistory()

  const {
    teamStore,
    userProfileStore: { profile },
  } = useStores()

  const { members } = teamStore
  const { loadWhile } = useLoadingState()
  const [, , serializeApiError] = useSerializeApiError()

  const handleAdd = () => {
    history.push(TEAM_MEMBER_ADD_ROUTE)
  }

  const handleEdit = (member: TeamMember) => {
    // @ts-ignore see thread https://github.com/supplyhound/order-app/pull/137/files#r789259791
    history.push(generatePath(TEAM_MEMBER_EDIT_ROUTE, { id: member.id }))
  }

  const handleScheduleClick = () => {
    history.push(TEAM_MEMBER_SCHEDULE_ROUTE)
  }

  useEffect(() => {
    if (!profile) return

    loadWhile(() => {
      teamStore.setId(profile.team.id)

      return serializeApiError(() => teamStore.dispatchLoadMembers())
    })
  }, [profile])

  const isReminderPaused = _.isEqual(profile?.team.schedule_days!, [])

  const reminderScheduleLabel = `${formatTime(profile?.team.schedule_time!)}
    ${getReminderDaysLabel(profile?.team.schedule_days!)}`

  return (
    <IonPage>
      <Header label="Team" mode={HeaderModes.Root} />
      <Content>
        <h3>Team reminders schedule</h3>
        <ScheduleCard paused={isReminderPaused}>
          <Row onClick={handleScheduleClick}>
            <ClockIcon icon={timeOutline} width={30} />
            <h4>{isReminderPaused ? 'All team reminders paused' : reminderScheduleLabel}</h4>
            <ChevronIcon icon={chevronForwardOutline} />
          </Row>
        </ScheduleCard>
        <StyledButton size="large" expand="block" onClick={handleAdd}>
          Add Team Member
        </StyledButton>
        {!members.length && (
          <Container>
            <TeamIcon icon={Team} width={80} />
            <Text>
              Welcome to the team tool. Add team members to send them material reminders, and requested items will
              appear in your material lists.
            </Text>
          </Container>
        )}
        {!!members.length && (
          <>
            <h3>Your team</h3>
            <Card>
              {members.map((member, idx) => {
                return (
                  <div key={idx}>
                    <TeamMemberRow
                      teamMember={member}
                      handleEdit={() => handleEdit(member)}
                      isReminderPaused={isReminderPaused}
                    />
                    {members.length - 1 !== idx && <HorizontalRule />}
                  </div>
                )
              })}
            </Card>
          </>
        )}
      </Content>
    </IonPage>
  )
}

export default observer(TeamMembersPage)
