import React from 'react'
import { observer } from 'mobx-react-lite'
import { IonPage } from '@ionic/react'
import Content from '@supplyhound/components/Content'
import { Header, HeaderModes } from '@supplyhound/layout'

import HistoryItems from 'pages/History/HistoryItems'
import { useParams } from 'react-router-dom'

const ItemHistoryPage: React.FC = () => {
  const { jobSiteId } = useParams<{ jobSiteId: string }>()

  return (
    <IonPage>
      <Header mode={HeaderModes.Leaf} label="Orders" />
      <Content>
        <HistoryItems jobSiteId={Number(jobSiteId)} />
      </Content>
    </IonPage>
  )
}

export default observer(ItemHistoryPage)
