import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import _ from 'lodash'
import styled from 'styled-components'
import { IonPage, IonRow, IonRadioGroup, IonRadio, IonLabel, IonItem } from '@ionic/react'
import Icon from '@supplyhound/components/common/Icon'
import { timeOutline } from 'ionicons/icons'
import { Header, HeaderModes } from '@supplyhound/layout'

import Card from '@supplyhound/components/common/Card'
import Content from '@supplyhound/components/Content'
import OvalButton from '@supplyhound/components/buttons/OvalButton'
import { ChangeReminderTimeModal } from '@supplyhound/modals'
import useStores from '@supplyhound/hooks/useStores'
import { REMINDER_DAYS, formatTime } from '@supplyhound/utils/teamReminder'
import { useHistory } from 'react-router-dom'
import { TEAM_MEMBERS_ROUTE } from '@supplyhound/utils/config'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'

const StyledCard = styled(Card)<{ paused: boolean }>`
  border: 1px solid;
  border-color: ${props => (props.paused ? 'var(--ion-color-warning)' : 'var(--ion-color-success)')};
`

const HeaderRow = styled(IonRow)`
  height: 80px;
  display: flex;
  align-items: center;
`

const Row = styled(IonItem)`
  --background-hover: none;
  --min-height: 65px;
  --background: var(--ion-color-secondary)
  display: flex;
  align-items: flex-center;
`

const StyledIcon = styled(Icon)`
  font-size: 2.2rem;
  margin-left: 25px;
  margin-right: 20px;
`

const StyledHR = styled.hr<{ paused: boolean }>`
  border-top: 1px solid;
  border-color: ${props => (props.paused ? 'var(--ion-color-warning)' : 'var(--ion-color-success)')};
  margin: 0px;
`

const TimeLabel = styled(IonLabel)`
  font-size: 1.5rem;
  font-weight: 500;
`
const Label = styled(IonLabel)`
  font-size: 1.125rem;
  font-weight: 500;
`

const ChangeTimeButton = styled(OvalButton)`
  background-color: var(--ion-color-tertiary);
  color: var(--perma-dark-color);
  border-radius: 31px;
  height: 40px;
  margin-right: 15px;
  margin-left: auto;
`

const Radio = styled(IonRadio)`
  margin-left: 10px;
  margin-right: 30px;
`

const ActionRow = styled.div`
  background-color: var(--ion-background-color);
`

const SaveButton = styled(SubmitButton)`
  margin: 20px;
`
const TeamMemberSchedulePage: React.FC = () => {
  const [openModal, setOpenModal] = useState(false)
  const history = useHistory()

  const {
    userProfileStore: { profile },
    userProfileStore,
  } = useStores()

  const [selectedReminderTime, setSelectedReminderTime] = useState(profile?.team.schedule_time!)

  const [selectedReminderDays, setSelectedReminderDays] = useState(profile?.team.schedule_days!)

  const handleChangeTime = (time: string) => {
    setSelectedReminderTime(time)
  }

  const saveChangesHandler = async () => {
    await userProfileStore.dispatchUpdateReminderSchedule(selectedReminderTime, selectedReminderDays)
    history.push(TEAM_MEMBERS_ROUTE)
  }

  return (
    <IonPage>
      <Header label="Schedule reminders" mode={HeaderModes.Leaf} />
      <Content>
        <StyledCard paused={_.isEqual(selectedReminderDays, [])}>
          <IonRadioGroup
            value={selectedReminderDays}
            onIonChange={e => {
              setSelectedReminderDays(e.detail.value)
            }}
          >
            <HeaderRow>
              <StyledIcon icon={timeOutline} width={30} />
              <TimeLabel>{formatTime(selectedReminderTime)}</TimeLabel>
              <ChangeTimeButton color="background-color" onClick={() => setOpenModal(true)}>
                Change time
              </ChangeTimeButton>
            </HeaderRow>
            {REMINDER_DAYS.map((selection, index) => {
              return (
                <div key={index}>
                  {index !== REMINDER_DAYS.length && <StyledHR paused={_.isEqual(selectedReminderDays, [])} />}
                  <RadioItem label={selection.label} value={selection.scheduleDays} />
                </div>
              )
            })}
          </IonRadioGroup>
        </StyledCard>
      </Content>
      <ActionRow>
        <SaveButton size="large" expand="block" onClick={saveChangesHandler}>
          Save changes
        </SaveButton>
      </ActionRow>
      <ChangeReminderTimeModal
        openModal={openModal}
        setOpenModal={() => setOpenModal(false)}
        handleClick={handleChangeTime}
        selectedReminderTime={selectedReminderTime}
        setSelectedReminderTime={setSelectedReminderTime}
      />
    </IonPage>
  )
}

export default observer(TeamMemberSchedulePage)

interface RadioItemProps {
  label: string
  value: string[]
}

const RadioItem: React.FC<RadioItemProps> = ({ label, value }) => {
  return (
    <Row lines="none">
      <Radio slot="start" value={value} mode="md" color="anchor" />
      <Label className="ion-text-wrap">{label}</Label>
    </Row>
  )
}
