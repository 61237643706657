import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { IonList } from '@ionic/react'
import JobSiteItem from '@supplyhound/components/JobSiteItem'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { JobSite, JobSiteStatus, JobSiteStatuses, FileWithUrl } from '@supplyhound/types'
import styled from 'styled-components'
import useStores from '@supplyhound/hooks/useStores'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import { createOrdererListItem } from '@supplyhound/api'

interface Props {
  jobSiteClickRoute: string
  jobSiteAfterCreateRoute: string
  statusFilter?: JobSiteStatus
  showAddRow?: boolean
}

const StyledList = styled(IonList)`
  border: 1px solid var(--ion-border-color);
  border-radius: 8px;
  background-color: var(--ion-color-light);
  padding: 0 0;
  overflow: hidden;

  ion-item:last-child {
    --border-width: 0;
  }
`

const JobSiteList: React.FC<Props> = ({
  jobSiteClickRoute,
  jobSiteAfterCreateRoute,
  statusFilter = JobSiteStatuses.current,
  showAddRow = true,
}) => {
  const { loadWhile } = useLoadingState()
  const history = useHistory()
  const { jobSitesStore } = useStores()
  const jobSites = jobSitesStore.allJobSiteArray

  const location = useLocation<{
    item: {
      description: string
      quantity: number
      image?: FileWithUrl
    }
  }>()
  const hasItemToAddToList = !!location.state?.item

  useEffect(() => {
    loadWhile(jobSitesStore.dispatchFetchJobSites)
  }, [])

  return (
    <>
      <StyledList lines="full">
        {jobSites.map(jobSite =>
          jobSite.status === statusFilter ? (
            <JobSiteItem
              key={jobSite.id}
              label={jobSite.nickname || jobSite.formatted_address}
              labelSubText={
                jobSite.status !== JobSiteStatuses.archived
                  ? `${jobSite.orderer_list.active_items_count} items` +
                    (jobSite.team_lists_count ? `, ${jobSite.team_requests_count} requests` : '')
                  : 'Archived'
              }
              onClick={() => {
                loadWhile(async () => {
                  if (hasItemToAddToList) {
                    await createOrdererListItem({
                      orderer_list_id: jobSite.orderer_list.id,
                      ...location.state.item,
                    })
                  }
                }).then(() =>
                  history.push(
                    // @ts-ignore see thread https://github.com/supplyhound/order-app/pull/137/files#r789259791
                    generatePath(jobSiteClickRoute, { id: jobSite.id })
                  )
                )
              }}
            />
          ) : null
        )}
        {showAddRow && (
          <JobSiteItem
            addJobSiteItem
            onJobSiteCreate={async (jobSite: JobSite) => {
              if (hasItemToAddToList) {
                await loadWhile(async () =>
                  createOrdererListItem({
                    orderer_list_id: jobSite.orderer_list.id,
                    ...location.state.item,
                  })
                )
              }
              // @ts-ignore see thread https://github.com/supplyhound/order-app/pull/137/files#r789259791
              history.push(generatePath(jobSiteAfterCreateRoute, { id: jobSite.id }))
            }}
          />
        )}
      </StyledList>
    </>
  )
}

export default observer(JobSiteList)
