import React, { useState, ChangeEvent, useEffect } from 'react'
import { Field, FieldProps, FormikBag, FormikProps, withFormik, getIn } from 'formik'
import { InputChangeEventDetail, IonInputCustomEvent } from '@ionic/core'
import { IonInput, IonButton, useIonModal } from '@ionic/react'
import styled from 'styled-components'
import SupplierSearchField from '@supplyhound/forms/fields/SupplierSearchField'
import * as Yup from 'yup'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import { ContactWithCommunicationPreference, SupplierSearchResult } from '@supplyhound/types'
import Spacer from '@supplyhound/components/common/Spacer'
import { useGoogleAutocompleteService, useStores, useLoadingState } from '@supplyhound/hooks'
import ErrorLabel from '@supplyhound/components/common/ErrorLabel'
import SupplierOptions from '@supplyhound/components/SupplierOptions'
import { withInputBorder } from '@supplyhound/forms/fields/InputBorder'
import FieldHeader from '@supplyhound/forms/fields/FieldHeader'
import { parseNameWithCity } from '@supplyhound/utils/address'
import { fetchContactByPlaceId, fetchContactByAddress } from '@supplyhound/api'
import { AddEditContactModal } from '@supplyhound/modals'

type SupplierSearchFormProps = {
  supplier?: SupplierSearchResult
  onSubmit: (values: SupplierSearchFormValues) => void
  label: string
}

const StyledInput = styled(withInputBorder(IonInput))`
  background-color: var(--greyscale-1);
  border-radius: var(--ion-border-radius);
  --padding-start: 10px;
  height: 50px;
`
const ContactPreferenceText = styled.span`
  font-size: 0.8rem;
  font-weight: 500;
`

export type SupplierSearchFormValues = {
  supplier: SupplierSearchResult
}

const SupplierSearchForm: React.FC<SupplierSearchFormProps & FormikProps<SupplierSearchFormValues>> = ({
  submitForm,
  label,
  setFieldValue,
  values,
}) => {
  const { loadWhile } = useLoadingState()
  const { suppliersStore } = useStores()

  const [recentSuppliers, setRecentSuppliers] = useState([])
  const [controlText, setControlText] = useState('')
  const [searchResults, setSearchResults] = useState<SupplierSearchResult[]>([])
  const [showNextButton, setShowNextButton] = useState<boolean>(false)
  const [contactWithPreference, setContactWithPreference] = useState<ContactWithCommunicationPreference | undefined>()
  const [showSetContactSection, setShowSetContactSection] = useState<boolean>(false)
  const [showSupplierOptions, setShowSupplierOptions] = useState(true)
  const showRecentSuppliers = !controlText || searchResults.length === 0
  const { userProfileStore } = useStores()
  const { search: googleSearch } = useGoogleAutocompleteService()

  const recentSupplierFeatureFlag = userProfileStore.profile?.feature_flags.recent_suppliers

  const [present, dismiss] = useIonModal(AddEditContactModal, {
    dismiss: () => dismiss(),
    contact: {
      place_ids: values?.supplier.place_id ? [values?.supplier.place_id] : [],
      supplier_name: values?.supplier.displayText ?? '',
    },
    mode: 'create',
    onSuccess: (contact: ContactWithCommunicationPreference) => {
      setContactWithPreference(contact)
      setShowSetContactSection(false)
    },
  })

  const handleControlChange = (event: CustomEvent<InputChangeEventDetail>) => {
    const text = event.detail.value || ''
    if (text) {
      search(text)
    }
  }

  const handleOnInput = (event: IonInputCustomEvent<InputEvent>) => {
    const text = event.target.value || ''

    if (text) {
      setControlText(text.toString())
    } else {
      setControlText('')
      setShowNextButton(false)
      setContactWithPreference(undefined)
      setShowSetContactSection(false)
    }
  }

  const search = (input: string) => {
    googleSearch(input).then(predictions => {
      const suppliersArray: SupplierSearchResult[] = predictions.map(prediction => {
        return {
          name: prediction.name,
          address: prediction.address,
          place_id: prediction.place_id,
          displayText: parseNameWithCity(prediction.address),
        }
      })
      setSearchResults(suppliersArray)
    })
  }

  const controlTextDisplay = (supplier: SupplierSearchResult) => {
    if (supplier.supplier_id && supplier.displayText) {
      return supplier.displayText
    } else if (supplier.supplier_id) {
      return supplier.name
    } else {
      return parseNameWithCity(supplier.address)
    }
  }

  useEffect(() => {
    loadWhile(suppliersStore.dispatchFetchRecentSuppliers).then(recentSuppliers => {
      setRecentSuppliers(recentSuppliers)
    })
  }, [])

  return (
    <>
      {recentSupplierFeatureFlag ? (
        <>
          <FieldHeader label={label} />
          <Field
            name="supplier"
            children={(props: FieldProps<Partial<SupplierSearchResult>>) => {
              const form = props.form
              const { name } = props.field
              const error = getIn(form.errors, `${name}.address`)
              const touched = getIn(form.touched, name)
              return (
                <>
                  <StyledInput
                    placeholder="Enter and select supplier name or address"
                    onIonChange={handleControlChange}
                    onIonInput={handleOnInput}
                    debounce={400}
                    value={controlText}
                    onClick={(event: ChangeEvent<HTMLInputElement>) => {
                      if (event.target && event.target.select) {
                        event.target.select()
                      }
                      setShowSupplierOptions(true)
                    }}
                  />
                  {error && touched && <ErrorLabel>{error}</ErrorLabel>}

                  {showSetContactSection ? (
                    <>
                      <Spacer height={20} />
                      <h3>Supplier contact</h3>
                      <ContactPreferenceText>
                        You have not set a contact for this supplier yet. To notify a specific contact with this
                        supplier, please add their information.
                      </ContactPreferenceText>
                      <br />
                      <IonButton size="small" color="dark" onClick={() => present()}>
                        Add supplier contact
                      </IonButton>
                    </>
                  ) : null}

                  {contactWithPreference ? (
                    <>
                      <Spacer height={20} />
                      <h3>Supplier contact</h3>
                      <ContactPreferenceText>
                        Note - {`${contactWithPreference.first_name} ${contactWithPreference.last_name}`} will be
                        notified per your settings. You can update your supplier communication preferences in the
                        Contacts menu.
                      </ContactPreferenceText>
                    </>
                  ) : null}

                  {showNextButton && (
                    <>
                      <Spacer height={20} />
                      <SubmitButton onClick={submitForm}>Next</SubmitButton>
                    </>
                  )}
                  {showSupplierOptions && (
                    <SupplierOptions
                      supplierOptions={showRecentSuppliers ? recentSuppliers : searchResults}
                      sectionLabel={showRecentSuppliers ? 'Recents' : 'Suggested'}
                      onSupplierSelected={async supplier => {
                        if ((supplier.place_ids && supplier.place_ids.length > 0) || supplier.place_id) {
                          const contactWithCommunicationPreference = await fetchContactByPlaceId({
                            placeId: (supplier.place_ids ? supplier.place_ids[0] : supplier.place_id) ?? '',
                          })
                          if (contactWithCommunicationPreference) {
                            setFieldValue('supplier', {
                              ...supplier,
                              displayText: controlTextDisplay(supplier),
                              place_id: contactWithCommunicationPreference.place_ids[0],
                              place_ids: contactWithCommunicationPreference.place_ids,
                            })
                            setContactWithPreference(contactWithCommunicationPreference)
                            setShowSetContactSection(false)
                          } else {
                            setFieldValue('supplier', {
                              ...supplier,
                              displayText: controlTextDisplay(supplier),
                              place_id: supplier?.place_ids ? supplier.place_ids[0] : supplier.place_id,
                              place_ids: supplier.place_ids ? supplier.place_ids : [supplier.place_id],
                            })
                            setContactWithPreference(undefined)
                            setShowSetContactSection(true)
                          }
                        } else {
                          // This else case most likely handles the recent address where they have empty place_ids array
                          const contactWithCommunicationPreferenceOrPlaceId = await fetchContactByAddress({
                            address: supplier.address,
                          })
                          if (typeof contactWithCommunicationPreferenceOrPlaceId === 'object') {
                            const contactWithCommunicationPreference = contactWithCommunicationPreferenceOrPlaceId
                            setFieldValue('supplier', {
                              ...supplier,
                              displayText: controlTextDisplay(supplier),
                              place_id: contactWithCommunicationPreference.place_ids[0],
                              place_ids: contactWithCommunicationPreference.place_ids,
                            })
                            setContactWithPreference(contactWithCommunicationPreference)
                            setShowSetContactSection(false)
                          } else if (typeof contactWithCommunicationPreferenceOrPlaceId === 'string') {
                            const placeId = contactWithCommunicationPreferenceOrPlaceId
                            setFieldValue('supplier', {
                              ...supplier,
                              displayText: controlTextDisplay(supplier),
                              place_id: placeId,
                              place_ids: [placeId],
                            })
                            setContactWithPreference(undefined)
                            setShowSetContactSection(true)
                          } else {
                            setFieldValue('supplier', supplier)
                            setContactWithPreference(undefined)
                            setShowSetContactSection(false)
                          }
                        }
                        setControlText(controlTextDisplay(supplier))
                        setShowNextButton(true)
                        setShowSupplierOptions(false)
                      }}
                    />
                  )}
                </>
              )
            }}
          />
        </>
      ) : (
        <>
          <SupplierSearchField label={label} />
          <Spacer height={20} />
          <SubmitButton onClick={submitForm}>Next</SubmitButton>
        </>
      )}
    </>
  )
}

export default withFormik<SupplierSearchFormProps, SupplierSearchFormValues>({
  displayName: 'SupplierSearchForm',
  enableReinitialize: true,
  validationSchema: Yup.object().shape({
    supplier: Yup.object()
      .shape({
        name: Yup.string().required('Search for and select a street address'),
        supplier_id: Yup.string().nullable(),
        address: Yup.string().required('Search for and select a street address'),
      })
      .required('Required'),
  }),
  validateOnMount: true,

  mapPropsToValues: ({ supplier }) => ({
    supplier: supplier ?? {
      name: '',
      address: '',
    },
  }),
  handleSubmit(
    values: SupplierSearchFormValues,
    { props: { onSubmit } }: FormikBag<SupplierSearchFormProps, SupplierSearchFormValues>
  ) {
    onSubmit(values)
  },
})(SupplierSearchForm)
