import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import { IonGrid, IonRow, IonCol, IonButton, useIonModal, useIonToast } from '@ionic/react'
import { mailUnreadOutline } from 'ionicons/icons'
import Spacer from '@supplyhound/components/common/Spacer'
import TextInputField from '@supplyhound/forms/fields/TextInputField'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import { close } from 'ionicons/icons'

import { useLoadingState } from '@supplyhound/hooks'
import useStores from 'hooks/useStore'

import { Invite } from 'types'

import ActionModal from '@supplyhound/components/common/Modals/ActionModal'

const Grid = styled(IonGrid)`
  border: 1px solid var(--ion-border-color);
  border-radius: var(--ion-border-radius);
  background-color: var(--greyscale-1);
`

const Row = styled(IonRow)`
  border-bottom: 1px solid var(--ion-border-color);

  &:last-child {
    border-bottom: none;
  }
`

const Col = styled(IonCol)`
  border-right: 1px solid var(--ion-border-color);
  display: flex;
  align-items: center;

  &:last-child {
    border-right: none;
  }
`

const EmailCol = styled(Col)`
  flex: 1 0 200px;
`

const ActionCol = styled(Col)`
  flex: 0 0 170px;
`

const StyledSubmitbutton = styled(SubmitButton)`
  width: 150px;
`

const ActionButton = ({ invite, onSubmit }: { invite: Invite; onSubmit: () => void }) => {
  const { companyStore } = useStores()
  const { loadWhile } = useLoadingState()

  const [presentDeleteModal, dismissDeleteModal] = useIonModal(ActionModal, {
    icon: mailUnreadOutline,
    title: `Revoke Member Invitation`,
    subtext: `Are you sure you want to revoke your invitation to join your company from ${invite.email}?`,
    submitText: 'Revoke Invite',
    onDismiss: () => dismissDeleteModal(),
    onSubmit: () => {
      loadWhile(async () => {
        await companyStore.dispatchRevokeEmployeeInvite({ id: invite.id })
      }).then(() => {
        onSubmit()
        dismissDeleteModal()
      })
    },
  })

  return (
    <IonButton
      size="small"
      onClick={() => {
        presentDeleteModal()
      }}
    >
      Delete
    </IonButton>
  )
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Enter a valid email address').required('Enter a valid email address'),
})

const EmployeeDirectory = () => {
  const { companyStore } = useStores()
  const { loadWhile } = useLoadingState()
  const [invites, setInvites] = useState<Invite[]>([])

  const [presentToast] = useIonToast()

  useEffect(() => {
    loadWhile(async () => {
      const fetchedInvites = await companyStore.dispatchFetchInvites()
      setInvites(fetchedInvites)
    })
  }, [])

  return (
    <div>
      <Formik<{ email: string }>
        initialValues={{ email: '' }}
        onSubmit={({ email }, { resetForm }) => {
          loadWhile(async () => {
            const invite = await companyStore.dispatchInviteEmployee({ email })
            if (invite.status === 'pending') {
              setInvites([...invites, invite])
            }
          }).then(() => {
            resetForm()
          })
        }}
        validationSchema={validationSchema}
      >
        <Form>
          <Field label="Invite orderer" name="email" placeholder="Email" component={TextInputField} type="email" />
          <Spacer height={20} />
          <StyledSubmitbutton type="submit">Invite</StyledSubmitbutton>
        </Form>
      </Formik>
      <Spacer height={40} />

      <h2>Pending Invites</h2>
      <Spacer height={20} />
      <Grid>
        <Row>
          <EmailCol>Email</EmailCol>
          <ActionCol>Action</ActionCol>
        </Row>
        {invites.length > 0 ? (
          invites.map(invite => {
            return (
              <Row key={invite.id}>
                <EmailCol>{invite.email}</EmailCol>
                <ActionCol>
                  <ActionButton
                    invite={invite}
                    onSubmit={() => {
                      setInvites([...invites.filter(invitation => invite.id !== invitation.id)])
                    }}
                  />
                  <IonButton
                    size="small"
                    onClick={() => {
                      loadWhile(async () => {
                        await companyStore.dispatchResendEmployeeInvite({ id: invite.id })
                      }).catch(() => {
                        presentToast({
                          header: 'Invite has been resent',
                          position: 'top',
                          mode: 'ios',
                          cssClass: 'standard',
                          duration: 5000,
                          buttons: [
                            {
                              icon: close,
                              role: 'cancel',
                            },
                          ],
                        })
                      })
                    }}
                  >
                    Resend
                  </IonButton>
                </ActionCol>
              </Row>
            )
          })
        ) : (
          <Row>
            <Col>No invites pending.</Col>
          </Row>
        )}
      </Grid>
    </div>
  )
}

export default observer(EmployeeDirectory)
