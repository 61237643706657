import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import CreditCardDisplay from '@supplyhound/components/CreditCardDisplay'
import BaseModal from '@supplyhound/components/common/Modals/BaseModal'
import ToggleableTabs, { ToggleableTab } from '@supplyhound/components/ToggleableTabs'
import Card from '@supplyhound/components/common/Card'
import Spacer from '@supplyhound/components/common/Spacer'
import useStores from '@supplyhound/hooks/useStores'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import { PaymentMethod, BillingPlanType, SubscriptionType } from '@supplyhound/types'
import BaseButton from '@supplyhound/components/buttons/BaseButton'
import { formatDatetime } from '@supplyhound/utils/formatters'
import SubscriptionCard from './components/SubscriptionCard'
import SubscriptionStatus from './components/SubscriptionStatus'
import { subscriptionPlans, subscriptionPlanLookup } from './config'

const CreditCardContainer = styled(Card)`
  margin-bottom: 20px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const Center = styled.div`
  display: flex;
  justify-content: center;
`

const ToggleableTabsContainer = styled.div`
  width: 400px;
  height: 60px;
`

const SubscriptionCardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Description = styled.p`
  color: var(--greyscale-6);
  font-size: 16px;
`

const SubscriptionManage: React.FC<{
  dismiss: () => void
}> = ({ dismiss }) => {
  const {
    paymentMethodStore: { paymentMethods },
    userProfileStore: { subscriptionStore },
    userProfileStore,
  } = useStores()
  const { loadWhile } = useLoadingState()
  const subscription = subscriptionStore.getSubscriptionJson
  const [activeTab, setActiveTab] = useState<BillingPlanType>('monthly')
  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState<SubscriptionType>('free')
  const subscriptionPaymentMethod = paymentMethods.find(
    paymentMethod => paymentMethod.id === subscription?.stripe_payment_method_id
  )
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | undefined>(
    subscriptionPaymentMethod
  )
  const [changeCreditCard, setChangeCreditCard] = useState<boolean>(false)

  const tabs: ToggleableTab[] = [
    {
      name: 'monthly',
      label: 'Monthly',
    },
    {
      name: 'annually',
      label: 'Annually',
    },
  ]
  const subscriptionType = subscription?.subscription_type
  const billingPlanType = subscription?.billing_plan_type

  useEffect(() => {
    if (subscription?.billing_plan_type) {
      setActiveTab(subscription.billing_plan_type)
    }
  }, [subscription?.billing_plan_type])

  useEffect(() => {
    if (subscriptionType) {
      setSelectedSubscriptionType(subscriptionType)
    }
  }, [subscriptionType])

  if (!subscription) {
    return null
  }

  const isSubscriptionCanceled = !!subscription.ended_at

  return (
    <BaseModal label={`SupplyHound ${_.capitalize(subscriptionType!)}`} onDidDismiss={() => dismiss()}>
      <Spacer height={20} />
      {isSubscriptionCanceled}
      {isSubscriptionCanceled ? (
        <>
          <Row>
            <span>
              Your company subscription has been downgraded to Free. Your {_.capitalize(subscriptionType!)} plan will
              remain active until the end of the billing period but will not be renewed.
            </span>
          </Row>
          <Spacer height={20} />
          <Row>
            <strong>Subscription end date</strong>
            <span>{subscription && formatDatetime(subscription.ended_at!, 'MM/dd/yyyy')}</span>
          </Row>
        </>
      ) : (
        <Row>
          <strong>Next billing date</strong>
          <span>{subscription && formatDatetime(subscription.next_billing_at, 'MM/dd/yyyy')}</span>
        </Row>
      )}
      <Spacer height={20} />
      <Row>
        <strong>Current Billing Plan</strong>
      </Row>
      <SubscriptionStatus subscriptionType={subscriptionType!} billingPlanType={billingPlanType!} />
      {subscriptionType === 'legacy' && (
        <Description>
          You are currently on a legacy subscription plan that is no longer being offered. You may choose to switch to
          one of the newer subscription plans to access more features or stay on your current subscription. To cancel
          your legacy subscription, choose the Free plan below.
        </Description>
      )}
      {!isSubscriptionCanceled && (
        <>
          <Spacer height={20} />
          <Center>
            <ToggleableTabsContainer>
              <ToggleableTabs
                activeTabName={activeTab}
                tabs={tabs}
                onToggle={name => {
                  setActiveTab(name as BillingPlanType)
                }}
              />
            </ToggleableTabsContainer>
          </Center>
          <Spacer height={20} />
          {selectedSubscriptionType !== subscriptionType ? (
            <Center>
              <SubscriptionCard
                billingPlanType={activeTab}
                planName={subscriptionPlanLookup[selectedSubscriptionType].planName}
                price={subscriptionPlanLookup[selectedSubscriptionType].price}
                bulletPoints={subscriptionPlanLookup[selectedSubscriptionType].bulletPoints}
                onChoose={() => {
                  setSelectedSubscriptionType(subscriptionType!)
                }}
                chooseButtonText="Change"
                chooseButtonColor="tertiary"
              />
            </Center>
          ) : (
            <SubscriptionCardsContainer className="subscription-cards-container">
              {subscriptionPlans.map((subscriptionPlan, idx) => (
                <SubscriptionCard
                  key={idx}
                  billingPlanType={activeTab}
                  planName={subscriptionPlan.planName}
                  price={subscriptionPlan.price}
                  bulletPoints={subscriptionPlan.bulletPoints}
                  onChoose={planName => {
                    setSelectedSubscriptionType(planName)
                  }}
                  currentPlan={subscriptionPlan.planName === subscriptionType}
                />
              ))}
            </SubscriptionCardsContainer>
          )}
          <Spacer height={20} />
          <Row>
            <span>Payment Method</span>
          </Row>
          {changeCreditCard &&
            paymentMethods.map(payment => (
              <CreditCardContainer key={payment.id}>
                <CreditCardDisplay
                  card={payment.card}
                  pillText="Choose"
                  onClick={() => {
                    setSelectedPaymentMethod(payment)
                    setChangeCreditCard(false)
                  }}
                />
              </CreditCardContainer>
            ))}
          {!changeCreditCard && !!selectedPaymentMethod && (
            <CreditCardContainer>
              <CreditCardDisplay
                card={selectedPaymentMethod.card}
                pillText="Change"
                onClick={() => {
                  setChangeCreditCard(true)
                }}
              />
            </CreditCardContainer>
          )}
          <BaseButton
            onClick={() => {
              if (subscription && activeTab && selectedPaymentMethod && selectedSubscriptionType) {
                if (selectedSubscriptionType === 'free') {
                  loadWhile(async () => {
                    await subscriptionStore.dispatchDeleteSubscription(subscription.id)
                    await userProfileStore.dispatchLoadProfile()
                  }).then(() => dismiss())
                } else {
                  loadWhile(async () => {
                    await subscriptionStore.dispatchUpdateSubscription(subscription.id, {
                      subscriptionType: selectedSubscriptionType,
                      stripePaymentMethodId: selectedPaymentMethod.id,
                      billingPlanType: activeTab,
                    })
                    await userProfileStore.dispatchLoadProfile()
                  }).then(() => dismiss())
                }
              }
            }}
          >
            Save Changes
          </BaseButton>
          <Spacer height={20} />
        </>
      )}
    </BaseModal>
  )
}

export default observer(SubscriptionManage)
