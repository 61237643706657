import React from 'react'
import { observer } from 'mobx-react-lite'
import _ from 'lodash'
import styled from 'styled-components'
import { useIonModal } from '@ionic/react'
import { ShLogoIcon } from '@supplyhound/images'
import Item from '@supplyhound/components/common/Item'
import SubscriptionModal from 'components/modals/SubscriptionModal'
import SubscriptionBadge from 'components/SubscriptionBadge'
import useStores from '@supplyhound/hooks/useStores'

const StyledItem = styled(Item)<{ $editable: boolean }>`
  border: 1px solid var(--ion-border-color);
  --background: var(--ion-secondary-color);
  cursor: ${props => (props.$editable ? 'pointer' : 'auto')};
`

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  word-wrap: break-word;
  justify-content: space-between;
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`

const LogoImg = styled.img`
  height: 40px;
`

const Text = styled.span`
  font-size: 16px;
`

const SubscriptionStatus = ({ editable = true }: { editable?: boolean }) => {
  const { userProfileStore } = useStores()
  const subscriptionType = _.capitalize(userProfileStore.profile?.subscription?.subscription_type || '')
  const isSubscriptionAdministrator = !!userProfileStore.profile?.subscription?.administrator
  const isLegacy = subscriptionType === 'Legacy'
  const isEditable = editable && (isSubscriptionAdministrator || isLegacy)

  const [presentSubscriptionModal, dismissSubscriptionModal] = useIonModal(SubscriptionModal, {
    dismiss: () => dismissSubscriptionModal(),
    type: 'manage',
  })

  return (
    <StyledItem
      detail={isEditable}
      $editable={isEditable}
      lines="none"
      onClick={() => {
        if (isEditable) {
          presentSubscriptionModal({ cssClass: ['subscription'] })
        }
      }}
    >
      <ContentContainer>
        <LogoContainer>
          <LogoImg src={ShLogoIcon} alt="logo" />
          <SubscriptionBadge clickable={isEditable} label={subscriptionType} />
        </LogoContainer>
        {isSubscriptionAdministrator ? (
          <Text>Manage subscription</Text>
        ) : (
          <Text>
            You're a part of {userProfileStore.profile?.company_name}'s {subscriptionType} program. Enjoy!
          </Text>
        )}
      </ContentContainer>
    </StyledItem>
  )
}

export default observer(SubscriptionStatus)
