import React, { useState, useEffect } from 'react'
import { IonPage } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import useStores from '@supplyhound/hooks/useStores'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import useQuery from '@supplyhound/hooks/useQuery'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import { BuildList, JobSiteDetailRouteParams } from '@supplyhound/types'
import { Header } from '@supplyhound/layout'
import ToggleableTabs, { ToggleableTab } from '@supplyhound/components/ToggleableTabs'
import OrdererList from '@supplyhound/components/OrdererList'
import TeamMemberList from '@supplyhound/components/TeamMemberList'
import NotificationBanner from '@supplyhound/components/NotificationBanner'

const ToggleContainer = styled.div`
  padding: var(--space-unit) calc(2 * var(--space-unit)) calc(2 * var(--space-unit)) calc(2 * var(--space-unit));
  background: var(--ion-background-color);
`

const JobSiteListDetailPage: React.FC = () => {
  const { loadWhile } = useLoadingState()
  const { query } = useQuery()
  const [activeTabName, setActiveTabName] = useState(
    query.tab === BuildList.Requests.toLowerCase() ? BuildList.Requests : BuildList.MyList
  )
  const [displayBanner, setDisplayBanner] = useState(false)

  const { jobSitesStore, ordererTeamRequestsStore } = useStores()

  const { id } = useParams<JobSiteDetailRouteParams>()
  const jobSiteId = parseInt(id)

  const list = jobSitesStore.getJobSiteOrdererList(jobSiteId)
  const jobSite = jobSitesStore.jobSites[jobSiteId]
  const items = list?.active_items ? list.active_items : []
  const selectedItems = items.filter(({ selected }) => selected)
  const isAllTeamMemberListItemsEmpty = ordererTeamRequestsStore.isAllTeamMemberListItemsEmpty
  const [requestsCount, setRequestsCount] = useState<number | undefined>(0)

  useEffect(() => setRequestsCount(jobSite?.team_requests_count), [jobSite])

  const handleTeamListChange = () => {
    setRequestsCount(ordererTeamRequestsStore.teamMemberListCount)
  }

  const tabs: ToggleableTab[] = [
    {
      name: BuildList.MyList,
      label: BuildList.MyList,
    },
    {
      name: BuildList.Requests,
      label: (jobSite ? `[${requestsCount}]` : '') + ` ${BuildList.Requests}`,
    },
  ]

  useEffect(() => {
    loadWhile(jobSitesStore.dispatchFetchJobSites)
  }, [])

  return (
    <IonPage>
      <Header
        label="Materials List"
        subLabel={(() => {
          let subLabel = ''
          if (activeTabName === BuildList.Requests) {
            subLabel = isAllTeamMemberListItemsEmpty
              ? 'Go back to your list to order'
              : 'Check items to add them to your list'
          }

          if (activeTabName === BuildList.MyList) {
            subLabel =
              selectedItems.length > 0
                ? `${selectedItems.length}/${items.length} items selected for order`
                : 'Check items to order them'
          }
          return subLabel
        })()}
      />
      {displayBanner && (
        <NotificationBanner
          onDidDismiss={() => setDisplayBanner(false)}
          message="These items have been added to your active materials list."
        />
      )}
      <ToggleContainer>
        <ToggleableTabs
          activeTabName={activeTabName}
          tabs={tabs}
          onToggle={name => {
            setActiveTabName(name)
          }}
        />
      </ToggleContainer>
      {activeTabName === BuildList.MyList ? (
        <OrdererList />
      ) : (
        <TeamMemberList
          onAcceptItems={() => setDisplayBanner(true)}
          onBackToList={() => setActiveTabName(BuildList.MyList)}
          onListChange={handleTeamListChange}
        />
      )}
    </IonPage>
  )
}

export default observer(JobSiteListDetailPage)
