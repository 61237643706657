import React from 'react'
import { IonPage } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import { Header, HeaderModes } from '@supplyhound/layout'

import Content from '@supplyhound/components/Content'
import TabsLayout from '@supplyhound/components/TabsLayout'
import HistoryItems from 'pages/History/HistoryItems'
import HistoryOrders from 'pages/History/HistoryOrders'

const HistoryPage = () => {
  const tabs = [
    {
      id: 'items',
      label: 'Items',
      View: HistoryItems,
    },
    {
      id: 'orders',
      label: 'Orders',
      View: HistoryOrders,
    },
  ]

  return (
    <IonPage>
      <Header mode={HeaderModes.Root} label={'Orders'} />
      <Content>
        <TabsLayout tabs={tabs} />
      </Content>
    </IonPage>
  )
}

export default observer(HistoryPage)
