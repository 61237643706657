import { variables, typography } from '@supplyhound/styles'

const defaultTheme = {
  contentGutters: '20px',
  fieldHeight: '50px',
  variables,
  typography,
}

export default defaultTheme
