import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import Icon from '@supplyhound/components/common/Icon'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import Content from '@supplyhound/components/Content'
import { checkmarkCircleOutline } from 'ionicons/icons'
import { JobSiteDetailRouteParams, MaterialListHeader, AbstractItem, ID } from '@supplyhound/types'
import useStores from '@supplyhound/hooks/useStores'
import TeamMemberMaterialsList from '@supplyhound/components/TeamMemberMaterialsList'
import Spacer from '@supplyhound/components/common/Spacer'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import Card from '@supplyhound/components/common/Card'

interface Props {
  onAcceptItems: () => void
  onBackToList: () => void
  onListChange: () => void
}

const OrderContainer = styled.div`
  padding: var(--space-unit) calc(2 * var(--space-unit)) calc(2 * var(--space-unit)) calc(2 * var(--space-unit));
  background: var(--ion-background-color);
`

const EmptyListContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const NoRequestIcon = styled(Icon)`
  align-self: center;
  margin: 20px auto;
`

const StyledContent = styled(Content)<{ isAllTeamMemberListItemsEmpty: boolean }>`
  ${props =>
    props.isAllTeamMemberListItemsEmpty
      ? `
      &::part(scroll) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      `
      : ''}
`

const Text = styled.h4`
  text-align: center;
`
const StyledCard = styled(Card)`
  width: 100%;
  height: 60px;
  margin: 0px;
  padding: 20px 0px;
  text-align: center;
  font-weight: 500;
`

const AddToMyListButton = styled(SubmitButton)`
  width: 50vw;
`

const RemoveButton = styled(SubmitButton)`
  width: 50vw;
  border: var(--thick-dark-border);
  border-radius: var(--ion-border-radius);
  margin-right: calc(1.5 * var(--space-unit));
`

const ButtonContainer = styled.div`
  display: flex;
`
const TeamMemberList: React.FC<Props> = ({ onAcceptItems, onBackToList, onListChange }) => {
  const { loadWhile } = useLoadingState()

  const {
    jobSitesStore,
    teamStore,
    jobSitesStore: { listStore },
    userProfileStore: { profile },
    ordererTeamRequestsStore,
  } = useStores()

  const { id } = useParams<JobSiteDetailRouteParams>()
  const jobSiteId = parseInt(id)
  const jobSite = jobSitesStore.jobSites[jobSiteId]
  const jobSiteStreetAddress = `${!!jobSite ? jobSite.name : 'your jobsite'}`

  const selectedItems = ordererTeamRequestsStore.selected
  const teamMemberLists = toJS(ordererTeamRequestsStore.teamMemberLists)
  const isAllTeamMemberListItemsEmpty = ordererTeamRequestsStore.isAllTeamMemberListItemsEmpty

  useEffect(() => {
    if (!profile) return
    loadWhile(async () => {
      await teamStore.setId(profile.team.id)
      await teamStore.dispatchLoadMembers()
    })
  }, [profile])

  useEffect(() => {
    loadWhile(async () => {
      if (teamStore.id) {
        await ordererTeamRequestsStore.dispatchFetchLists(teamStore.id, jobSiteId)
        onListChange()
      }
    })
  }, [])

  const archiveItemsHandler = () => {
    loadWhile(async () => {
      if (teamStore.id) {
        await ordererTeamRequestsStore.dispatchArchiveItems(teamStore.id)
        onListChange()
      }
    })
  }

  const acceptItemsHandler = () => {
    loadWhile(async () => {
      if (teamStore.id) {
        await ordererTeamRequestsStore.dispatchAcceptTeamMemberListItems(teamStore.id, jobSiteId)
        await listStore.dispatchFetchList(jobSite.orderer_list.id)
        onAcceptItems()
        onListChange()
      }
    })
  }
  return (
    <>
      <StyledContent isAllTeamMemberListItemsEmpty={isAllTeamMemberListItemsEmpty}>
        {isAllTeamMemberListItemsEmpty ? (
          <EmptyListContainer>
            <NoRequestIcon icon={checkmarkCircleOutline} width={75} color="success" />
            <Text>All requested items from your teammates at {jobSiteStreetAddress} have been reviewed.</Text>
          </EmptyListContainer>
        ) : (
          <>
            {teamMemberLists.map((list, index) => {
              if (!list.items.length) return ''

              return (
                <div key={index}>
                  <TeamMemberMaterialsList
                    listId={list.id}
                    items={list.items}
                    onItemSubmit={item => ordererTeamRequestsStore.dispatchUpdateSubmittedTeamMemberItem(item, list.id)}
                    header={MaterialListHeader.TeamMember}
                    teamMember={list.team_member}
                    onCheckboxClick={(item: AbstractItem & { id: ID; selected: boolean }) => {
                      ordererTeamRequestsStore.setTeamMemberListItemSelectState(item.id, item.selected)
                    }}
                    onCheckboxControlClick={isCheckboxOn => {
                      list.items.forEach(item => {
                        ordererTeamRequestsStore.setTeamMemberListItemSelectState(item.id, isCheckboxOn)
                      })
                    }}
                  />
                  <Spacer height={15} />
                </div>
              )
            })}
          </>
        )}
      </StyledContent>
      <OrderContainer>
        {!isAllTeamMemberListItemsEmpty ? (
          <>
            {selectedItems.length > 0 ? (
              <ButtonContainer>
                <RemoveButton color="secondary" onClick={archiveItemsHandler}>
                  Remove
                </RemoveButton>

                <AddToMyListButton onClick={acceptItemsHandler}>Add to my list</AddToMyListButton>
              </ButtonContainer>
            ) : (
              <StyledCard>Check items to add them to your list.</StyledCard>
            )}
          </>
        ) : (
          <SubmitButton size="large" expand="block" onClick={onBackToList}>
            Back to my list
          </SubmitButton>
        )}
      </OrderContainer>
    </>
  )
}

export default observer(TeamMemberList)
