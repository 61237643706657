import React from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { GoogleMap } from '@react-google-maps/api'
import DirectionsMap from '@supplyhound/components/DirectionsMap'
import {
  LAST_ORDER_OMIT_FIELDS,
  TaskTypes,
  UnidentifiedDeliveryTask,
  UnidentifiedGenericTask,
} from '@supplyhound/types'
import useStores from '@supplyhound/hooks/useStores'
import { IonPage } from '@ionic/react'
import SuccessConfirmationCard from '@supplyhound/components/SuccessConfirmationCard'
import Content from '@supplyhound/components/Content'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import { HISTORY_ROUTE, JOB_SITE_LIST_DETAIL_ROUTE } from '@supplyhound/utils/config'
import styled from 'styled-components'
import PaymentMethodComponent from '@supplyhound/components/PaymentMethod'
import OrderDetails from '@supplyhound/components/OrderDetails'
import omit from 'lodash/omit'
import { Header, HeaderModes } from '@supplyhound/layout'

import MaterialsList from '@supplyhound/components/MaterialsList'
import { GoogleMapStyles } from '@supplyhound/styles'
import Caption from '@supplyhound/components/common/text/Caption'

const StyledSubmitButton = styled(SubmitButton)`
  width: 100%;
  height: 55px;
  margin: 20px 0;
`

const StyledMaterialsList = styled(MaterialsList)`
  margin: calc(var(--space-unit) * 2) 0px;
`

const OrderReceiptPage: React.FC = () => {
  const history = useHistory()
  const { jobSitesStore, userProfileStore } = useStores()
  const lastOrderedTask = jobSitesStore.lastOrderedTask
  const task = omit(lastOrderedTask, LAST_ORDER_OMIT_FIELDS) as UnidentifiedGenericTask
  const jobSiteId = lastOrderedTask?.job_site_id

  const isDelivery = lastOrderedTask?.type === TaskTypes.Delivery
  const isUserSetupForBillToAccount = userProfileStore.profile?.feature_flags.bill_to_account

  return (
    <IonPage>
      <Header mode={HeaderModes.Root} label="Review" />
      <Content>
        <SuccessConfirmationCard>
          <div>
            <h3>Order placed</h3>
            <Caption>We will keep you posted on your order status</Caption>
          </div>
        </SuccessConfirmationCard>
        {lastOrderedTask && (
          <GoogleMap options={{ gestureHandling: 'none' }} mapContainerStyle={GoogleMapStyles}>
            <DirectionsMap
              origin={lastOrderedTask.pickup_address}
              destination={
                isDelivery
                  ? (lastOrderedTask as UnidentifiedDeliveryTask).delivery_address
                  : lastOrderedTask.pickup_address
              }
              travelMode={google.maps.TravelMode.DRIVING}
            />
          </GoogleMap>
        )}

        <StyledSubmitButton onClick={() => history.push(generatePath(JOB_SITE_LIST_DETAIL_ROUTE, { id: jobSiteId }))}>
          Back to my list
        </StyledSubmitButton>

        <StyledSubmitButton
          color="tertiary"
          onClick={() =>
            history.push({
              pathname: HISTORY_ROUTE,
              search: '?tab=orders',
            })
          }
        >
          View my orders
        </StyledSubmitButton>

        <OrderDetails task={task} billingDetails={lastOrderedTask?.billingDetails} />

        {!!lastOrderedTask?.items?.length && (
          <StyledMaterialsList jobSiteId={jobSiteId} items={lastOrderedTask?.items} displayOnly canRequestChange />
        )}

        {isDelivery && !isUserSetupForBillToAccount && <PaymentMethodComponent editable={false} />}
      </Content>
    </IonPage>
  )
}

export default observer(OrderReceiptPage)
