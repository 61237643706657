import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { IonPage } from '@ionic/react'

import { TeamMemberAuthenticationValues, TeamMemberConfirmationQuery } from '@supplyhound/types'
import useStores from '@supplyhound/hooks/useStores'
import useQuery from '@supplyhound/hooks/useQuery'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import { Header } from '@supplyhound/layout'
import Content from '@supplyhound/components/Content'
import Spacer from '@supplyhound/components/common/Spacer'
import TeamMemberLoginForm from '@supplyhound/forms/TeamMemberLoginForm'
import { useHistory } from 'react-router-dom'
import { TEAM_MEMBER_LOGIN_ROUTE } from '@supplyhound/utils/config'

const TeamMemberConfirmPage: React.FC = () => {
  const history = useHistory()
  const { teamMemberProfileStore } = useStores()
  const {
    query: { token, phone },
  } = useQuery<TeamMemberConfirmationQuery>()
  const { loadWhile } = useLoadingState()

  const handleSubmit = useCallback(
    ({ phone }: TeamMemberAuthenticationValues) => {
      return loadWhile(() => teamMemberProfileStore.dispatchConfirmTeamMember(phone, token)).catch(e => {
        const err = e.response
        if (err.status === 403 && err.data.message === 'already confirmed') {
          history.push({
            pathname: TEAM_MEMBER_LOGIN_ROUTE,
            search: `?phone=${phone}`,
          })
        } else {
          throw e
        }
      })
    },
    [teamMemberProfileStore.dispatchConfirmTeamMember, token]
  )

  return (
    <IonPage>
      <Header label="Team Tool" />
      <Content>
        <Spacer height={20} />
        <TeamMemberLoginForm phone={phone} onSubmit={handleSubmit} confirmation />
      </Content>
    </IonPage>
  )
}

export default observer(TeamMemberConfirmPage)
