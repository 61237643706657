import { makeAutoObservable, toJS } from 'mobx'

import {
  inviteEmployee,
  fetchCompany,
  removeEmployee,
  fetchInvites,
  revokeEmployeeInvite,
  resendEmployeeInvite,
} from 'api/company'
import { Employee } from 'types'
import { ID } from '@supplyhound/types'

export default class CompanyStore {
  employees: Employee[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setEmployees(employees: Employee[]) {
    this.employees = employees
  }

  get employeesJSON() {
    return toJS(this.employees)
  }

  dispatchFetchCompany = async () => {
    const company = await fetchCompany()
    this.setEmployees(company.employments)
    return company
  }

  dispatchInviteEmployee = async ({ email }: { email: string }) => {
    return inviteEmployee({ email })
  }

  dispatchRemoveEmployee = async ({ id }: { id: ID }) => {
    return removeEmployee({ id })
  }

  dispatchFetchInvites = async () => {
    return fetchInvites()
  }

  dispatchRevokeEmployeeInvite = async ({ id }: { id: ID }) => {
    return revokeEmployeeInvite({ id })
  }

  dispatchResendEmployeeInvite = async ({ id }: { id: ID }) => {
    return resendEmployeeInvite({ id })
  }
}
