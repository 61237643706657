import React, { useEffect } from 'react'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { IonPage, useIonModal } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import useStores from '@supplyhound/hooks/useStores'
import DeliveryOnlyDetailsForm from '@supplyhound/forms/DetailsForm/DeliveryOnlyDetailsForm'
import { toJS } from 'mobx'
import { ID, UnidentifiedDeliveryTask, UnidentifiedGenericTask } from '@supplyhound/types'
import Content from '@supplyhound/components/Content'
import { JOB_SITE_TASK_DETAIL_ROUTE, DELIVERY_ONLY_DETAIL_ROUTE } from '@supplyhound/utils/config'
import { Header, HeaderModes } from '@supplyhound/layout'

import useLoadingState from '@supplyhound/hooks/useLoadingState'
import { DeliveryFormHelpModal } from '@supplyhound/modals'
import Icon from '@supplyhound/components/common/Icon'
import { helpCircleOutline } from 'ionicons/icons'

const DeliveryOnlyTaskDetailPage: React.FC = () => {
  const { userProfileStore, jobSitesStore } = useStores()
  const { loadWhile } = useLoadingState()
  const history = useHistory<{
    jobSiteId?: ID
  }>()

  let task = jobSitesStore.taskStore.getTask(jobSitesStore.deliveryOnlyJobSiteId) as UnidentifiedDeliveryTask

  useEffect(() => {
    loadWhile(jobSitesStore.dispatchFetchJobSites)
  }, [])

  const [presentHelp, dimissHelp] = useIonModal(DeliveryFormHelpModal, {
    dismiss: () => dimissHelp(),
    isDeliveryOnly: true,
  })

  const location = useLocation()
  if (!task && location.pathname !== DELIVERY_ONLY_DETAIL_ROUTE) {
    return null
  }

  const handleDeliveryOnlyDetailsFormSubmit = (values: UnidentifiedGenericTask) => {
    const jobSiteId = values.job_site_id
    const selectedJobSite = jobSitesStore.jobSiteArray.find(jobSite => jobSite.id === jobSiteId)
    jobSitesStore.deliveryOnlyJobSite = selectedJobSite!
    jobSitesStore.updateJobSiteTask(jobSitesStore.deliveryOnlyJobSiteId, values)
    history.replace({
      pathname: generatePath(DELIVERY_ONLY_DETAIL_ROUTE),
      search: `?jobSiteId=${jobSiteId}`,
    })
    history.push(
      generatePath(JOB_SITE_TASK_DETAIL_ROUTE, {
        id: jobSitesStore.deliveryOnlyJobSiteId!,
      })
    )
  }

  const searchParams = new URLSearchParams(history.location.search)
  const jobSiteIdSearchParam = searchParams.get('jobSiteId')
  const jobSiteId = jobSiteIdSearchParam ? parseInt(jobSiteIdSearchParam) : -1

  const selectedJobSite = jobSitesStore.jobSiteArray.find(jobSite => jobSite.id === jobSiteId)
  if (selectedJobSite) {
    task = {
      ...task,
      job_site_id: jobSiteId,
      delivery_address: task.delivery_address,
      job_site_name: selectedJobSite?.name,
      delivery_username: selectedJobSite?.contact_name,
      delivery_phone: selectedJobSite?.phone,
    }
  }

  return (
    <IonPage>
      <Header
        mode={HeaderModes.Leaf}
        label="Pickup from"
        subLabel={task.supplier?.address}
        end={
          <div onClick={() => presentHelp()}>
            <Icon icon={helpCircleOutline} width={30} />
          </div>
        }
      />
      <Content>
        <DeliveryOnlyDetailsForm
          task={toJS(task)}
          onSubmit={handleDeliveryOnlyDetailsFormSubmit}
          timezone={userProfileStore.marketTimezone}
          jobSiteId={jobSiteId}
        />
      </Content>
    </IonPage>
  )
}

export default observer(DeliveryOnlyTaskDetailPage)
