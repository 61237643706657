import React from 'react'
import { observer } from 'mobx-react-lite'
import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react'
import { SegmentChangeEventDetail } from '@ionic/core/dist/types/interface'
import styled from 'styled-components'
import { TaskType, TaskTypes } from '@supplyhound/types'

export type TaskTypeFormValues = {
  task_type: TaskType
}

interface TaskTypeFormProps {
  taskType: TaskType
  onChange: (taskType: TaskType) => void
}

const StyledIonSegmentButton = styled(IonSegmentButton)`
  padding: 8px;

  &.segment-button-checked {
    --indicator-box-shadow: none;
    background-color: var(--ion-color-tertiary);
    color: var(--perma-dark-color);

    ::part(indicator-background) {
      background-color: var(--ion-color-tertiary);
    }
  }
  &::before {
    border: none;
  }
`

const StyledIonSegment = styled(IonSegment)`
  background-color: var(--greyscale-1);
  ion-app.dark & {
    color: unset;
  }

  .segment-button-checked {
    border: 3px solid var(--ion-color-primary);
    ion-app.dark & {
      color: var(--perma-dark-color);
    }
  }
`

const TaskTypeForm: React.FC<TaskTypeFormProps> = ({ taskType, onChange }) => {
  const handleIonChange = (e: CustomEvent<SegmentChangeEventDetail>) => {
    onChange(e.detail.value! as TaskType)
  }

  return (
    <StyledIonSegment scrollable mode="ios" value={taskType} onIonChange={handleIonChange}>
      <StyledIonSegmentButton value={TaskTypes.Delivery}>
        <IonLabel>Delivery</IonLabel>
      </StyledIonSegmentButton>

      <StyledIonSegmentButton value={TaskTypes.Pickup}>
        <IonLabel>Will Call</IonLabel>
      </StyledIonSegmentButton>
    </StyledIonSegment>
  )
}

export default observer(TaskTypeForm)
