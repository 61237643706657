import React from 'react'
import Card from '@supplyhound/components/common/Card'

import styled from 'styled-components'
import Icon from '@supplyhound/components/common/Icon'

import { HistoryTask } from '@supplyhound/types'
import { chevronForwardOutline } from 'ionicons/icons'
import { formatTaskType } from '@supplyhound/utils/history'
import { removeCountry } from '@supplyhound/utils/address'
import Ellipsis from '@supplyhound/components/common/text/Ellipsis'
import Caption from '@supplyhound/components/common/text/Caption'

const FlexContainer = styled.div`
  display: flex;
  cursor: pointer;
`

const FlexSpaceBetween = styled(FlexContainer)`
  justify-content: space-between;
  margin: calc(0.7 * var(--space-unit)) 0;
  align-items: center;
`

const FlexGrey = styled(FlexSpaceBetween)`
  color: var(--ion-color-medium);
`

const TaskStatus = styled.div<{ isComplete: boolean; isCancelled: boolean }>`
  background-color: var(--greyscale-2);
  border-radius: var(--ion-border-radius);
  padding: 5px 8px;
  color: ${({ isComplete, isCancelled }) => {
    if (isCancelled) return 'var(--ion-color-dark)'

    return isComplete ? 'var(--ion-color-success)' : 'var(--ion-color-anchor)'
  }};
`

const TaskContainer = styled(Card)`
  padding: var(--space-unit);
`
const LocationName = styled(Ellipsis)`
  font-weight: 500;
`

const Chevron = styled(Icon)`
  min-width: calc(var(--space-unit) * 1.6);
  color: var(--greyscale-4);
`

type TaskCardProps = {
  task: HistoryTask
  onClick?: () => void
}

const TaskCard = ({ task, onClick }: TaskCardProps) => {
  return (
    <TaskContainer
      key={task.id}
      onClick={() => {
        if (onClick) onClick()
      }}
    >
      <FlexGrey>
        <TaskStatus isComplete={task.task_complete} isCancelled={task.status === 'canceled'}>
          <Caption>{task.type === 'DeliveryTask' ? task.delivery_job_status : task.pickup_job_status}</Caption>
        </TaskStatus>
        <Caption>{new Date(task.created_at).toLocaleDateString('en-US')}</Caption>
      </FlexGrey>
      <FlexSpaceBetween>
        <LocationName text={task.store_name || removeCountry(task.pickup_address)} />
        <Chevron icon={chevronForwardOutline} />
      </FlexSpaceBetween>
      <FlexGrey>
        <Caption>{formatTaskType(task.type)}</Caption>
      </FlexGrey>
    </TaskContainer>
  )
}

export default TaskCard
