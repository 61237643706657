import React, { useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import CreditCardDisplay from '@supplyhound/components/CreditCardDisplay'
import BaseModal from '@supplyhound/components/common/Modals/BaseModal'
import ToggleableTabs, { ToggleableTab } from '@supplyhound/components/ToggleableTabs'
import Card from '@supplyhound/components/common/Card'
import Spacer from '@supplyhound/components/common/Spacer'
import BaseButton from '@supplyhound/components/buttons/BaseButton'
import useStores from '@supplyhound/hooks/useStores'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import { PaymentMethod } from '@supplyhound/types'
import { BillingPlanType, SubscriptionType } from './types'
import SuccessConfirmationCard from '@supplyhound/components/SuccessConfirmationCard'
import SubscriptionCard from './components/SubscriptionCard'
import SubscriptionStatus from './components/SubscriptionStatus'
import { subscriptionPlans, subscriptionPlanLookup } from './config'
import { COMPANY_ROUTE } from '@supplyhound/utils/config'

const Center = styled.div`
  display: flex;
  justify-content: center;
`

const SubscriptionCardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const ToggleableTabsContainer = styled.div`
  width: 400px;
  height: 60px;
`

const CreditCardContainer = styled(Card)`
  margin-bottom: 20px;
`

const Description = styled.p`
  color: var(--greyscale-6);
  font-size: 16px;
`

const tabs: ToggleableTab[] = [
  {
    name: 'monthly',
    label: 'Monthly',
  },
  {
    name: 'annually',
    label: 'Annually',
  },
]

const SubscriptionSignup: React.FC<{
  dismiss: () => void
  history: any
}> = ({ dismiss, history }) => {
  const {
    paymentMethodStore: { paymentMethods },
    userProfileStore: { subscriptionStore },
    userProfileStore,
  } = useStores()
  const { loadWhile } = useLoadingState()
  const [activeTab, setActiveTab] = useState<BillingPlanType>('annually')
  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState<SubscriptionType>('free')
  const [subscriptionUpgradeCompleted, setSubscriptionUpgradeCompleted] = useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | undefined>()

  return (
    <BaseModal label="Subscription" onDidDismiss={() => dismiss()}>
      {subscriptionUpgradeCompleted ? (
        <>
          <SuccessConfirmationCard>
            <div>
              <h3>You are now on the {_.capitalize(selectedSubscriptionType)} plan!</h3>
            </div>
          </SuccessConfirmationCard>
          <Spacer height={20} />
          <strong>Billing Plan</strong>
          <SubscriptionStatus subscriptionType={selectedSubscriptionType} billingPlanType={activeTab} />
          <Spacer height={20} />
          <h2>Payment Method</h2>
          {selectedPaymentMethod && (
            <CreditCardContainer>
              <CreditCardDisplay card={selectedPaymentMethod.card} editable={false} />
            </CreditCardContainer>
          )}
          <Description>
            Charged each {activeTab === 'monthly' ? 'month' : 'year'} on the subscription activation date.
          </Description>
          <Spacer height={20} />
          <h2>Next Step</h2>
          <p>Click on the button below to begin onboarding your team into your subscription.</p>
          <BaseButton
            onClick={() => {
              dismiss()
              history.push(COMPANY_ROUTE)
            }}
          >
            Invite Teammates
          </BaseButton>
        </>
      ) : (
        <>
          <strong>Current Billing Plan</strong>
          <SubscriptionStatus subscriptionType="free" billingPlanType="monthly" />
          <Spacer height={20} />
          <Center>
            <ToggleableTabsContainer>
              <ToggleableTabs
                activeTabName={activeTab}
                tabs={tabs}
                onToggle={name => {
                  setActiveTab(name as BillingPlanType)
                }}
              />
            </ToggleableTabsContainer>
          </Center>
          <Spacer height={20} />
          {selectedSubscriptionType !== 'free' ? (
            <Center>
              <SubscriptionCard
                billingPlanType={activeTab}
                planName={subscriptionPlanLookup[selectedSubscriptionType].planName}
                price={subscriptionPlanLookup[selectedSubscriptionType].price}
                bulletPoints={subscriptionPlanLookup[selectedSubscriptionType].bulletPoints}
                onChoose={() => {
                  setSelectedSubscriptionType('free')
                }}
                chooseButtonText="Change Plan"
                chooseButtonColor="tertiary"
              />
            </Center>
          ) : (
            <SubscriptionCardsContainer className="subscription-cards-container">
              {subscriptionPlans.map((subscriptionPlan, idx) => (
                <SubscriptionCard
                  key={idx}
                  billingPlanType={activeTab}
                  planName={subscriptionPlan.planName}
                  price={subscriptionPlan.price}
                  bulletPoints={subscriptionPlan.bulletPoints}
                  onChoose={planName => {
                    setSelectedSubscriptionType(planName)
                  }}
                  currentPlan={subscriptionPlan.planName === 'free'}
                />
              ))}
            </SubscriptionCardsContainer>
          )}
          <Spacer height={20} />
          <h2>{selectedPaymentMethod ? 'Selected' : 'Select'} Payment Method</h2>
          {!selectedPaymentMethod &&
            paymentMethods.map(payment => (
              <CreditCardContainer key={payment.id}>
                <CreditCardDisplay
                  card={payment.card}
                  pillText="Choose"
                  onClick={() => {
                    setSelectedPaymentMethod(payment)
                  }}
                />
              </CreditCardContainer>
            ))}
          {selectedPaymentMethod && (
            <CreditCardContainer>
              <CreditCardDisplay
                card={selectedPaymentMethod.card}
                pillText="Change"
                onClick={() => {
                  setSelectedPaymentMethod(undefined)
                }}
              />
            </CreditCardContainer>
          )}
          <BaseButton
            onClick={() =>
              loadWhile(async () => {
                await subscriptionStore.dispatchCreateSubscription({
                  subscriptionType: selectedSubscriptionType,
                  billingPlanType: activeTab,
                  stripePaymentMethodId: selectedPaymentMethod!.id,
                })
                await userProfileStore.dispatchLoadProfile()
              }).then(() => setSubscriptionUpgradeCompleted(true))
            }
            disabled={!selectedPaymentMethod || selectedSubscriptionType === 'free'}
          >
            Create Subscription
          </BaseButton>
        </>
      )}
      <Spacer height={20} />
    </BaseModal>
  )
}

export default observer(SubscriptionSignup)
