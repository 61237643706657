import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { IonLabel } from '@ionic/react'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import useStores from '@supplyhound/hooks/useStores'
import {
  OptionallyIdentifiedTeamMemberListItem,
  SelectableTeamMemberListItem,
  UnidentifiedTeamMemberListItem,
} from '@supplyhound/types'

import styled from 'styled-components'
import AddButton from '@supplyhound/components/buttons/AddButton'
import useModallyEditableItems from '@supplyhound/hooks/useModallyEditableItems'
import TeamMemberListItemModalForm, { FormModes } from '@supplyhound/forms/TeamMemberListItemModalForm'
import TeamMemberMaterialsList from '@supplyhound/components/TeamMemberMaterialsList'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import Card from '@supplyhound/components/common/Card'

interface Props {
  setPageSubHeading: (arg0: string) => void
  onSubmitItemsHandler: () => void
}

const SubmitContainer = styled.div`
  position: fixed;
  bottom: 0;
  padding-right: calc(4 * var(--space-unit));
  padding-bottom: calc(2 * var(--space-unit));
  width: 100%;
  z-index: 10;
  background: var(--ion-background-color);
`

const StyledCard = styled(Card)`
  width: 100%;
  height: 60px;
  margin: 0px;
  padding: 20px 0px;
  text-align: center;
  font-weight: 500;
`

const ActionRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: calc(2 * var(--space-unit));
`

const RequestedItemsTab: React.FC<Props> = ({ setPageSubHeading, onSubmitItemsHandler }) => {
  const {
    teamMemberProfileStore: { listStore },
  } = useStores()

  const { loadWhile } = useLoadingState()

  const { id } = useParams<{ id: string }>()
  const listId = parseInt(id)
  const list = listStore.lists[listId]

  const [selectableItems, setSelectableItems] = useState<SelectableTeamMemberListItem[]>([])

  useEffect(() => {
    if (listId) {
      loadWhile(() => listStore.dispatchFetchList(listId))
    }
  }, [listId])

  useEffect(() => {
    if (list?.activeItems) {
      setSelectableItems(list.activeItems.map(item => ({ ...item, selected: false })))
    }
  }, [list?.activeItems])

  const handleSubmit = async (item: OptionallyIdentifiedTeamMemberListItem): Promise<void> => {
    item.team_member_list_id = list.id
    return loadWhile(() => list.dispatchCreateItem(item as UnidentifiedTeamMemberListItem))
  }

  const submitItemsHandler = () => {
    loadWhile(() =>
      list
        .dispatchSubmitItems(selectableItems.filter(item => item.selected).map(item => item.id))
        .then(() => {
          setSelectableItems(
            list.activeItems.map(item => ({
              ...item,
              selected: false,
            }))
          )
        })
        .then(() => {
          onSubmitItemsHandler()
        })
    )
  }
  const { handleAddItem, handleEditItemSubmit, handleEditItemCancel, showModal } =
    useModallyEditableItems<OptionallyIdentifiedTeamMemberListItem>({
      items: selectableItems,
      onSubmit: handleSubmit,
    })
  const selectedItemCount = selectableItems.filter(selectableItem => selectableItem.selected).length

  useEffect(() => {
    setPageSubHeading(
      selectedItemCount > 0
        ? `${selectedItemCount}/${selectableItems.length} items selected to submit`
        : 'Add an item to build your list'
    )
  }, [selectedItemCount])

  return (
    <>
      <TeamMemberMaterialsList
        listId={list?.id}
        items={selectableItems}
        onCheckboxClick={item => {
          setSelectableItems(
            selectableItems.map(selectableItem =>
              item.id === selectableItem.id ? { ...selectableItem, selected: item.selected } : selectableItem
            )
          )
        }}
        onCheckboxControlClick={selected => {
          setSelectableItems(
            selectableItems.map(selectableItem => ({
              ...selectableItem,
              selected,
            }))
          )
        }}
        setSelectableItems={setSelectableItems}
      />

      <ActionRow>
        <AddButton onClick={handleAddItem}>
          <IonLabel>Add item</IonLabel>
        </AddButton>
      </ActionRow>

      {list && (
        <TeamMemberListItemModalForm
          isOpen={showModal}
          onSubmit={createdItem => {
            return handleEditItemSubmit(createdItem).then(createdItemId => {
              const item = list.activeItems.find(item => item.id === createdItemId)
              setSelectableItems([...selectableItems, { ...item!, selected: false }])
            })
          }}
          onCancel={handleEditItemCancel}
          mode={FormModes.Add}
          headerSubtitle={list.jobSite.name}
        />
      )}
      <SubmitContainer>
        {selectedItemCount > 0 ? (
          <SubmitButton expand="block" color="primary" onClick={submitItemsHandler}>
            SUBMIT
          </SubmitButton>
        ) : (
          <StyledCard>Check items to submit them</StyledCard>
        )}
      </SubmitContainer>
    </>
  )
}

export default observer(RequestedItemsTab)
