import React from 'react'
import { observer } from 'mobx-react-lite'
import { IonList } from '@ionic/react'
import { Contact } from '@supplyhound/types'
import styled from 'styled-components'
import ContactItem from '@supplyhound/components/ContactItem'

interface Props {
  loadContacts: () => void
  showAddRow: boolean
  contacts: Contact[]
}

const StyledList = styled(IonList)`
  border: 1px solid var(--ion-border-color);
  border-radius: 8px;
  background-color: var(--ion-color-light);
  padding: 0 0;
  overflow: hidden;

  ion-item:last-child {
    --border-width: 0;
  }
`

const ContactsList: React.FC<Props> = ({ loadContacts, showAddRow = true, contacts }) => {
  return (
    <>
      <StyledList lines="full">
        {contacts.map(contact => {
          return (
            <ContactItem
              contact={contact}
              key={contact.id}
              loadContacts={() => {
                loadContacts()
              }}
            />
          )
        })}
        {showAddRow && (
          <ContactItem
            addContact
            loadContacts={() => {
              loadContacts()
            }}
          />
        )}
      </StyledList>
    </>
  )
}

export default observer(ContactsList)
