import React, { useEffect, useState } from 'react'
import { IonPage, useIonModal } from '@ionic/react'
import { Header, HeaderModes } from '@supplyhound/layout'

import Content from '@supplyhound/components/Content'
import { observer } from 'mobx-react-lite'
import Pill from '@supplyhound/components/common/Pill'
import styled from 'styled-components'
import { Contact, toEnumType } from '@supplyhound/types'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import Card from '@supplyhound/components/common/Card'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import { AddEditContactModal } from '@supplyhound/modals'
import { useHistory } from 'react-router-dom'
import Icon from '@supplyhound/components/common/Icon'
import { peopleOutline } from 'ionicons/icons'
import Spacer from '@supplyhound/components/common/Spacer'
import { fetchContacts } from '@supplyhound/api'
import ContactsList from '@supplyhound/components/ContactsList'
import { MANAGE_CONTACTS_ROUTE } from '@supplyhound/utils/config'

export const statues = {
  current: 'active',
  archived: 'archived',
} as const

export type Status = toEnumType<typeof statues>

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  overflow: scroll;
  margin-bottom: calc(2 * var(--space-unit));
`

const StyledEmptyCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  height: 370px;
  padding: 20px;
`

const StyledTextContainer = styled.div`
  flex: 50%;
  text-align: center;
`

const ManageContactsPage: React.FC = () => {
  const history = useHistory()
  const [filterState, setFilterState] = useState<Status>(statues.current)
  const [contacts, setContacts] = useState<Contact[]>([])

  const { loadWhile } = useLoadingState()
  const loadContacts = () => {
    loadWhile(() =>
      fetchContacts(filterState === 'archived').then(data => {
        setContacts(data)
      })
    )
  }
  useEffect(loadContacts, [filterState])

  const [present, dismiss] = useIonModal(AddEditContactModal, {
    dismiss: () => dismiss(),
    onSubmit: () => history.push(MANAGE_CONTACTS_ROUTE),
    loadContacts: loadContacts,
    mode: 'create',
  })

  return (
    <IonPage>
      <Header mode={HeaderModes.Root} label="Manage Contacts" />
      <Content>
        <FilterContainer>
          <Pill
            label="Current"
            isSelected={filterState === statues.current}
            onClick={() => {
              setFilterState(statues.current)
            }}
          />
          <Pill
            label="Archived"
            isSelected={filterState === statues.archived}
            onClick={() => {
              setFilterState(statues.archived)
            }}
          />
        </FilterContainer>
        {contacts.length ? (
          <ContactsList loadContacts={loadContacts} showAddRow={filterState === 'active'} contacts={contacts} />
        ) : (
          <>
            <StyledEmptyCard>
              <StyledTextContainer>
                <Spacer height={60} />
                <Icon icon={peopleOutline} width={50} />
                <p>All your {filterState === statues.current ? 'current' : 'archived'} contacts will appear here.</p>
              </StyledTextContainer>
              {filterState === 'active' && <SubmitButton onClick={() => present()}>Add a new contact</SubmitButton>}
            </StyledEmptyCard>
          </>
        )}
      </Content>
    </IonPage>
  )
}

export default observer(ManageContactsPage)
