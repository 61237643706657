import React from 'react'
import styled from 'styled-components'
import { checkmarkCircleOutline } from 'ionicons/icons'
import Icon from '@supplyhound/components/common/Icon'
import Card from '@supplyhound/components/common/Card'

const SuccessCard = styled(Card)`
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  border-color: var(--ion-color-success);
  margin-bottom: 20px;
`

const StyledCheckmark = styled(Icon)`
  padding-top: 3px;
  margin-right: 10px;
  color: var(--ion-color-success);
`

const SuccessfulConfirmationCard = ({ children }: { children: React.ReactNode }) => {
  return (
    <SuccessCard>
      <StyledCheckmark icon={checkmarkCircleOutline} width={50} />
      {children}
    </SuccessCard>
  )
}

export default SuccessfulConfirmationCard
