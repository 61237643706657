import { axiosInstance } from './axios'

// This will be replaced in the new front end app with the Voucherify JS SDK validate functionality.
export const validatePromoCode = (
  promo_code: string | undefined,
  total: number,
  ordered_directly: boolean,
  supplier_id: number | undefined
) =>
  axiosInstance
    .post('/v1/promo_code/validate', {
      promo_code: promo_code,
      fare: total,
      ordered_directly: ordered_directly,
      supplier_id: supplier_id,
    })
    .then(({ data }) => data)
