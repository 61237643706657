import React, { useEffect } from 'react'
import { IonPage } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import Content from '@supplyhound/components/Content'
import { Header, HeaderModes } from '@supplyhound/layout'

import useLoadingState from '@supplyhound/hooks/useLoadingState'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@supplyhound/hooks'
import { acceptInvite } from '@supplyhound/api'

const AcceptInvitePage: React.FC = () => {
  const { loadWhile } = useLoadingState()
  const history = useHistory()

  const { query } = useQuery<{ email: string; invite_token: string }>()
  const { email, invite_token } = query

  useEffect(() => {
    loadWhile(async () => {
      await acceptInvite({ email, invite_token }).then(({ data }) => {
        const { redirect } = data
        history.replace(redirect)
      })
    })
  }, [])

  return (
    <IonPage>
      <Header mode={HeaderModes.Root} />
      <Content>
        <div></div>
      </Content>
    </IonPage>
  )
}

export default observer(AcceptInvitePage)
