import React from 'react'
import styled from 'styled-components'
import { IonRow, IonButton, IonToast } from '@ionic/react'
import Icon from '@supplyhound/components/common/Icon'
import { Field, withFormik, FormikBag, FormikProps } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import TextInputField from './fields/TextInputField'
import ToggleButton from '@supplyhound/components/buttons/ToggleButton'
import OnOffLabel from '@supplyhound/components/common/OnOffLabel'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import { checkmarkCircleOutline, helpCircleOutline, lockClosedOutline } from 'ionicons/icons'
import { TeamMember, Modes, OptionallyIdentifiedTeamMember, PageMode } from '@supplyhound/types'
import { DEFAULT_TEAM_MEMBER } from '@supplyhound/utils/config'
import IconPopover from '@supplyhound/components/IconPopover'
import useHandlePressEnter from '@supplyhound/hooks/useHandlePressEnter'
import useStores from '@supplyhound/hooks/useStores'

interface TeamMemberAddEditFormProps {
  teamMember?: TeamMember
  mode: PageMode
  reminderOn: boolean
  team_id: number
  setReminderOn: () => void
  onSubmit: (teamMember: OptionallyIdentifiedTeamMember) => Promise<void>
  handleRemoveClick: () => void
}

const StyledButton = styled(SubmitButton)`
  margin-bottom: 20px;
`
const CheckIcon = styled(Icon)`
  margin-right: var(--space-unit);
`

const StyledCard = styled.div<{ reminderOn: boolean }>`
  border: var(--thick-dark-border);
  border-radius: var(--ion-border-radius);
  margin: calc(1.5 * var(--space-unit)) 0px;
  background-color: var(--greyscale-1);
  border-color: ${props => (props.reminderOn ? 'var(--ion-color-success)' : 'var(--ion-border-color)')};
`

const MaterialRequests = styled.div`
  margin: 0px 26px;
`

const Wrapper = styled.div`
  margin: 30px 20px 40px 20px;
  margin: calc(3 * var(--space-unit)) calc(2 * var(--space-unit)) calc(4 * var(--space-unit))
    calc(2 * var(--space-unit));
`
const RemoveTeamMemberButton = styled(IonButton)`
  --color: var(--ion-color-danger);
  margin-top: calc(4 * var(--space-unit));
`
const Row = styled(IonRow)`
  margin-top: 30px;
  margin-bottom: 15px;
`
const HorizontalRule = styled.hr<{ reminderOn: boolean }>`
  width: 100%;
  height: 0px;
  border-top: var(--thick-dark-border);
  border-color: ${props => (props.reminderOn ? 'var(--ion-color-success)' : 'var(--ion-border-color)')};
  margin: 0px;
`

const StyledField = styled(Field)`
  margin-top: calc(3 * var(--space-unit));
`
const StyledToggle = styled(ToggleButton)`
  margin-left: auto;
  border: none;
`
const Text = styled.h3`
  margin-bottom: 15px;
`

const StyledOnOffLabel = styled(OnOffLabel)`
  margin-top: 15px;
`

const StyledToast = styled(IonToast)`
  --height: 67px;
  --width: calc(100% - ${props => props.theme.contentGutters} - ${props => props.theme.contentGutters});
  --max-width: calc(1024px - ${props => props.theme.contentGutters} - ${props => props.theme.contentGutters});
  ::part(container) {
    color: var(--ion-color-dark);
    border-radius: var(--ion-border-radius);
    border: 1px solid var(--ion-color-dark);
  }
  ::part(button) {
    color: var(--ion-color-dark);
  }
`

const StyledToggleHelper = styled(IconPopover)`
  margin-left: auto;
`

const TeamMemberAddEditForm: React.FC<TeamMemberAddEditFormProps & FormikProps<OptionallyIdentifiedTeamMember>> = ({
  submitForm,
  mode,
  reminderOn,
  setReminderOn,
  handleRemoveClick,
  status,
  setStatus,
}) => {
  const {
    userProfileStore: { profile },
  } = useStores()
  const isReminderPaused = _.isEqual(profile?.team.schedule_days!, [])
  const reminderOnDisplay = !isReminderPaused && reminderOn
  useHandlePressEnter(submitForm, false)
  return (
    <>
      <StyledButton size="large" expand="block" onClick={submitForm}>
        <CheckIcon icon={checkmarkCircleOutline} size="large" />
        {mode === Modes.Add ? 'Add team member' : 'Finish editing team members'}
      </StyledButton>
      <StyledCard reminderOn={reminderOnDisplay}>
        <MaterialRequests>
          <Row>
            <div>
              <Text>Material reminders</Text>
              <StyledOnOffLabel on={reminderOnDisplay} onLabel="ON" offLabel={isReminderPaused ? 'PAUSED' : 'OFF'} />
            </div>
            {isReminderPaused ? (
              <StyledToggleHelper
                icon={helpCircleOutline}
                content="To change reminder settings for individual team members, please unpause reminders for your team."
                id="toggleHelp"
              />
            ) : (
              <Field
                name="should_receive_daily_reminder"
                component={StyledToggle}
                onToggle={setReminderOn}
                checked={reminderOn}
              />
            )}
          </Row>
        </MaterialRequests>
        <HorizontalRule reminderOn={reminderOnDisplay} />
        <Wrapper>
          <StyledField
            name="first_name"
            label="First name"
            placeholder="Team member first name"
            component={TextInputField}
            type="text"
          />
          <StyledField
            name="last_name"
            label="Last name"
            placeholder="Team member last name"
            component={TextInputField}
            type="text"
          />
          <StyledField
            name="phone"
            label="Phone number"
            placeholder="xxx-xxx-xxxx"
            component={TextInputField}
            type="tel"
            mask="999-999-9999"
            disabled={mode === Modes.Edit}
            extraContent={
              mode === Modes.Edit && (
                <IconPopover
                  icon={lockClosedOutline}
                  content="Phone number for team members cannot be changed. Please create a new team member instead."
                  id="phoneFieldHelp"
                />
              )
            }
          />
          {mode === Modes.Edit && (
            <RemoveTeamMemberButton fill="clear" expand="block" onClick={handleRemoveClick}>
              Remove team member
            </RemoveTeamMemberButton>
          )}
        </Wrapper>
      </StyledCard>
      {status && (
        <StyledToast
          isOpen={!!status}
          message={status}
          position="bottom"
          duration={3000}
          onDidDismiss={() => setStatus(null)}
        />
      )}
    </>
  )
}

export default withFormik<TeamMemberAddEditFormProps, OptionallyIdentifiedTeamMember>({
  displayName: 'TeamMemberAddEditForm',
  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    first_name: Yup.string().required('Enter at least one character'),
    last_name: Yup.string().required('Enter at least one character'),
    phone: Yup.string()
      .test('Length', 'Enter a 10 digit phone number', (val = '') => val?.replace(/\D/g, '').length === 10)
      .required('Enter a 10 digit phone number'),
    should_receive_daily_reminder: Yup.boolean().required('Required').oneOf([true, false], 'Required'),
  }),
  mapPropsToValues({ teamMember, team_id }: TeamMemberAddEditFormProps): OptionallyIdentifiedTeamMember {
    return teamMember ?? { ...DEFAULT_TEAM_MEMBER, team_id: team_id }
  },
  async handleSubmit(
    values: OptionallyIdentifiedTeamMember,
    { props: { onSubmit }, setErrors, setStatus }: FormikBag<TeamMemberAddEditFormProps, OptionallyIdentifiedTeamMember>
  ) {
    onSubmit(values).catch(e => {
      const phone = e.response.data.phone
      const errors = { phone: '' }
      if (phone) {
        if (phone[0] === 'is invalid') {
          errors.phone = 'This phone number is not valid'
        } else {
          errors.phone = 'This phone number is already taken'
        }
      } else {
        setStatus('Oops, something went wrong')
      }
      setErrors(errors)
    })
  },
})(TeamMemberAddEditForm)
