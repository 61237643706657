import { AxiosError } from 'axios'
import capitalize from 'lodash/capitalize'

export type ApiErrorSerializer = (e: AxiosError) => string

const DEFAULT_ERROR_MESSAGE = 'Server Error.'

/**
 * Map API form errors to informative
 * messages for the user.
 * @param e AxiosError<Record<string, string[]>>
 * @returns An error message
 */
export const serializeApiFieldError: ApiErrorSerializer = (e: AxiosError<Record<string, string[]>>): string => {
  const { data } = e.response!

  if (!data) return DEFAULT_ERROR_MESSAGE

  return Object.keys(data)
    .map((field: string) => `${capitalize(field)} ${data[field][0]}`)
    .join('<br />')
}

/**
 * Turn an API error into an informative
 * message for the user.
 * @param e AxiosError<Record<string, string>>
 * @returns An error message
 */
export const serializeApiError: ApiErrorSerializer = (e: AxiosError<Record<string, string>>): string => {
  const { data, statusText } = e.response!

  if (data && data.message) return data.message
  else if (statusText) return statusText

  return DEFAULT_ERROR_MESSAGE
}
