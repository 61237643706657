import React from 'react'
import { FieldArray, FormikHelpers, FormikErrors } from 'formik'
import { ID, SelectableOrdererListItem } from '@supplyhound/types'
import ErrorLabel from '@supplyhound/components/common/ErrorLabel'
import MaterialsList from '@supplyhound/components/MaterialsList'
import { PickupDetailsFormValues } from '@supplyhound/forms/DetailsForm/PickUpDetailsForm'
import { DeliveryDetailsFormValues } from '@supplyhound/forms/DetailsForm/DeliveryDetailsForm'

const MaterialListField = <FormValues extends DeliveryDetailsFormValues | PickupDetailsFormValues>({
  jobSiteId,
  taskItems,
  setFieldValue,
  errors,
}: {
  jobSiteId: ID
  taskItems: SelectableOrdererListItem[]
  setFieldValue: FormikHelpers<FormValues>['setFieldValue']
  errors: FormikErrors<FormValues>
}) => {
  return (
    <>
      <FieldArray
        name="items"
        render={() => (
          <MaterialsList
            jobSiteId={jobSiteId}
            items={taskItems}
            onCheckboxClick={(item, index) => {
              setFieldValue(`taskItems[${index}].selected`, item.selected)
            }}
            onCheckboxControlClick={isCheckboxOn => {
              setFieldValue(
                'taskItems',
                taskItems.map(taskItem => ({
                  ...taskItem,
                  selected: isCheckboxOn,
                }))
              )
            }}
          />
        )}
      />
      {typeof errors.taskItems === 'string' && <ErrorLabel>{errors.taskItems}</ErrorLabel>}
    </>
  )
}

export default MaterialListField
