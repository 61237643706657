import React, { useEffect, useState } from 'react'
import { IonButton, IonPage, useIonModal } from '@ionic/react'
import { Header, HeaderModes } from '@supplyhound/layout'

import Content from '@supplyhound/components/Content'
import { observer } from 'mobx-react-lite'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { JobSiteStatuses } from '@supplyhound/types'
import useStores from '@supplyhound/hooks/useStores'
import Card from '@supplyhound/components/common/Card'
import { archiveOutline, checkmarkCircle } from 'ionicons/icons'
import { JOB_SITE_LIST_DETAIL_ROUTE } from '@supplyhound/utils/config'
import styled from 'styled-components'
import Icon from '@supplyhound/components/common/Icon'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import ActionModal from '@supplyhound/components/common/Modals/ActionModal'
import { AddEditJobSiteModal } from '@supplyhound/modals'
import OvalButton from '@supplyhound/components/buttons/OvalButton'
import Spacer from '@supplyhound/components/common/Spacer'
import NotificationBanner from '@supplyhound/components/NotificationBanner'
import HistoryOrders from 'pages/History/HistoryOrders'
import Caption from '@supplyhound/components/common/text/Caption'

type ActiveProp = { isActive: boolean }

const StyledCard = styled(Card)`
  border: 1px solid var(--greyscale-3);
  border-bottom: 0;
  padding: 20px;
  margin-bottom: 0px;
  border-radius: var(--ion-border-radius) var(--ion-border-radius) 0 0;
`

const CreatedContainer = styled.div`
  font-size: 0.875rem;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const CreatedHeader = styled.span`
  color: var(--ion-color-dark);
`

const CreatedDate = styled.span`
  color: var(--greyscale-5);
`

const StyledButtonContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
`

const StyledOvalButton = styled(OvalButton)`
  flex: 50%;
`

const StyledArchiveContainer = styled.div<ActiveProp>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50px;
  background-color: ${props => (props.isActive ? 'var(--greyscale-3)' : 'var(--greyscale-1)')};
  border: 1px solid var(--greyscale-3);
  border-radius: 0 0 var(--ion-border-radius) var(--ion-border-radius);
`

const StyledIonButton = styled(IonButton)`
  --color: var(--ion-color-dark);
`

const StyledArchiveIcon = styled(Icon)`
  margin-right: 5px;
`

const StyledCaption = styled(Caption)`
  color: var(--ion-color-medium);
`

const ManageJobSitesDetails: React.FC = () => {
  const history = useHistory()
  const { jobSitesStore } = useStores()
  const { id } = useParams()
  const jobSiteId = parseInt(id)
  const jobSite = jobSitesStore.jobSites[jobSiteId]
  const [showBanner, setShowBanner] = useState(false)

  const isActive = jobSite?.status === JobSiteStatuses.current

  const { loadWhile } = useLoadingState()
  useEffect(() => {
    if (!jobSite) {
      loadWhile(jobSitesStore.dispatchFetchJobSites)
    }
  }, [])

  const handleArchive = () => {
    jobSitesStore.dispatchUpdateJobSiteStatus(jobSiteId, JobSiteStatuses.archived).then(() => dismissArchiveModal())
  }

  const [presentArchiveModal, dismissArchiveModal] = useIonModal(ActionModal, {
    icon: archiveOutline,
    iconColor: 'dark',
    title: `Archive jobsite ${jobSite?.name}`,
    subtext:
      'If you are no longer working on this jobsite, archiving will remove it from your list of active jobsites.',
    onDismiss: () => dismissArchiveModal(),
    ActionButtons: <SubmitButton onClick={handleArchive}>Archive this jobsite</SubmitButton>,
  })

  const [presentJobSiteModal, dismissJobSiteModal] = useIonModal(AddEditJobSiteModal, {
    dismiss: () => dismissJobSiteModal(),
    onSubmit: () => {
      setShowBanner(true)
      dismissArchiveModal()
    },
    jobSite: jobSite,
  })

  return (
    <IonPage>
      <Header mode={HeaderModes.Leaf} label="View Jobsite" />
      <Content>
        <StyledCard>
          <h2>{jobSite?.name}</h2>
          <StyledCaption>{jobSite?.formatted_address}</StyledCaption>
          <CreatedContainer>
            <div>
              <CreatedHeader>Created:</CreatedHeader> <CreatedDate>{jobSite?.created_date}</CreatedDate>
            </div>
            {!isActive && (
              <div>
                <CreatedHeader>Archived:</CreatedHeader> <CreatedDate>{jobSite?.archived_date}</CreatedDate>
              </div>
            )}
          </CreatedContainer>

          {isActive && (
            <StyledButtonContainer>
              <StyledOvalButton
                color="tertiary"
                onClick={() => {
                  presentJobSiteModal()
                }}
              >
                Edit
              </StyledOvalButton>
              <Spacer width={15} />
              <StyledOvalButton
                color="tertiary"
                onClick={() => history.push(generatePath(JOB_SITE_LIST_DETAIL_ROUTE, { id: jobSiteId }))}
              >
                Go to item list
              </StyledOvalButton>
            </StyledButtonContainer>
          )}
        </StyledCard>
        <StyledArchiveContainer isActive={isActive}>
          <StyledIonButton
            size="small"
            expand="block"
            fill="clear"
            disabled={!isActive}
            onClick={() => presentArchiveModal()}
          >
            {isActive ? (
              <>
                <StyledArchiveIcon icon={archiveOutline} />
                Archive this jobsite
              </>
            ) : (
              <>
                <StyledArchiveIcon icon={checkmarkCircle} />
                Archived
              </>
            )}
          </StyledIonButton>
        </StyledArchiveContainer>
        <HistoryOrders jobSiteId={jobSiteId} />
        {showBanner && <NotificationBanner message="Jobsite saved" onDidDismiss={() => setShowBanner(false)} />}
      </Content>
    </IonPage>
  )
}

export default observer(ManageJobSitesDetails)
