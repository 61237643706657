import { Field, FieldProps, getIn } from 'formik'
import React, { useState } from 'react'
import { chain } from 'lodash'
import useGoogleAutocompleteService, { Prediction } from '@supplyhound/hooks/useGoogleAutocompleteService'
import SearchField from './SearchField'
import { SupplierSearchResult } from '@supplyhound/types'
import styled from 'styled-components'
import { IonLabel } from '@ionic/react'
import ErrorLabel from '@supplyhound/components/common/ErrorLabel'
import FieldHeader from './FieldHeader'
import { parseAddress } from '@supplyhound/utils/address'

const AddressLabel = styled(IonLabel)`
  max-width: unset !important;
  font-size: 16px;
`

interface SupplierSearchFieldProps {
  label: string
}

const SupplierSearchField = (props: SupplierSearchFieldProps) => {
  const label = props.label
  const { search: googleSearch } = useGoogleAutocompleteService()
  const [results, setResults] = useState<SupplierSearchResult[]>([])

  const SearchResultsFactory = (predictions: Prediction[]): SupplierSearchResult[] =>
    // @ts-ignore
    chain(predictions).sortBy('supplier_id').compact().value()

  const search = (input: string) =>
    googleSearch(input).then(predictions => {
      setResults(SearchResultsFactory(predictions))
    })
  return (
    <Field
      name="supplier"
      children={(props: FieldProps<Partial<SupplierSearchResult>>) => {
        const form = props.form
        const { name, value } = props.field
        const controlText = getIn(value, 'displayText') ?? getIn(value, 'name')
        const error = getIn(form.errors, `${name}.address`)
        const touched = getIn(form.touched, name)

        return (
          <>
            <FieldHeader label={label} />
            <SearchField
              {...props}
              controlText={controlText}
              onResultSelect={(result: SupplierSearchResult) => {
                form.setFieldValue(name, result)
              }}
              renderResult={result => (
                <>
                  <h3>{result.name}</h3>
                  <AddressLabel>{parseAddress(result.address)}</AddressLabel>
                </>
              )}
              results={results}
              search={search}
              placeholder="Enter and select supplier name or address"
              isSearchFieldInvalid={error && touched}
            />
            {error && touched && <ErrorLabel>{error}</ErrorLabel>}
          </>
        )
      }}
    />
  )
}

export default SupplierSearchField
