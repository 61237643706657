import React from 'react'
import { useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { IonPage } from '@ionic/react'
import Content from '@supplyhound/components/Content'
import Spacer from '@supplyhound/components/common/Spacer'
import { JOB_SITE_LIST_DETAIL_ROUTE } from '@supplyhound/utils/config'
import { FileWithUrl, JobSiteStatuses } from '@supplyhound/types'
import { Header, HeaderModes } from '@supplyhound/layout'
import JobSiteList from '@supplyhound/components/JobSiteList'

const JobSiteAddItemPage: React.FC = () => {
  const location = useLocation<{
    item: {
      description: string
      quantity: number
      image?: FileWithUrl
    }
  }>()
  const hasItemToAddToList = !!location.state?.item

  return (
    <IonPage>
      <Header mode={HeaderModes.Leaf} label="Choose jobsite" />
      <Content>
        <h1>
          <b>Which jobsite would you like to {hasItemToAddToList ? 'add this item to' : 'build a list for'}?</b>
        </h1>
        <Spacer height={30} />
        <JobSiteList
          jobSiteClickRoute={JOB_SITE_LIST_DETAIL_ROUTE}
          jobSiteAfterCreateRoute={JOB_SITE_LIST_DETAIL_ROUTE}
          statusFilter={JobSiteStatuses.current}
        />
      </Content>
    </IonPage>
  )
}

export default observer(JobSiteAddItemPage)
