import React, { useEffect } from 'react'
import styled from 'styled-components'
import { generatePath, useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { IonPage, IonList } from '@ionic/react'
import Icon from '@supplyhound/components/common/Icon'

import useStores from '@supplyhound/hooks/useStores'
import Content from '@supplyhound/components/Content'
import JobSiteItem from '@supplyhound/components/JobSiteItem'
import Spacer from '@supplyhound/components/common/Spacer'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import { TEAM_MEMBER_LIST_ROUTE } from '@supplyhound/utils/config'
import { Header, HeaderModes } from '@supplyhound/layout'

import { alertCircleOutline } from 'ionicons/icons'

const StyledList = styled(IonList)`
  border: 1px solid var(--ion-border-color);
  border-radius: var(--ion-border-radius);
  background-color: var(--ion-color-light);
  padding: 0 0;
  overflow: hidden;

  ion-item:last-child {
    --border-width: 0;
  }
`

const StyledContainer = styled.div`
  padding: 0 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledIcon = styled(Icon)`
  font-size: 4rem;
  align-self: center;
`

const StyledBoldText = styled.p`
  font-size: 1.75rem;
  font-weight: 500;
  text-align: center;
  margin: 30px 0 15px 0;
`

const StyledSubText = styled.p`
  font-size: 1.25rem;
  text-align: center;
  margin-top: 0px;
`

const StyledSpanSpacer = styled.span`
  height: 15%;
`

const TeamMemberJobSitePage: React.FC = () => {
  const { loadWhile } = useLoadingState()
  const history = useHistory()
  const {
    teamMemberProfileStore: { listStore },
  } = useStores()
  const lists = Object.values(listStore.lists)

  useEffect(() => {
    loadWhile(listStore.dispatchFetchLists)
  }, [])

  return (
    <IonPage>
      <Header mode={HeaderModes.Root} label="Choose jobsite" />
      <Content>
        {lists.length > 0 ? (
          <>
            <h1>
              <b>Which jobsite would you like to build a list for?</b>
            </h1>
            <Spacer height={20} />
            <StyledList lines="full">
              {lists.map(list => (
                <JobSiteItem
                  key={list.jobSite.id}
                  label={list.jobSite.nickname || list.jobSite.formatted_address}
                  labelSubText={`${list.active_items_count} items`}
                  onClick={() => {
                    history.push(generatePath(TEAM_MEMBER_LIST_ROUTE, { id: list.id }))
                  }}
                />
              ))}
            </StyledList>
          </>
        ) : (
          <StyledContainer>
            <StyledIcon icon={alertCircleOutline} width={50} />
            <StyledBoldText>Your foreman hasn't added any jobsites yet</StyledBoldText>
            <StyledSubText>Please ask your foreman to add your jobsite(s) so you can request materials</StyledSubText>
            <StyledSpanSpacer />
          </StyledContainer>
        )}
      </Content>
    </IonPage>
  )
}

export default observer(TeamMemberJobSitePage)
