import React from 'react'
import styled from 'styled-components'
import clsx from 'clsx'
import { useIonModal } from '@ionic/react'
import { chevronForwardOutline, addOutline } from 'ionicons/icons'
import ListItem from '@supplyhound/components/common/ListItem'
import { AddEditContactModal } from '@supplyhound/modals'
import { Contact } from '@supplyhound/types'
import Ellipsis from './common/text/Ellipsis'
import Spacer from './common/Spacer'

const StyledItem = styled(ListItem)`
  --background: var(--ion-color-light);

  &.add-job-site-item ion-label,
  &.add-job-site-item::part(native) {
    padding: 10px 0px;
  }
`

const RowLabel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Details = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Contactname = styled.div``
const Contactbuttons = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const StyledLabel = styled.span`
  font-size: 1.125rem;
  font-weight: 500;
`

const StyledSubText = styled.span`
  color: var(--ion-color-medium);
  font-size: 1rem;
`

const ContactButton = styled.button`
  width: 100px;
  padding: 5px 0;
  margin: 5px 0;
  background-color: var(--ion-color-primary);
  border-radius: var(--ion-border-radius);
  cursor: pointer;
`

type JobSiteItemProps = {
  addContact?: boolean
  loadContacts?: () => void
  contact?: Contact
}

const ContactItem = ({ addContact = false, loadContacts, contact }: JobSiteItemProps) => {
  const [present, dismiss] = useIonModal(AddEditContactModal, {
    dismiss: () => dismiss(),
    loadContacts: loadContacts,
    contact: contact,
    mode: 'edit',
  })

  if (addContact) {
    return (
      <StyledItem
        className={clsx({
          'add-job-site-item': addContact,
        })}
        onClick={() => present()}
        detail
        button
        detailIcon={addOutline}
      >
        <RowLabel>{'Add a new contact'}</RowLabel>
      </StyledItem>
    )
  }
  const contactName = `${contact!.first_name} ${contact!.last_name}`
  return (
    <StyledItem onClick={() => present()} detail button detailIcon={chevronForwardOutline}>
      <RowLabel>
        <StyledLabel>
          <Ellipsis text={contact!.supplier_name} />
        </StyledLabel>
        <Details>
          <Contactname>
            <StyledSubText>{contactName}</StyledSubText>
          </Contactname>
        </Details>

        <Spacer height={4} />
        <Contactbuttons>
          <a href={`tel:${contact!.phone}`} onClick={e => e.stopPropagation()}>
            <ContactButton>Call</ContactButton>
          </a>
          <Spacer width={12} />
          <a href={`sms:${contact!.mobile}`} onClick={e => e.stopPropagation()}>
            <ContactButton>Text</ContactButton>
          </a>
          <Spacer width={12} />

          <a href={`mailto:${contact!.email}`} onClick={e => e.stopPropagation()}>
            <ContactButton>Email</ContactButton>
          </a>
          <Spacer width={30} />
        </Contactbuttons>
      </RowLabel>
    </StyledItem>
  )
}

export default ContactItem
