import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import useStores from '@supplyhound/hooks/useStores'
import { trackReminderDecline } from '@supplyhound/api'
import BaseButton from '@supplyhound/components/buttons/BaseButton'
import { TEAM_MEMBER_HOME_ROUTE } from '@supplyhound/utils/config'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: auto 20px;
  flex-direction: column;
`

const Text = styled.p`
  font-size: 1.125rem;
  text-align: center;
  line-height: 1.625rem;
`

const TeamMemberReminderDeclinePage: React.FC = () => {
  const params = new URLSearchParams(window.location.search)
  const phone = params.get('phone')
  const history = useHistory()

  const { teamMemberProfileStore } = useStores()

  useEffect(() => {
    if (phone) {
      trackReminderDecline(phone)
    }
  }, [])

  return (
    <Container>
      <Text>Thank you. Your response has been submitted.</Text>
      <BaseButton
        onClick={() => {
          history.replace(TEAM_MEMBER_HOME_ROUTE)
        }}
        size="small"
      >
        {teamMemberProfileStore.authStore.isAuthenticated ? 'Back to my lists' : 'Login'}
      </BaseButton>
    </Container>
  )
}

export default observer(TeamMemberReminderDeclinePage)
