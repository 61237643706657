import { SubscriptionType, BulletPointsType } from './types'

export const BILLING_CADENCE = {
  monthly: 'monthly',
  annually: 'annually',
}

export const PLANS: { free: 'free'; pro: 'pro'; power: 'power' } = {
  free: 'free',
  pro: 'pro',
  power: 'power',
}

export const subscriptionPlanLookup = {
  [PLANS.free]: {
    planName: PLANS.free,
    price: {
      monthly: 0,
      annually: 0,
    },
    bulletPoints: [
      { description: '1 Authorized orderer' },
      { description: '2 Team members' },
      { description: '2 Active job sites' },
      { description: '$15/order booking fee' },
    ],
  },
  [PLANS.pro]: {
    planName: PLANS.pro,
    price: {
      monthly: 29,
      annually: 25,
    },
    bulletPoints: [
      { description: '2 Authorized orderers' },
      { description: '8 Team members' },
      { description: '5 Active job sites' },
      { description: 'No order fees' },
      { description: '4 free/month (save $60)' },
    ],
  },
  [PLANS.power]: {
    planName: PLANS.power,
    price: {
      monthly: 99,
      annually: 85,
    },
    bulletPoints: [
      { description: '10 Authorized orderers' },
      { description: 'Unlimited team members' },
      { description: '25 Active job sites' },
      { description: 'No booking fees (unlimited)' },
    ],
  },
  legacy: {
    planName: 'legacy',
    price: {
      monthly: 10,
      annually: 8,
    },
    bulletPoints: [
      { description: '1 Leader (orderer)' },
      { description: 'Unlimited Team Members' },
      { description: 'No order fees' },
    ],
  },
}

export const subscriptionPlans: {
  planName: SubscriptionType
  price: {
    monthly: number
    annually: number
  }
  bulletPoints: BulletPointsType[]
}[] = [subscriptionPlanLookup[PLANS.free], subscriptionPlanLookup[PLANS.pro], subscriptionPlanLookup[PLANS.power]]
