import { useEffect } from 'react'
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications'
import { useIonToast } from '@ionic/react'
import { closeOutline } from 'ionicons/icons'
import { Capacitor } from '@capacitor/core'

import firebase from 'firebase'
import mixpanel from 'mixpanel-browser'
import * as Sentry from '@sentry/react'
import * as SentryCapacitor from '@sentry/capacitor'
import useStores from '@supplyhound/hooks/useStores'

const PushNotificationListener = () => {
  const [present] = useIonToast()
  const { userProfileStore } = useStores()

  useEffect(() => {
    if (Capacitor.getPlatform() === 'web') {
      if (firebase.messaging.isSupported()) {
        try {
          const messaging = firebase.messaging()
          const askForPermissionToReceiveNotifications = async () => {
            try {
              const token = await messaging.getToken()
              console.log('Your token is:', token)

              return token
            } catch (error) {
              mixpanel.track('Push notification denied', {
                user: { id: userProfileStore.profile ? userProfileStore.profile.id.toString() : '' },
              })
            }
          }

          askForPermissionToReceiveNotifications()

          messaging.onMessage(payload => {
            present({
              header: payload.notification.title,
              message: payload.notification.body,
              position: 'top',
              buttons: [
                {
                  icon: closeOutline,
                  side: 'end',
                  role: 'cancel',
                  cssClass: 'notification-close-icon',
                },
              ],
            })
          })
        } catch (e) {
          Sentry.captureException(e)
        }
      }
    } else {
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register()
        } else {
          SentryCapacitor.captureMessage('Push notification denied', {
            user: { id: userProfileStore.profile ? userProfileStore.profile.id.toString() : '' },
          })
        }
      })

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration', (token: Token) => {
        console.log('Push registration success, token: ' + token.value)
      })

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError', (error: any) => {
        Sentry.captureException(error)
      })

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
        present({
          header: notification.title,
          message: notification.body,
          position: 'top',
          buttons: [
            {
              icon: closeOutline,
              side: 'end',
              role: 'cancel',
              cssClass: 'notification-close-icon',
            },
          ],
        })
      })

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed', (actionPerformed: ActionPerformed) => {
        const notification = actionPerformed.notification
        if (notification.data.title) {
          present({
            header: notification.data.title,
            message: notification.data.body,
            position: 'top',
            buttons: [
              {
                icon: closeOutline,
                side: 'end',
                role: 'cancel',
                cssClass: 'notification-close-icon',
              },
            ],
          })
        }
      })
    }
  }, [])

  return null
}

export default PushNotificationListener
