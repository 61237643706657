import React, { ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import useQuery from '@supplyhound/hooks/useQuery'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

type TabsProps = {
  tabs: Array<{
    id: string
    label: string | ReactNode
    View: () => ReactNode
  }>
}
type TabProps = {
  id: string
  activeTab?: string
  label: string | ReactNode
  onClick: () => void
}

const TabList = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  width: 100%;
`
const TabContainer = styled.div``
const TabDiv = styled.div`
  margin: var(--space-unit);
  font-weight: 500;
  font-size: 1.5em;
  cursor: pointer;
  display: inline-flex;
`
const TabText = styled.div<{ isActive: boolean }>`
  padding-bottom: var(--space-unit);
  ${props => (props.isActive ? 'border-bottom: 2px solid var(--ion-color-primary);' : '')}
`
const TabContent = styled.div`
  padding: var(--space-unit);
`

const Tab: React.FC<TabProps> = ({ onClick, activeTab, id, label }) => {
  const isActive = activeTab === id
  return (
    <TabDiv onClick={onClick}>
      <TabText isActive={isActive}>{label}</TabText>
    </TabDiv>
  )
}

const TabsLayout: React.FC<TabsProps> = props => {
  const { tabs } = props || []
  const { search } = useLocation()

  const {
    query: { tab },
    setQuery,
  } = useQuery<{ tab: string }>()
  const [activeTab, setActiveTab] = useState<string | undefined>(tab || tabs[0].id)
  const onClickTabItem = (tabId: string): void => {
    setActiveTab(tabId)
    setQuery({ tab: tabId })
  }

  useEffect(() => {
    const query = queryString.parse(search) as { tab: string }
    const tab = query.tab
    tab && setActiveTab(tab)
  }, [search])

  return (
    <TabContainer>
      <TabList>
        {tabs.map(({ id, label }) => {
          return <Tab activeTab={activeTab} key={id} label={label} id={id} onClick={() => onClickTabItem(id)} />
        })}
      </TabList>
      <TabContent>
        {tabs.map(({ id, View }) => {
          if (id !== activeTab) return undefined
          return (
            <div key={id}>
              <View />
            </div>
          )
        })}
      </TabContent>
    </TabContainer>
  )
}

export default TabsLayout
