import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import clsx from 'clsx'
import { Redirect, Route, Switch } from 'react-router-dom'
import { IonReactRouter } from '@ionic/react-router'
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import { autorun } from 'mobx'
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider'
import { Capacitor } from '@capacitor/core'
import { ThemeDetection } from '@awesome-cordova-plugins/theme-detection'

import { ClientApplication } from '@supplyhound/types'
import {
  HOME_ROUTE,
  ACCOUNT_ROUTE,
  DELIVERY_ONLY_ADD_SUPPLIER_ROUTE,
  DELIVERY_ONLY_DETAIL_ROUTE,
  HELP_ROUTE,
  HISTORY_ADD_ROUTE,
  HISTORY_JOBSITE_SELECT,
  HISTORY_ROUTE,
  JOB_SITE_LIST_DETAIL_ROUTE,
  JOB_SITE_LIST_SELECT_ROUTE,
  JOB_SITE_ORDER_RECEIPT_ROUTE,
  JOB_SITE_TASK_ADD_SUPPLIER_ROUTE,
  JOB_SITE_TASK_DETAIL_ROUTE,
  JOB_SITE_TASK_EDIT_ROUTE,
  MANAGE_JOB_SITES_DETAIL_ROUTE,
  MANAGE_JOB_SITES_SELECT_ROUTE,
  PAYMENT_ADD_ROUTE,
  PAYMENT_SELECT_ROUTE,
  SIGNUP_ROUTE,
  TEAM_MEMBERS_ROUTE,
  TEAM_MEMBER_ADD_ROUTE,
  TEAM_MEMBER_CONFIRMATION_ROUTE,
  TEAM_MEMBER_EDIT_ROUTE,
  TEAM_MEMBER_HOME_ROUTE,
  TEAM_MEMBER_LIST_ROUTE,
  TEAM_MEMBER_LOGIN_ROUTE,
  HISTORY_ADD_TO_JOBSITE_ROUTE,
  TEAM_MEMBER_REMINDER_DECLINE_ROUTE,
  TEAM_MEMBER_ROUTE_BASE,
  TEAM_MEMBER_SCHEDULE_ROUTE,
  DESKTOP_WIDTH,
  COMPANY_ROUTE,
  MANAGE_CONTACTS_ROUTE,
} from '@supplyhound/utils/config'

import { AppUrlListener } from '@supplyhound/listeners'
import { AppStateChangeListener } from '@supplyhound/listeners'
import { PushNotificationListener } from '@supplyhound/listeners'

import OrdererAuthenticated from '@supplyhound/components/routes/OrdererAuthenticated'
import OrdererUnauthenticatedOnly from '@supplyhound/components/routes/OrdererUnauthenticatedOnly'
import { OrdererApp } from '@supplyhound/layout'
import { OrdererSidebar } from '@supplyhound/layout'
import { DesktopNav } from '@supplyhound/layout'
import HomePage from 'pages/HomePage'
import SignUpPage from 'pages/SignUpPage'
import JobSiteListDetailPage from 'pages/JobSiteListDetailPage'
import JobSiteListSelectPage from 'pages/JobSiteListSelectPage'
import JobSiteTaskDetailPage from 'pages/JobSiteTaskDetailPage'
import JobSiteTaskAddSupplierPage from 'pages/JobSiteTaskAddSupplierPage'
import JobSiteTaskEditPage from 'pages/JobSiteTaskEditPage'
import OrderReceiptPage from 'pages/OrderReceiptPage'
import PaymentSelectPage from 'pages/PaymentSelectPage'
import AddPaymentPage from 'pages/PaymentAddPage'
import DeliveryOnlySupplierPage from 'pages/DeliveryOnlySupplierPage'
import DeliveryOnlyTaskDetailPage from 'pages/DeliveryOnlyTaskDetailPage'
import TeamMembersPage from 'pages/TeamMembersPage'
import HistoryPage from 'pages/History/HistoryPage'
import HistoryJobSiteListSelectPage from 'pages/History/HistoryJobSiteListSelectPage'
import TeamMemberReminderDeclinePage from 'pages/TeamMemberReminderDeclinePage'
import AcceptInvitePage from 'pages/AcceptInvitePage'

import '@supplyhound/styles/app.scss'
import metadata from '@supplyhound/utils/metadata'

import { useStores, useLoadingState, VersionChecker } from '@supplyhound/hooks'

import TeamMemberAddEditPage from 'pages/TeamMemberAddEditPage'
import HelpPage from 'pages/HelpPage'
import TeamMemberConfirmPage from 'pages/TeamMemberConfirmPage'

import TeamMemberUnauthenticatedOnly from '@supplyhound/components/routes/TeamMemberUnauthenticatedOnly'
import TeamMemberLoginPage from 'pages/TeamMemberLoginPage'
import { TeamMemberApp } from '@supplyhound/layout'
import TeamMemberAuthenticated from '@supplyhound/components/routes/TeamMemberAuthenticated'
import TeamMemberListPage from 'pages/TeamMemberListPage'
import { TeamMemberSidebar } from '@supplyhound/layout'
import TeamMemberJobSitePage from 'pages/TeamMemberJobSitePage'
import ItemHistoryPage from 'pages/ItemHistoryPage'
import TeamMemberSchedulePage from 'pages/TeamMemberSchedulePage'
import AccountPage from 'pages/Account/AccountPage'
import CompanyPage from 'pages/Company/CompanyPage'
import ManageJobSitesPage from 'pages/ManageJobSites/ManageJobSitesPage'
import ManageJobSitesDetails from 'pages/ManageJobSites/ManageJobSiteDetails'
import useWindowDimensions from '@supplyhound/hooks/useWindowDimensions'
import { ScreenConstraint } from '@supplyhound/layout'

import { ShLogoIcon } from '@supplyhound/images'
import ManageContactsPage from 'pages/ManageContactsPage'

const App: React.FC = () => {
  const { userProfileStore } = useStores()
  const { loadWhile } = useLoadingState()
  const isUserLoggedIn = userProfileStore.authStore.isAuthenticated
  const [darkModePreferred, setDarkModePreferred] = useState(false)

  const themeChecker = () => {
    if (['web', 'ios'].includes(Capacitor.getPlatform())) {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)')
      setDarkModePreferred(prefersDark.matches)
    } else {
      ThemeDetection.isDarkModeEnabled().then(result => {
        setDarkModePreferred(result.value)
      })
    }
  }

  useEffect(() => {
    loadWhile(userProfileStore.dispatchLoadMarkets)
  }, [])

  useEffect(() => {
    themeChecker()
  }, [])

  useEffect(() => {
    if (isUserLoggedIn) {
      loadWhile(userProfileStore.dispatchLoadProfile)
    }
  }, [isUserLoggedIn])

  autorun(() => {
    if (isUserLoggedIn && userProfileStore.profile) {
      Sentry.setUser({ email: userProfileStore.profile?.email, name: userProfileStore.profile?.name })
    }
  })

  // Resolve mismatches between the current app version and the most
  // recently published with a hard reload.
  const handleReceivedClientApp = ({ name, version }: ClientApplication) => {
    if (name === metadata.appName && version !== metadata.appVersion) {
      console.warn(`Newer app version available: ${name}:${version}`)

      // Leave a few seconds to check the console.
      setTimeout(window.location.reload.bind(window.location), 5000)
    }
  }

  const hasDesktopNavFF = userProfileStore.profile?.feature_flags.desktopnav

  const { width } = useWindowDimensions()
  const isDesktop = width > DESKTOP_WIDTH

  return (
    <IonApp className={clsx({ dark: darkModePreferred })}>
      <ActionCableConsumer channel="ClientAppsChannel" onReceived={handleReceivedClientApp}>
        <VersionChecker logoSrc={ShLogoIcon} />
        <AppUrlListener />
        <AppStateChangeListener
          logoSrc={ShLogoIcon}
          activeStateChangeCallback={() => {
            themeChecker()
          }}
        />
        <PushNotificationListener />

        <IonReactRouter>
          <Switch>
            <Route exact path={SIGNUP_ROUTE}>
              {hasDesktopNavFF && isDesktop && <DesktopNav type={'orderer'} />}
              <ScreenConstraint>
                <OrdererUnauthenticatedOnly>
                  <SignUpPage />
                </OrdererUnauthenticatedOnly>
              </ScreenConstraint>
            </Route>

            <Route exact path="/accept-invite">
              <AcceptInvitePage />
            </Route>

            <Route exact path={TEAM_MEMBER_LOGIN_ROUTE}>
              {hasDesktopNavFF && isDesktop && <DesktopNav type={'teammember'} />}
              <ScreenConstraint>
                <TeamMemberUnauthenticatedOnly>
                  <TeamMemberLoginPage />
                </TeamMemberUnauthenticatedOnly>
              </ScreenConstraint>
            </Route>

            <Route exact path={TEAM_MEMBER_CONFIRMATION_ROUTE}>
              {hasDesktopNavFF && isDesktop && <DesktopNav type={'teammember'} />}
              <ScreenConstraint>
                <TeamMemberUnauthenticatedOnly>
                  <TeamMemberConfirmPage />
                </TeamMemberUnauthenticatedOnly>
              </ScreenConstraint>
            </Route>
            <Route exact path={TEAM_MEMBER_REMINDER_DECLINE_ROUTE}>
              <ScreenConstraint>
                <TeamMemberReminderDeclinePage />
              </ScreenConstraint>
            </Route>

            <Route path={TEAM_MEMBER_ROUTE_BASE}>
              {hasDesktopNavFF && isDesktop && <DesktopNav type={'teammember'} />}
              <ScreenConstraint>
                <TeamMemberApp>
                  <TeamMemberSidebar />
                  <IonSplitPane contentId="main">
                    <IonRouterOutlet id="main">
                      <Route exact path={TEAM_MEMBER_HOME_ROUTE}>
                        <TeamMemberAuthenticated>
                          <TeamMemberJobSitePage />
                        </TeamMemberAuthenticated>
                      </Route>
                      <Route exact path={TEAM_MEMBER_LIST_ROUTE}>
                        <TeamMemberAuthenticated>
                          <TeamMemberListPage />
                        </TeamMemberAuthenticated>
                      </Route>
                    </IonRouterOutlet>
                  </IonSplitPane>
                </TeamMemberApp>
              </ScreenConstraint>
            </Route>

            <Route path="/">
              {hasDesktopNavFF && isDesktop && <DesktopNav type={'orderer'} />}
              <ScreenConstraint>
                <OrdererApp>
                  <OrdererSidebar />
                  <IonSplitPane contentId="main">
                    <IonRouterOutlet id="main">
                      <Switch>
                        <Route exact path={HOME_ROUTE}>
                          <HomePage />
                        </Route>
                        <Route exact path={JOB_SITE_LIST_DETAIL_ROUTE}>
                          <OrdererAuthenticated>
                            <JobSiteListDetailPage />
                          </OrdererAuthenticated>
                        </Route>
                        <Route exact path={JOB_SITE_LIST_SELECT_ROUTE}>
                          <OrdererAuthenticated>
                            <JobSiteListSelectPage />
                          </OrdererAuthenticated>
                        </Route>
                        <Route exact path={JOB_SITE_TASK_DETAIL_ROUTE}>
                          <OrdererAuthenticated>
                            <JobSiteTaskDetailPage />
                          </OrdererAuthenticated>
                        </Route>
                        <Route exact path={JOB_SITE_TASK_ADD_SUPPLIER_ROUTE}>
                          <OrdererAuthenticated>
                            <JobSiteTaskAddSupplierPage />
                          </OrdererAuthenticated>
                        </Route>
                        <Route exact path={JOB_SITE_TASK_EDIT_ROUTE}>
                          <OrdererAuthenticated>
                            <JobSiteTaskEditPage />
                          </OrdererAuthenticated>
                        </Route>
                        <Route exact path={PAYMENT_ADD_ROUTE}>
                          <OrdererAuthenticated>
                            <AddPaymentPage />
                          </OrdererAuthenticated>
                        </Route>
                        <Route exact path={PAYMENT_SELECT_ROUTE}>
                          <OrdererAuthenticated>
                            <PaymentSelectPage />
                          </OrdererAuthenticated>
                        </Route>
                        <Route exact path={TEAM_MEMBERS_ROUTE}>
                          <OrdererAuthenticated>
                            <TeamMembersPage />
                          </OrdererAuthenticated>
                        </Route>
                        <Route exact path={TEAM_MEMBER_ADD_ROUTE}>
                          <OrdererAuthenticated>
                            <TeamMemberAddEditPage />
                          </OrdererAuthenticated>
                        </Route>
                        <Route exact path={TEAM_MEMBER_EDIT_ROUTE}>
                          <OrdererAuthenticated>
                            <TeamMemberAddEditPage />
                          </OrdererAuthenticated>
                        </Route>
                        <Route exact path={TEAM_MEMBER_SCHEDULE_ROUTE}>
                          <OrdererAuthenticated>
                            <TeamMemberSchedulePage />
                          </OrdererAuthenticated>
                        </Route>
                        <Route exact path={DELIVERY_ONLY_ADD_SUPPLIER_ROUTE}>
                          <OrdererAuthenticated>
                            <DeliveryOnlySupplierPage />
                          </OrdererAuthenticated>
                        </Route>
                        <Route exact path={DELIVERY_ONLY_DETAIL_ROUTE}>
                          <OrdererAuthenticated>
                            <DeliveryOnlyTaskDetailPage />
                          </OrdererAuthenticated>
                        </Route>
                        <Route exact path={JOB_SITE_ORDER_RECEIPT_ROUTE}>
                          <OrdererAuthenticated>
                            <OrderReceiptPage />
                          </OrdererAuthenticated>
                        </Route>
                        <Route exact path={HELP_ROUTE}>
                          <OrdererAuthenticated>
                            <HelpPage />
                          </OrdererAuthenticated>
                        </Route>
                        <Route exact path={HISTORY_ROUTE}>
                          <OrdererAuthenticated>
                            <HistoryPage />
                          </OrdererAuthenticated>
                        </Route>
                        <Route exact path={HISTORY_JOBSITE_SELECT}>
                          <OrdererAuthenticated>
                            <HistoryJobSiteListSelectPage />
                          </OrdererAuthenticated>
                        </Route>
                        <Route exact path={HISTORY_ADD_ROUTE}>
                          <OrdererAuthenticated>
                            <ItemHistoryPage />
                          </OrdererAuthenticated>
                        </Route>
                        <Route exact path={HISTORY_ADD_TO_JOBSITE_ROUTE}>
                          <OrdererAuthenticated>
                            <ItemHistoryPage />
                          </OrdererAuthenticated>
                        </Route>
                        <Route exact path={ACCOUNT_ROUTE}>
                          <OrdererAuthenticated>
                            <AccountPage />
                          </OrdererAuthenticated>
                        </Route>
                        <Route exact path={MANAGE_JOB_SITES_SELECT_ROUTE}>
                          <OrdererAuthenticated>
                            <ManageJobSitesPage />
                          </OrdererAuthenticated>
                        </Route>
                        <Route exact path={MANAGE_JOB_SITES_DETAIL_ROUTE}>
                          <OrdererAuthenticated>
                            <ManageJobSitesDetails />
                          </OrdererAuthenticated>
                        </Route>
                        <Route exact path={COMPANY_ROUTE}>
                          <OrdererAuthenticated>
                            <CompanyPage />
                          </OrdererAuthenticated>
                        </Route>
                        <Route exact path={MANAGE_CONTACTS_ROUTE}>
                          <OrdererAuthenticated>
                            <ManageContactsPage />
                          </OrdererAuthenticated>
                        </Route>
                        {/* Catch all */}
                        <Route>
                          <Redirect to={HOME_ROUTE} />
                        </Route>
                      </Switch>
                    </IonRouterOutlet>
                  </IonSplitPane>
                </OrdererApp>
              </ScreenConstraint>
            </Route>
          </Switch>
        </IonReactRouter>
      </ActionCableConsumer>
    </IonApp>
  )
}

export default observer(App)
