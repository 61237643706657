import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import Card from '@supplyhound/components/common/Card'
import Spacer from '@supplyhound/components/common/Spacer'
import SubscriptionBadge from 'components/SubscriptionBadge'
import { ShLogoIcon } from '@supplyhound/images'
import { subscriptionPlanLookup } from '../config'
import { formatCurrency } from '@supplyhound/utils/formatters'

import { SubscriptionType, BillingPlanType } from '../types'

const BillingPlanContainer = styled(Card)`
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
`

const LogoBadgeContiner = styled.div`
  display: flex;
  align-items: center;
`

const LogoImg = styled.img`
  height: 50px;
  width: 50px;
`

const HeadingPrice = styled.h3`
  &&& {
    font-size: 32px;
  }
`

const Price = styled.span`
  display: flex;
  align-items: end;
`

const SubscriptionStatus = ({
  subscriptionType,
  billingPlanType,
}: {
  subscriptionType: SubscriptionType
  billingPlanType: BillingPlanType
}) => {
  const price = subscriptionPlanLookup[subscriptionType].price[billingPlanType]
  return (
    <BillingPlanContainer>
      <LogoBadgeContiner>
        <LogoImg src={ShLogoIcon} alt="logo" />
        <Spacer width={5} />
        <SubscriptionBadge label={_.capitalize(subscriptionType)} />
      </LogoBadgeContiner>
      {subscriptionType === 'free' ? (
        <HeadingPrice>Free</HeadingPrice>
      ) : (
        <Price>
          <HeadingPrice>{formatCurrency(price, { noDecimal: true })}</HeadingPrice>&nbsp;
          <span> / month, billed {billingPlanType}</span>
        </Price>
      )}
    </BillingPlanContainer>
  )
}

export default SubscriptionStatus
