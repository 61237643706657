import React from 'react'
import styled from 'styled-components'
import useHandlePressEnter from '@supplyhound/hooks/useHandlePressEnter'
import { Field, withFormik, FormikProps, FormikBag } from 'formik'
import * as Yup from 'yup'
import TextInputField from '@supplyhound/forms/fields/TextInputField'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import { TeamMemberAuthenticationValues } from '@supplyhound/types'
import { IonToast } from '@ionic/react'
import { closeOutline } from 'ionicons/icons'
import { formatPhoneNumber } from '@supplyhound/utils/formatters'

export type TeamMemberLoginFormValues = TeamMemberAuthenticationValues

interface TeamMemberLoginFormProps {
  phone?: string
  onSubmit: (values: TeamMemberLoginFormValues) => Promise<any>
  confirmation?: boolean
}

const ButtonTier = styled.div`
  padding-top: 20px;
  padding-bottom: 15px;
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`

const StyledToast = styled(IonToast)`
  --height: 67px;
  --width: calc(100% - ${props => props.theme.contentGutters} - ${props => props.theme.contentGutters});
  --max-width: calc(1024px - ${props => props.theme.contentGutters} - ${props => props.theme.contentGutters});
  ::part(container) {
    color: var(--ion-color-dark);
    border-radius: var(--ion-border-radius);
    border: 1px solid var(--ion-color-dark);
  }
  ::part(button) {
    color: var(--ion-color-dark);
  }
`

const TeamMemberLoginForm: React.FC<TeamMemberLoginFormProps & FormikProps<TeamMemberLoginFormValues>> = ({
  submitForm,
  status,
  confirmation = false,
}) => {
  useHandlePressEnter(submitForm, false)

  const labelText = confirmation ? 'confirm' : 'login'

  return (
    <ContentWrapper>
      <Field
        name="phone"
        component={TextInputField}
        label={`Enter your phone number to ${labelText}`}
        placeholder="(xxx)-xxx-xxxx"
        type="tel"
        mask="(999)-999-9999"
      />
      <ButtonTier>
        <SubmitButton color="dark" onClick={submitForm}>
          {confirmation ? 'Confirm' : 'Continue'}
        </SubmitButton>
      </ButtonTier>
      {status && (
        <StyledToast
          isOpen
          message={status}
          position="bottom"
          buttons={[
            {
              side: 'end',
              icon: closeOutline,
            },
          ]}
        />
      )}
    </ContentWrapper>
  )
}

export default withFormik<TeamMemberLoginFormProps, TeamMemberLoginFormValues>({
  displayName: 'TeamMemberLoginForm',
  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    phone: Yup.string()
      .matches(/\(\d{3}\)-\d{3}-\d{4}/, 'Enter a 10 digit phone number')
      .required('Enter a 10 digit phone number'),
  }),

  mapPropsToValues({ phone }): TeamMemberLoginFormValues {
    return { phone: formatPhoneNumber(phone) ?? '' }
  },

  handleSubmit(
    values: TeamMemberLoginFormValues,
    { props: { onSubmit }, setErrors, setStatus }: FormikBag<TeamMemberLoginFormProps, TeamMemberLoginFormValues>
  ) {
    onSubmit(values).catch((e: { response: { status: number } }) => {
      if (e.response.status === 404) {
        const errorMsg = 'Unregistered phone number'
        setErrors({ phone: errorMsg })
        setStatus(errorMsg)
      } else {
        setStatus('Oops, something went wrong')
      }
    })
  },
})(TeamMemberLoginForm)
