import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { App, URLOpenListenerEvent } from '@capacitor/app'

const AppUrlListener = () => {
  let history = useHistory()
  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const slug = event.url.split('.com').pop()
      if (slug) {
        history.push(slug)
      }
    })
  }, [])

  return null
}

export default AppUrlListener
