import React from 'react'
import ReactDOM from 'react-dom'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import { LoadScript } from '@react-google-maps/api'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { ReusableProvider } from 'reusable'
import { setupIonicReact } from '@ionic/react'
import mixpanel from 'mixpanel-browser'
import * as serviceWorker from './serviceWorker'
import App from 'layout/App'
import { ThemeProvider } from 'styled-components'
import { defaultTheme } from '@supplyhound/themes'

import { ActionCableProvider } from '@thrash-industries/react-actioncable-provider'
import metadata from '@supplyhound/utils/metadata'

import * as FullStory from '@fullstory/browser'
import * as ActiveStorage from '@rails/activestorage'
import { Keyboard } from '@capacitor/keyboard'
import { Capacitor } from '@capacitor/core'
import { initializeFirebase } from './push-notification'
import * as Sentry from '@sentry/react'
import * as SentryCapacitor from '@sentry/capacitor'

if (Capacitor.isPluginAvailable('Keyboard') && Capacitor.getPlatform() === 'ios') {
  Keyboard.setAccessoryBarVisible({ isVisible: true })
}

ActiveStorage.start()

if (process.env.REACT_APP_SENTRY_DSN) {
  if (Capacitor.getPlatform() === 'web') {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_SENTRY_ENV,
    })
  } else {
    SentryCapacitor.init(
      {
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_SENTRY_ENV,
      },
      Sentry.init
    )
  }
}

if (process.env.REACT_APP_FULLSTORY_ORG_ID) {
  FullStory.init({ orgId: process.env.REACT_APP_FULLSTORY_ORG_ID })
}

if (process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN)
}

setupIonicReact({
  mode: 'ios',
})

initializeFirebase()

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!)

const wsUrl = metadata.appName
  ? `${process.env.REACT_APP_API_WS_URL!}?app_name=${metadata.appName}`
  : process.env.REACT_APP_API_WS_URL!

ReactDOM.render(
  <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY!} libraries={['places']}>
    <Elements stripe={stripePromise}>
      <ReusableProvider>
        <ActionCableProvider url={wsUrl}>
          <ThemeProvider theme={defaultTheme}>
            <App />
          </ThemeProvider>
        </ActionCableProvider>
      </ReusableProvider>
    </Elements>
  </LoadScript>,
  document.getElementById('root')
)

defineCustomElements(window)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// This is a comment to force deploy - 8/20/2023 #3
