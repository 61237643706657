import React, { useEffect } from 'react'
import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { useIonModal } from '@ionic/react'
import { UpdateAppModal } from '@supplyhound/modals'
import { fetchAppVersion } from '@supplyhound/api'

export const VersionChecker = ({ logoSrc }: { logoSrc: string }) => {
  const { checkIfNativeAppNeedsUpdate, presentUpdateModal } = useUpdateNativeAppPrompt({ logoSrc })

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      checkIfNativeAppNeedsUpdate().then(doesNativeAppNeedToUpdate => {
        if (doesNativeAppNeedToUpdate) {
          presentUpdateModal()
        }
      })
    }
  }, [])

  return null
}

export const useUpdateNativeAppPrompt = ({ logoSrc }: { logoSrc: string }) => {
  const [present] = useIonModal(UpdateAppModal, { logoSrc })
  const checkIfNativeAppNeedsUpdate = async () => {
    const appInfo = await App.getInfo()
    const latestVersion = await fetchAppVersion(appInfo.id, Capacitor.getPlatform())

    const [deviceMajorStr, deviceMinorStr] = appInfo.version.split('.')
    const [latestMajorStr, latestMinorStr] = latestVersion.split('.')
    const deviceMajor = Number(deviceMajorStr)
    const deviceMinor = Number(deviceMinorStr)
    const latestMajor = Number(latestMajorStr)
    const latestMinor = Number(latestMinorStr)

    const isDeviceMajorLower = deviceMajor < latestMajor
    const isDeviceMajorMinorLower = deviceMajor === latestMajor && deviceMinor < latestMinor
    return isDeviceMajorLower || isDeviceMajorMinorLower
  }

  const presentUpdateModal = () => {
    present({
      backdropDismiss: false,
      canDismiss: false,
    })
  }

  return {
    checkIfNativeAppNeedsUpdate,
    presentUpdateModal,
  }
}

export default useUpdateNativeAppPrompt
