import React from 'react'

import CreditCardDisplay from '@supplyhound/components/CreditCardDisplay'
import Card from '@supplyhound/components/common/Card'
import styled from 'styled-components'
import useStores from '@supplyhound/hooks/useStores'
import { observer } from 'mobx-react-lite'
import ErrorLabel from '@supplyhound/components/common/ErrorLabel'

const StyledCard = styled(Card)<{ $error: boolean }>`
  background-color: var(--greyscale-1);
  border-color: ${props => (props.$error ? 'red' : 'var(--ion-border-color)')};
`

interface PaymentMethodProps {
  onClick?: () => void
  editable?: boolean
  showCreditCardRequired?: boolean
}

const PaymentMethod: React.FC<PaymentMethodProps> = ({ onClick, editable = true, showCreditCardRequired = false }) => {
  const { paymentMethodStore } = useStores()

  const { paymentMethods, selectedPaymentMethodId } = paymentMethodStore

  let card
  if (selectedPaymentMethodId) {
    const selectedPaymentMethod = paymentMethods.find(paymentMethod => paymentMethod.id === selectedPaymentMethodId)
    card = selectedPaymentMethod?.card
  }

  return (
    <>
      <StyledCard $error={showCreditCardRequired}>
        <CreditCardDisplay
          card={card ? { brand: card.brand, last4: card.last4 } : null}
          onClick={editable ? onClick : undefined}
          editable={editable}
        />
      </StyledCard>
      {showCreditCardRequired && <ErrorLabel>Adding a credit card is required</ErrorLabel>}
    </>
  )
}

export default observer(PaymentMethod)
