import React from 'react'
import styled from 'styled-components'
import { IonRow, useIonModal } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import { personOutline } from 'ionicons/icons'
import Spacer from '@supplyhound/components/common/Spacer'
import { TeamMember } from '@supplyhound/types'
import OnOffLabel from '@supplyhound/components/common/OnOffLabel'
import OvalButton from '@supplyhound/components/buttons/OvalButton'
import ActionModal from '@supplyhound/components/common/Modals/ActionModal'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'

interface Props {
  teamMember: TeamMember
  handleEdit: () => void
  isReminderPaused: boolean
}

const ContactButton = styled(OvalButton)`
  font-size: 20px;
  margin-left: auto;
`

const Row = styled(IonRow)`
  align-items: center;
  background-color: var(--greyscale-1);
  border-radius: var(--ion-border-radius);
  cursor: pointer;
  margin: 20px 13px;
`

const Container = styled.div`
  padding: 30px auto;
  flex-grow: 1;
`

const NameLabel = styled.p`
  font-size: 18px;
  font-weight: 400;
  margin: 0px;
`

const PhoneLabel = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin: 8px 0px 0px 0px;
`

const ReminderRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  height: 15px;
`

const Text = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: var(--ion-color-medium);
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
`

const StyledSubmit = styled(SubmitButton)`
  flex: 50%;
`

const TeamMemberRow: React.FC<Props> = ({
  teamMember: { first_name, last_name, phone, should_receive_daily_reminder },
  handleEdit,
  isReminderPaused,
}) => {
  const fullName = `${first_name} ${last_name}`
  const reminderOnDisplay = !isReminderPaused && should_receive_daily_reminder

  const [presentContactModal, dismissContactModal] = useIonModal(ActionModal, {
    icon: personOutline,
    iconColor: 'dark',
    title: `Contact ${first_name}`,
    subtext: `Call or text ${first_name} about their submitted materials requests.`,
    onDismiss: () => dismissContactModal(),
    showCancel: false,
    ActionButtons: (
      <StyledContainer>
        <StyledSubmit onClick={() => window.open(`sms:${phone}`, '_self')}>Text</StyledSubmit>
        <Spacer width={15} />
        <StyledSubmit onClick={() => window.open(`tel:${phone}`, '_self')}>Call</StyledSubmit>
      </StyledContainer>
    ),
  })

  return (
    <Row>
      <Container onClick={handleEdit}>
        <NameLabel>{fullName}</NameLabel>
        <PhoneLabel>{phone}</PhoneLabel>
        <ReminderRow>
          <Text>Text reminders - </Text>
          <Spacer width={2} />
          <OnOffLabel on={reminderOnDisplay} onLabel="ON" offLabel={isReminderPaused ? 'PAUSED' : 'OFF'} />
        </ReminderRow>
      </Container>
      <ContactButton size="small" color="tertiary" onClick={() => presentContactModal()}>
        Contact
      </ContactButton>
    </Row>
  )
}

export default observer(TeamMemberRow)
