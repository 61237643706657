import { useEffect } from 'react'
import { App, AppState } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { PushNotifications, DeliveredNotifications } from '@capacitor/push-notifications'
import useUpdateNativeAppPrompt from '@supplyhound/hooks/useUpdateNativeAppPrompt'
import { useIonToast } from '@ionic/react'
import { closeOutline } from 'ionicons/icons'

const AppStateChangeListener = ({
  logoSrc,
  activeStateChangeCallback,
}: {
  logoSrc: string
  activeStateChangeCallback?: () => void
}) => {
  const [present] = useIonToast()
  const { checkIfNativeAppNeedsUpdate, presentUpdateModal } = useUpdateNativeAppPrompt({ logoSrc })

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      App.addListener('appStateChange', ({ isActive }: AppState) => {
        if (isActive) {
          checkIfNativeAppNeedsUpdate().then(doesNativeAppNeedToUpdate => {
            if (doesNativeAppNeedToUpdate) {
              presentUpdateModal()
            }
          })

          PushNotifications.getDeliveredNotifications().then(({ notifications }: DeliveredNotifications) => {
            notifications.forEach(notification => {
              present({
                header: notification.title,
                message: notification.body,
                position: 'top',
                buttons: [
                  {
                    icon: closeOutline,
                    side: 'end',
                    role: 'cancel',
                    cssClass: 'notification-close-icon',
                    handler: () => {
                      PushNotifications.removeAllDeliveredNotifications()
                    },
                  },
                ],
              })
            })
          })

          if (activeStateChangeCallback) {
            activeStateChangeCallback()
          }
        }
      })
    }
  }, [])

  return null
}

export default AppStateChangeListener
