import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import useStores from '@supplyhound/hooks/useStores'
import AccountingEmailForm from 'pages/Account/AccountingEmailForm'
import { dispatchUserUpdate } from '@supplyhound/api'
import NotificationBanner from '@supplyhound/components/NotificationBanner'
import { ID, PaymentMethod, UserProfile } from '@supplyhound/types'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import { useHistory, useLocation } from 'react-router-dom'
import { PAYMENT_ADD_ROUTE } from '@supplyhound/utils/config'
import CreditCardDisplay from '@supplyhound/components/CreditCardDisplay'
import styled from 'styled-components'
import { useIonModal } from '@ionic/react'
import ActionModal from '@supplyhound/components/common/Modals/ActionModal'
import { cardOutline } from 'ionicons/icons'
import Card from '@supplyhound/components/common/Card'
import Icon from '@supplyhound/components/common/Icon'
import Spacer from '@supplyhound/components/common/Spacer'
import { Capacitor } from '@capacitor/core'
import SubscriptionStatus from 'components/SubscriptionStatus'
import SubscriptionGetStarted from 'components/SubscriptionGetStarted'

const Section = styled.div`
  margin-top: 20px;
`

const EmptyPaymentContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50%;
`

const AccountPaymentTab = () => {
  const {
    userProfileStore,
    userProfileStore: { authStore, subscriptionStore },
    paymentMethodStore,
    paymentMethodStore: { paymentMethods },
  } = useStores()

  const history = useHistory()
  const location = useLocation()

  const [bannerMessage, setBannerMessage] = useState('')
  const [selectedCard, setSelectedCard] = useState<PaymentMethod>()
  const hasActiveSubscription = !!subscriptionStore.getSubscriptionJson

  const [presentRemoveModal, dismissRemoveModal] = useIonModal(ActionModal, {
    icon: cardOutline,
    title: `Remove card ending in ${selectedCard?.card.last4}?`,
    subtext:
      'Caution: This action will permanently remove your stored payment card and cannot be undone. Are you sure?',
    submitText: 'Remove payment card',
    onDismiss: () => dismissRemoveModal(),
    onSubmit: () => {
      if (selectedCard) {
        paymentMethodStore.dispatchDeletePaymentMethod(selectedCard.id)
        dismissRemoveModal()
        setBannerMessage('Payment card removed')
      }
    },
  })

  const handleEmailSubmit = async (id: ID, userPayload: Pick<UserProfile, 'accounting_emails'>) =>
    dispatchUserUpdate(id, userPayload).then(response => {
      authStore.updateBasicUserInfo(response.data.user)
      setBannerMessage('Email(s) Saved')
    })

  const isCompanyAdministrator = !!userProfileStore.profile?.subscription?.administrator
  const hasSubscriptionAndAdmin = hasActiveSubscription && isCompanyAdministrator

  return (
    <div>
      {userProfileStore.profile && <AccountingEmailForm onSubmit={handleEmailSubmit} user={userProfileStore.profile} />}
      <Section>
        <h2>Payment Cards</h2>
        {!paymentMethods.length && (
          <EmptyPaymentContainer>
            <Icon width={50} icon={cardOutline} />
            <p>Your payment cards will appear here.</p>
          </EmptyPaymentContainer>
        )}
        {paymentMethods.map(payment => (
          <Card key={payment.id}>
            <CreditCardDisplay
              card={payment.card}
              pillText="Remove"
              onClick={() => {
                setSelectedCard(payment)
                presentRemoveModal()
              }}
              editable={hasSubscriptionAndAdmin || !hasActiveSubscription}
            />
          </Card>
        ))}
        {(hasSubscriptionAndAdmin || !hasActiveSubscription) && (
          <SubmitButton onClick={() => history.push(PAYMENT_ADD_ROUTE, { referrer: location })}>
            Add payment card
          </SubmitButton>
        )}
      </Section>
      {userProfileStore.profile?.feature_flags.saas && (
        <Section>
          {Capacitor.getPlatform() === 'web' ? (
            <>
              <h2>Subscription</h2>
              <Spacer height={10} />
              {hasActiveSubscription ? <SubscriptionStatus /> : <SubscriptionGetStarted />}
            </>
          ) : hasActiveSubscription ? (
            <>
              <h2>Corporate Plans</h2>
              <Spacer height={10} />
              {hasActiveSubscription && <SubscriptionStatus editable={false} />}
            </>
          ) : null}
        </Section>
      )}
      {bannerMessage && <NotificationBanner message={bannerMessage} onDidDismiss={() => setBannerMessage('')} />}
    </div>
  )
}

export default observer(AccountPaymentTab)
