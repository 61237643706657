import React from 'react'
import styled from 'styled-components'

interface Props {
  on: boolean
  onLabel: string
  offLabel: string
}

const OnOff = styled.div`
  font-size: 16px;
  font-weight: 500;
`

const On = styled.p`
  color: var(--ion-color-success);
`

const Off = styled.p`
  color: var(--ion-color-warning);
`

const OnOffLabel: React.FC<Props> = ({ on, onLabel, offLabel, ...props }) => (
  <OnOff {...props}>{on ? <On>{onLabel}</On> : <Off>{offLabel}</Off>}</OnOff>
)

export default OnOffLabel
