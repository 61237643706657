import { createContext, useContext } from 'react'
import CompanyStore from 'stores/company'

const context = {
  companyStore: new CompanyStore(),
}

const StoresContext = createContext(context)

export const useStores = () => useContext(StoresContext)

export default useStores
